<template>
    <Modal
        v-model="show"
        :laoding="true"
        @on-ok="ok"
        title="切换翻译渠道"
        >
        <div v-if="list && list.length">
            <Alert type="error">
                由于渠道价格不一致,切换渠道可能会导致余量的变更,请谨慎操作
            </Alert>
            <RadioGroup v-model="selection">
                <Radio border :label="item.id" v-for="item in list" :key="item.id">{{item.name}}</Radio>
            </RadioGroup>

        </div>
        <div v-else>暂无可切换翻译渠道</div>
    </Modal>
</template>

<script>
    import service from "@/service/member/group"
    export default {
        name: 'TranChannelMappingModal',
        emits:["on-success"],
        data() {
            return {
                show : false,
                selection : null,
                list : []
            }
        },
        methods:{
            open(){
                this.show = true;
                this.init();
            },
            init(){
                this.loadData();
            },
            async loadData(){
                this.loading = true;
                let result = await service.mappingList();
                this.loading = false;
                this.list = result;
                
            },
            async ok(){
                this.loading = true;
                let result = await service.changeChannel(this.selection);
                this.loading = false;
                if(result){
                    this.show = false;
                    this.$Message.success("操作成功")
                    this.$emit("on-success")

                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    
</style>