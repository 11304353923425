import { createStore } from 'vuex'

import tranChannelService from "@/service/translation/channel"

export default createStore({
  state: {
    TRAN_CHANNEL_LIST: [],
    TRAN_CHANNEL_MAP: {}
  },
  mutations: {
  },
  actions: {
    async FLUSH_TRAN_CHANNEL(content){
      let result = await tranChannelService.list();
      if(result){
        content.state.TRAN_CHANNEL_LIST = result;
        let map = {};
        result.forEach(item => {
          map[item.id] = item;
        })
        content.state.TRAN_CHANNEL_MAP = map;
      }
      return result && true;
    }
  },
  modules: {
  }
})
