<template>
  <Modal v-model="show" :width="isMobile ? '90%vw' :'550'" :mask-closable="false">
    <Tabs :value="act">
      <TabPane
        style="padding: 5px"
        v-for="item in list"
        :key="item.id"
        :label="item.name"
        :name="item.id"
      >
        <h2>{{ item.title }}</h2>
        <h4>{{ item.subTitle }}</h4>
        <Divider />
        <div v-html="item.content" style="padding-bottom: 25px"></div>
      </TabPane>
    </Tabs>
  </Modal>
</template>

<script>
import service from "@/service/notice";
export default {
  name: "",
  data() {
    return {
      show: false,
      list: [],
      act: "0",
      isMobile:
        window.screen.width > 768 || window.screen.width == 768 ? false : true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loadData();
    },
    async loadData(result) {
      if (!result) result = await service.list();
      if (result && result.length) {
        this.show = true;
        this.list = result;
        this.act = this.list[0].id;
      }
    },
  },
};
</script>

<style lang="scss">
</style>