<template>
  <div class="main">
    <div class="mainContent">
      <div class="companyInfo">
        <div>
          <div>
            <img :src="logoSrc" alt="" style="height: 2.625rem" />
          </div>
          <div class="company">成都千蓝月网络科技有限公司</div>
          <div class="email">E-mail: yifanyi@traneasy.com.cn</div>
        </div>
        <div class="qrArr">
          <div v-for="(item, idx) in QRCodes" :key="idx" class="qrBox">
            <div>
              <template v-if="item.imgSrcArr.length > 0">
                <img
                  v-for="(img, j) in item.imgSrcArr"
                  :key="j"
                  :src="img"
                  alt=""
                  style="height: 90px; display: inline; width: 90px"
                />
              </template>
              <template v-else>
                <img
                  :src="item.imgSrc"
                  alt=""
                  style="height: 90px; display: inline; width: 90px"
                />
              </template>
            </div>
            <div>{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <div class="recordNumber">
        <span @click="open" style="cursor: pointer">
          蜀ICP备2023001571号-1&nbsp;&nbsp;</span
        >
        <span>&nbsp;Copyright © 2012-2023 UCloud </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import logoSrc from "@/assets/imgs/footer/footerLogo.png";
import Wechat from "@/assets/imgs/footer/wechat.png";
import whatsapp from "@/assets/imgs/footer/whatsapp.png";
import telegram from "@/assets/imgs/footer/telegram.png";
import service from "@/service/customerService";
import { ref } from "vue";
// const isMobile =
//   window.screen.width > 768 || window.screen.width == 768 ? false : true;
let QRCodes = ref([
  {
    desc: "微信",
    name: "wxchat",
    imgSrc: Wechat,
    imgSrcArr: [],
  },
  {
    desc: "Whatsapp",
    imgSrc: whatsapp,
    name: "whatsapp",
    imgSrcArr: [],
  },
  {
    desc: "Telegram",
    imgSrc: telegram,
    name: "telegram",
    imgSrcArr: [],
  },
]);
const getList = async () => {
  let res = await service.list({ height: 400, width: 400 });
  if (res && res.length > 0) {
    res.forEach((j) => {
      let platform = j.platform.toLowerCase();
      if (j.accountList?.length > 0) {
        j.accountList.forEach((r) => {
          if (platform === "wechat") {
            QRCodes.value[0].imgSrcArr.push(r.qrCode);
          } else if (platform === "whatsapp") {
            QRCodes.value[1].imgSrcArr.push(r.qrCode);
          } else if (platform === "telegram") {
            QRCodes.value[2].imgSrcArr.push(r.qrCode);
          }
        });
      }
    });
    QRCodes.value = JSON.parse(JSON.stringify(QRCodes.value));
  }
};
//仅在正式官网域名下展示公司等信息  其他推广域名不展示
//www.traneasy.com.cn为官网
// const notPromotion = window.notPromotion;
getList();
const open = () => {
  window.open("https://beian.miit.gov.cn/", "_blank");
};
</script>
<style scoped>
.main {
  background: #f5f5f5;
}
.companyInfo {
  padding: 2.3125rem 0;
  display: flex;
  justify-content: space-between;
}
.qrArr {
  display: flex;
  justify-content: space-between;
}
.recordNumber {
  padding: 1.25rem 0;
  border-top: 1px solid #dbdbdb;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.recordNumber span {
  font-size: 0.875rem;
  line-height: 1.2rem;
  color: #676767;
  font-weight: lighter;
}
.logo {
  width: 9.1875rem;
}
.grey {
  color: var(--text-color-grey);
}
.company {
  margin: 0.8125rem 0 0.5rem;
  font-weight: 500;
  letter-spacing: 0em;
}
.email {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
  letter-spacing: 0em;
  color: #676767;
}
.qrBox {
  padding: 3px 5px 8px;
  background-color: var(--vt-c-white);
  border-radius: 0.5rem;
  font-size: 0.75rem;
  color: #333333;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 0.6875rem;
}
@media (max-width: 768px) {
  .companyInfo {
    padding: 2.5rem 1.5rem 1.2rem;
    display: block;
  }
  .qrBox {
    margin-top: 1.25rem;
    margin-right: 0.6875rem;
    margin-left: 0px;
  }
  .recordNumber {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0;
    margin: 0 0.75rem;
  }

  .company {
    margin: 0.62rem 0;
    color: #000;
    text-align: left;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .email {
    color: #676767;
    text-align: left;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
