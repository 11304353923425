import http from "@/utils/http.js"

export default{
    async get(id){
        let api = "/loginLog/get";
        let params = {id}
        let result = await http.get(api,params);
        return result.success && result.data;
    },
    async page(params){
        let api = "/loginLog/page";
        let result = await http.get(api,params);
        return result.success && result.data;
    },
}