
const ERRORMSG = {
  401: "未授权，请重新登录！",
  500: "服务器异常，请联系工作人员"
}

module.exports = {
  // request: "http://116.62.232.108:80/client-user", // 不同环境的请求地址
  // request: "http://8.222.156.60:8787/client-user", // 不同环境的请求地址
  // request: "https://lqtran.lqjava.com/client-user", // 不同环境的请求地址
  // request: "https://www.traneasy.com.cn/client-user", // 不同环境的请求地址
  request: "/client-user", // 不同环境的请求地址
  timeout: 6000, // 超时时间
  ERRORMSG,
  
}

