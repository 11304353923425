<template>
  <div>
    <banner
      :bgSrc="bannerBg"
      @toDownload="toDownload"
      :showCarousel="true"
    ></banner>
    <div class="main">
      <achievement />
    </div>
    <features />
    <functions />
    <whyChoose />
    <!-- <demand @freeTest="freeTest" v-if="false"/> -->
    <div class="specialityBox">
      <div v-if="!isMobile">专业翻译通讯技术沉淀，专注即时通讯翻译领域</div>
      <div v-else>
        <div>专业翻译通讯技术沉淀</div>
        <div>专注即时通讯翻译领域</div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from "@/components/banner.vue";
import achievement from "@/components/home/achievement.vue";
import bannerBg from "@/assets/imgs/home/bannerBg.png";
import features from "@/components/home/features.vue";
import functions from "@/components/home/functions.vue";
import whyChoose from "@/components/home/whyChoose.vue";
// import demand from "@/components/home/demand.vue";
export default {
  name: "home",
  components: {
    banner,
    achievement,
    features,
    functions,
    whyChoose,
    // demand,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bannerBg,
    };
  },
  methods: {
    toDownload() {
      this.$emit("toDownload");
    },
    freeTest() {
      this.$emit("on-free-test");
    },
  },
};
</script>
<style scoped>
.main {
  position: relative;
}
.specialityBox {
  background-color: #3581ff;
}
.specialityBox > div {
  background-image: url("../../assets/imgs/home/speciality.png");
  color: var(--vt-c-white);
  font-size: 2.375rem;
  font-weight: 500;
  line-height: 2.875rem;
  letter-spacing: 0em;
  text-align: center;
  padding: 5.4375rem 0;
}
@media (max-width: 768px) {
  .specialityBox > div {
    padding: 1.4rem;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 140% */
  }
}
</style>
