<style lang="scss" scoped>
    .base-warp{
        width: 100%; height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .son{
            width: 400px;
            height: 400px;
            margin: 5px;
            .img-warp{
                width: 100%;
                text-align: center;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .title{
                text-align: center;
                strong{
                    font-size: 24px;
                }
            }
        }
    }
</style>

<template>
    <div class="base-warp">
        <div class="son">
            <div class="title">支付成功,将在 <strong>{{num}}</strong> 秒后自动跳转到订单详情页面</div>
            <div class="title"><a @click="toOrderListView">立即跳转</a></div>

            <div class="img-warp">
                <img src="@/assets/pay-success.svg">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PaySuccess',
        data() {
            return {
               payInfo : {} ,
               num: 5
            }
        },
        mounted(){
            let query = this.$route.query
            this.payInfo = JSON.parse(decodeURIComponent(query.payInfo))
            this.iid = setInterval(() => {
                this.num--;
                if(this.num < 1){
                    this.toOrderListView()
                }
            }, 1000);

        },
        methods:{
            toOrderListView(){
                clearInterval(this.iid)
                this.$router.push({name : "UserCenterOrder"})
            }
        }
    }
</script>

