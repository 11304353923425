<template>
  <Modal v-model="show" :loading="loading" @on-ok="submit" title="修改昵称">
    <div>
      <Form
        style="padding: 0px"
        ref="form"
        :model="formData"
        :rules="rules"
        :label-width="60"
      >
        <FormItem label="昵称" prop="nickname">
          <Input v-model="formData.nickname" placeholder="请输入昵称"></Input>
        </FormItem>
      </Form>
    </div>
    <template #footer>
      <div style="text-align: center">
        <Button class="saveBtn" long :loading="loading" @click="submit"
          >保存</Button
        >
      </div>
    </template>
  </Modal>
</template>

<script>
import userService from "@/service/user";
export default {
  name: "ChangeUserInfoModal",
  emits: ["on-success"],
  data() {
    return {
      show: false,
      loading: false,
      formData: {},
      rules: {
        nickname: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          {
            type: "string",
            min: 2,
            message: "昵称至少输入2位",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    submit() {
      let { nickname } = this.formData;
      let params = { nickname };
      let sub = async () => {
        this.loading = true;
        let result = await userService.update(params);
        this.loading = false;
        if (!result) {
          this.$Message.error("修改失败");
          return;
        }
        let userInfo = await userService.get();
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
        this.$Message.success("修改成功");
        this.$emit("on-success");
        this.show = false;
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          sub();
        } else {
          return;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>