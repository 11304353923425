<template>
  <div>
    <banner
      :bgSrc="bannerBg"
      title1="易翻译-使用文档"
      title2=""
      desc="文档使用小技巧"
      @toDownload="toDownload"
    ></banner>
    <div class="title">使用文档</div>
    <template v-if="!isMobile">
      <div class="part part1">
        <div class="mainContent">
          <div class="partTitle">
            <span>01</span>
            <span>注册说明</span>
          </div>
          <div class="imgBox">
            <img :src="img1_1" alt="" />
            <div class="relative">
              <img :src="img1_2" alt="" />
              <div class="desc desc1_1">
                <div class="descTitle">创建账号</div>
                <div>
                  点击免费创建账号,输入用户名与<br />
                  邮箱(用户名不能有特殊符号)
                </div>
              </div>
              <div class="desc desc1_2">
                <div class="descTitle">验证码</div>
                <div>
                  点获取验证码，然后输入验证码<br />
                  (验证码在输入的邮箱里面，查看收件箱与垃圾箱)
                </div>
              </div>
              <div class="desc desc1_3">
                <div class="descTitle">密码</div>
                <div>输入密码密码长度大于8位，小于20位</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part part2">
        <div class="mainContent">
          <div class="partTitle">
            <span>02</span>
            <span>充值说明</span>
          </div>
          <div class="imgBox">
            <div class="relative">
              <img :src="img2_1" alt="" />
              <div class="desc desc2_1">
                <div class="descTitle">打开官网</div>
                <div>www.traneasy.com.cn</div>
              </div>
              <div class="desc desc2_2">
                <div class="descTitle">登录你的主账号密码</div>
                <div>(子账户没有充值权限)</div>
              </div>
              <div class="desc desc2_3">
                <div class="descTitle">选择充值的套餐及数量</div>
                <div>标准版/高级版/专业版)与对应的充值方式微信/支付宝）</div>
              </div>
            </div>
            <img :src="img2_2" alt="" />
          </div>
        </div>
      </div>
      <div class="part part3">
        <div class="mainContent">
          <div class="partTitle">
            <span>03</span>
            <span>翻译技巧</span>
          </div>
          <div class="imgBox">
            <img :src="img3_1" alt="" />
            <div class="relative">
              <img :src="img3_2" alt="" />
              <div class="desc desc3_1">
                <div class="descTitle">翻译技巧</div>
                <div>
                  可实现全球各大社交软件聊天自动收发翻译及多开等多项功能。
                </div>
                <div class="splitLine"></div>
                <div>使用翻译时注意以下几点,让翻译更准确</div>
                <div class="part3Content">
                  发送语言文本尽量官方，简洁。<br />
                  语言不要太嘜嗦，绕口，表达直接。<br />
                  不要带自己国家说话的口音发送。<br />
                  不要带自己国家独有的词汇发送，在其它国家语言没有的词语。<br />
                  尽量按目标语言国的说话思维，语序，去使用翻译。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="isMobile">
      <div class="part part1">
        <div class="mainContent">
          <div class="partTitle">
            <span>01</span>
            <span>注册说明</span>
          </div>
          <div class="mImgBox">
            <img :src="mUseDoc1" alt="" />
            <div class="doc1font1">
              <div class="mTitle">创建账号</div>
              <div class="mDesc">
                点击免费创建账号,输入用户名与 邮箱(用户名不能有特殊符号)
              </div>
            </div>
            <div class="doc1font2">
              <div class="mTitle">验证码</div>
              <div class="mDesc">
                点获取验证码，然后输入验证码
                (验证码在输入的邮箱里面，查看收件箱与垃圾箱)
              </div>
            </div>
            <div class="doc1font3">
              <div class="mTitle">密码</div>
              <div class="mDesc">输入密码密码长度大于8位，小于20位</div>
            </div>
          </div>
        </div>
      </div>
      <div class="part part2">
        <div class="mainContent">
          <div class="partTitle">
            <span>02</span>
            <span>充值说明</span>
          </div>
          <div class="mImgBox">
            <img :src="mUseDoc2" alt="" />
            <div class="doc2font1">
              <div class="mTitle">打开官网</div>
              <div class="mDesc">www.traneasy.com.cn</div>
            </div>
            <div class="doc2font2">
              <div class="mTitle">登录您的<br />主账号密码</div>
              <div class="mDesc">(子账户没有充值权限)</div>
            </div>
            <div class="doc2font3">
              <div class="mTitle">选择充值套餐数量</div>
              <div class="mDesc">
                标准版/高级版/专业版)与对应的充值方式微信/支付宝）
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part part3">
        <div class="mainContent">
          <div class="partTitle">
            <span>03</span>
            <span>翻译技巧</span>
          </div>
          <div class="mImgBox">
            <img :src="mUseDoc3" alt="" />
            <div class="doc3font1">
              <div class="mTitle">翻译技巧</div>
              <div class="mDesc">
                <div style="margin-bottom: 1rem;">
                  可实现全球各大社交软件聊天自动收发翻译及多开<br/>等多项功能。
                </div>
                <div style="margin-bottom: 0.5rem;color: #343434;">使用翻译时注意以下几点,让翻译更准确</div>
                <div>
                  发送语言文本尽量官方，简洁。<br />
                  语言不要太嘜嗦，绕口，表达直接。<br />
                  不要带自己国家说话的口音发送。<br />
                  不要带自己国家独有的词汇发送，在其它国家语言没有的词语。<br/>
                  尽量按目标语言国的说话思维，语序，去使用翻译。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script >
import banner from "@/components/banner.vue";
import bannerBg from "@/assets/imgs/home/bannerBg.png";
import mBannerBg from "@/assets/imgs/mobile/banner/mBanner2.png";
import img1_1 from "@/assets/imgs/useDoc/01_1.png";
import img1_2 from "@/assets/imgs/useDoc/01_2.png";
import img2_1 from "@/assets/imgs/useDoc/02_1.png";
import img2_2 from "@/assets/imgs/useDoc/02_21.png";
import img3_1 from "@/assets/imgs/useDoc/03_1.png";
import img3_2 from "@/assets/imgs/useDoc/03_2.png";
import mUseDoc1 from "@/assets/imgs/mobile/useDoc/mUseDoc1.png";
import mUseDoc2 from "@/assets/imgs/mobile/useDoc/mUseDoc2_1.png";
import mUseDoc3 from "@/assets/imgs/mobile/useDoc/mUseDoc3.png";
export default {
  components: {
    banner,
  },
  data() {
    const isMobile =
      window.screen.width > 768 || window.screen.width == 768 ? false : true;
    return {
      bannerBg: isMobile ? mBannerBg : bannerBg,
      img1_1,
      img1_2,
      img2_1,
      img2_2,
      img3_1,
      img3_2,
      isMobile,
      mUseDoc1,
      mUseDoc2,
      mUseDoc3,
    };
  },
  methods: {
    toDownload() {
      this.$emit("toDownload");
    },
    freeTest() {
      this.$emit("on-free-test");
    },
  },
};
</script>
<style scoped>
.title {
  padding-top: 5.625rem;
  background-color: #fff;
  text-align: center;
  color: #292b35;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}
.part {
  padding: 2.4375rem 0 5.3125rem;
}
.part1,
.part3 {
  background-color: #fff;
}
.part2 {
  background-color: #f6f8ff;
}
.partTitle {
  padding: 1rem 0.25rem;
  position: relative;
}
.partTitle > span:first-child {
  color: #b3b3b3;
  font-size: 5.5rem;
  line-height: 6rem;
  font-weight: 600;
  font-style: normal;
}
.partTitle > span:last-child {
  color: #292b35;
  font-size: 1.25rem;
  line-height: 1.25rem;
  position: absolute;
  bottom: 1.6rem;
  font-weight: 600;
  left: 0.3rem;
  font-style: normal;
}
.imgBox {
  display: flex;
  /* justify-content: space-between; */
  align-content: center;
}
.part1 .imgBox > img {
  max-width: 39rem;
  height: auto;
  object-fit: contain;
}
.imgBox .desc {
  color: #676767;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  position: absolute;
}
.imgBox .desc .descTitle {
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  margin-bottom: 0.375rem;
}
.desc1_1 {
  top: 80px;
  left: 70px;
}
.desc1_2 {
  top: 244px;
  left: 226px;
  white-space: nowrap;
}
.desc1_3 {
  top: 436px;
  left: 54px;
  white-space: nowrap;
}
.part2 .imgBox > img {
  max-width: 39rem;
  height: auto;
  object-fit: contain;
}
.part2 .imgBox {
  margin-left: 6.25rem;
}
.desc2_1 {
  top: 80px;
  left: 184px;
}
.desc2_2 {
  top: 244px;
  left: -30px;
  white-space: nowrap;
}
.desc2_3 {
  top: 436px;
  left: 82px;
  white-space: nowrap;
}
.part3 .imgBox {
  margin-top: 1.625rem;
}
.imgBox .desc.desc3_1 {
  text-align: left;
  position: absolute;
  top: 94px;
  left: 160px;
}
.imgBox .desc3_1 .descTitle {
  text-align: left;
  margin-bottom: 0.5rem;
}
.imgBox .desc3_1 > div {
  white-space: nowrap;
  color: #333;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
}
.splitLine {
  width: 50px;
  height: 1px;
  background: #cdcdcd;
  margin: 1rem 0;
}
.part3Content {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .title {
    padding-top: 2.5rem;
    margin-bottom: 1.25rem;
    background-color: #fff;
    color: #292b35;
    text-align: center;
    font-family: Inter;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .part {
    padding: 0 0.6rem;
  }

  .partTitle {
    padding: 0;
    position: relative;
  }
  .partTitle > span:first-child {
    color: #b3b3b3;
    font-family: Inter;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .partTitle > span:last-child {
    color: #292b35;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    bottom: 0.6rem;
    left: 0rem;
  }

  .mImgBox {
    margin-top: 1.2rem;
    position: relative;
  }

  .mImgBox img {
    width: 100%;
    height: auto;
  }
  .doc1font1 {
    position: absolute;
    width: 6rem;
    top: 16%;
    left: 4%;
  }

  .doc1font2 {
    position: absolute;
    width: 6rem;
    top: 16%;
    left: calc(50% - 3rem);
  }

  .doc1font3 {
    position: absolute;
    width: 6rem;
    top: 16%;
    right: 4.5%;
  }

  .doc2font1 {
    position: absolute;
    width: 6rem;
    top: 16%;
    left: 4%;
  }

  .doc2font2 {
    position: absolute;
    width: 6rem;
    top: 16%;
    left: calc(50% - 3rem);
  }

  .doc2font3 {
    position: absolute;
    width: 7rem;
    top: 16%;
    right: 2.5%;
  }

  .mTitle {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.4rem;
    word-break: break-all;
  }

  .mDesc {
    color: #676767;
    text-align: center;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: break-all;
  }

  .part2 {
    padding: 2rem 0.6rem 1.56rem;
  }

  .part3 {
    padding: 2rem 0.6rem 1.12rem;
  }

  .doc3font1 {
    position: absolute;
    top: 16%;
    left: 2%;
    right: 2%;
  }
}
</style>
