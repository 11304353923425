<template>
    <div>
        <banner
            :bgSrc="isMobile ? mBannerBg : bannerBg"
            :showDownload="false"
        ></banner>
        <div class="download">
            <div class="mainContent">
                <div class="moduleTitle">软件安装包下载</div>
                <!-- <div class="parentBox" :style="{ opacity: curParentName ? 1 : 0 }">
          <span
            >当前下载集合：<span class="parent">{{ curParentName }}</span></span
          ><span @click="backToParent"
            ><a href="javascript:void(0)">返回上级</a></span
          >
        </div> -->
                <div class="downArea flex pcdownArea">
                    <template v-if="!isMobile">
                        <div
                            v-for="(item, idx) in downloadList"
                            :key="idx"
                            @mouseenter="
                                () => {
                                    curIdx = idx;
                                }
                            "
                            @mouseleave="
                                () => {
                                    curIdx = null;
                                }
                            "
                            :style="{
                                backgroundImage:
                                    curIdx === idx
                                        ? 'url(' + hoverBg + ')'
                                        : 'url(' + defaultBg + ')',
                                color: curIdx === idx ? '#fff' : '#17406F',
                                width: curIdx === idx ? '247px' : '203px',
                                backgroundSize: 'cover',
                            }"
                        >
                            <div
                                class="appTitle"
                                :style="{
                                    color: curIdx === idx ? '#fff' : '#17406F',
                                    width: curIdx === idx ? '240px' : '180px',
                                }"
                            >
                                {{ item.label }}
                            </div>
                            <div
                                class="splitList"
                                :style="{
                                    backgroundColor:
                                        curIdx === idx ? '#fff' : '#17406F',
                                }"
                            ></div>
                            <div class="version">{{ item.version }}</div>
                            <div
                                class="downloadBtn"
                                v-if="curIdx === idx"
                                @click="download(item)"
                            >
                                点击下载
                            </div>
                        </div>
                    </template>

                    <template v-if="isMobile">
                        <div
                            v-for="(item, idx) in downloadList"
                            :key="idx"
                            :style="{
                                backgroundImage: 'url(' + mDownload + ')',
                                color: '#fff',
                            }"
                        >
                            <div
                                class="appTitle"
                                :style="{
                                    color: '#fff',
                                }"
                            >
                                {{ item.label }}
                            </div>
                            <div
                                class="splitList"
                                :style="{
                                    backgroundColor: '#fff',
                                }"
                            ></div>
                            <div class="version">{{ item.version }}</div>
                            <div class="downloadBtn" @click="download(item)">
                                点击下载
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <Modal
            v-model="showSecond"
            :title="curParentName"
            class="downloadModal"
        >
            <div
                v-for="(item, idx) in childDownLoadList"
                :key="idx"
                class="secBox"
            >
                <div style="display: flex; align-items: center">
                    <div>
                        <img
                            :src="idx % 2 === 0 ? download1 : download2"
                            alt=""
                        />
                    </div>
                    <div class="secTitle">{{ item.label }}</div>
                </div>

                <Button
                    class="saveBtn"
                    long
                    @click="download(item)"
                    style="padding: 0.4rem 1.2rem; font-size: 0.875rem"
                    >点击下载</Button
                >
            </div>
            <template #footer>
                <div></div>
                <!-- <div style="text-align: center">
          <Button class="saveBtn" long @click="showSecond = false"
            >关 闭</Button
          >
        </div> -->
            </template>
        </Modal>
    </div>
</template>
<script setup>
import banner from "@/components/banner.vue";
import { ref, onMounted, toRaw } from "vue";
import bannerBg from "@/assets/imgs/price/priceBg.png";
import hoverBg from "@/assets/imgs/download/hoverBg.png";
import defaultBg from "@/assets/imgs/download/defaultBg.png";
import download1 from "@/assets/imgs/download/download1.png";
import download2 from "@/assets/imgs/download/download2.png";
import dataService from "@/service/data";
import mBannerBg from "@/assets/imgs/mobile/banner/mBanner3.png";
import mDownload from "@/assets/imgs/mobile/download.png";
const curIdx = ref(null);
const allList = ref([]);
const downloadList = ref([]);
const pDownloadList = ref([]); //一级下载菜单
const childDownLoadList = ref([]); //当前二级下载菜单
const curParentName = ref("易翻译软件下载");
const showSecond = ref(false);
const isMobile =
    window.screen.width > 768 || window.screen.width == 768 ? false : true;
const download = (item) => {
    let rItem = toRaw(item);
    if (rItem.childId?.length > 0) {
        // curParentName.value = rItem.label;
        let cArr = [];
        rItem.childId.forEach((i) => {
            let ci = allList.value.find((av) => {
                return av.id == i;
            });
            cArr.push(ci);
        });
        childDownLoadList.value = cArr;
        console.log("childDownLoadList.value:", childDownLoadList.value);
        showSecond.value = true;
    } else {
        window.open(rItem.val, "_blank");
    }
};
// const backToParent = () => {
//   downloadList.value = pDownloadList.value;
//   curParentName.value = null;
// };
onMounted(async () => {
    let data = await dataService.get("SOFT_DOWNLOAD_CONFIG");
    let value = JSON.parse(data.valued);
    if (value?.[0]?.child?.length > 0) {
        let childArrIds = [];
        allList.value = value[0].child;
        value[0].child.forEach((v) => {
            if (v.childId?.length > 0) {
                childArrIds = childArrIds.concat(v.childId);
            }
        });
        if (childArrIds.length > 0) {
            let pArr = [];
            value[0].child.forEach((v) => {
                if (!childArrIds.includes(v.id)) {
                    pArr.push(v);
                }
            });
            downloadList.value = pDownloadList.value = pArr;
        } else {
            downloadList.value = value[0].child;
        }
    }
});
</script>
<style scoped>
.download {
    background: #fff;
}
.mainContent {
    padding: 5.625rem 0 8.125rem;
}
.moduleTitle {
    margin-bottom: 4.5rem;
    font-size: 1rem;
}
.pcdownArea {
    flex-wrap: wrap;
    justify-content: center;
}
.parentBox {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
}
.parent {
    font-weight: bold;
}
.pcdownArea > div {
    margin-bottom: 20px;
}
.downArea > div {
    overflow: hidden;
    border-radius: 1rem;
    padding: 2rem 1.5rem;
    /* flex-grow: 1; */
    height: 260px;
    margin-right: 0.8rem;
    margin-left: 0.8rem;
    position: relative;
    background-repeat: no-repeat;
    transition: all 0.2s linear;
}

.appTitle {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.splitList {
    margin: 20px 0 24px;
    height: 2px;
    width: 32px;
}
.version {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.secBox {
    display: flex;
    padding: 0.5rem 1rem;
    /* border: 1px solid #3581ff; */
    justify-content: space-between;
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
    align-items: center;
    background-color: #f0f0f0;
}
.sDownloadBtn {
    display: inline-block;
    color: #3581ff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 28px;
    background: #fff;
    padding: 10px 28px;
    cursor: pointer;
}
.secTitle {
    font-size: 0.875rem;
    font-weight: bold;
    color: #1c4472;
    margin-left: 0.5rem;
}
.downloadBtn {
    position: absolute;
    display: inline-block;
    color: #3581ff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 28px;
    background: #fff;
    padding: 10px 28px;
    bottom: 2rem;
    left: 2rem;
    cursor: pointer;
}
@media (max-width: 768px) {
    .secTitle {
        font-size: 0.75rem;
        font-weight: bold;
    }
    .saveBtn {
        font-size: 0.75rem !important;
    }
    .secBox {
        padding: 0.5rem 0.5rem;
        margin-bottom: 0.5rem;
    }
    .mainContent {
        padding: 2.5rem 0.75rem 2rem;
    }

    .moduleTitle {
        margin-bottom: 1rem;
    }

    .downArea {
        flex-direction: column;
    }
    .downArea > div:last-child {
        margin-right: 0;
    }
    .downArea > div {
        overflow: hidden;
        border-radius: 1rem;
        padding: 1rem 1.5rem;
        flex-grow: 1;
        position: relative;
        background-repeat: no-repeat;
        margin-top: 1rem;
        width: 100%;
        height: 200px;
        background-size: cover;
    }
}
</style>
<style>
.downloadModal > .ivu-modal-content {
    background: #fff !important;
}
.downloadModal > .ivu-modal-content > .ivu-modal-footer {
    display: none !important;
}
</style>