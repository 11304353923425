
import http from "@/utils/http.js"

export default{
    async page(params){
        let api = "/orderByMemberGroup/page";
        let result = await http.get(api,params);
        return result.success && result.data;
    },
    async pay(params){
        let api = "/orderByMemberGroup/pay";
        let result = await http.post(api,params);
        return result.success && result.data;
    },
    async getPayInfo(orderId){
        let api = "/orderByMemberGroup/getPayInfo";
        let result = await http.get(api,{id:orderId});
        return result.success && result.data;
    },
    async flush(orderId){
        let api = "/orderByMemberGroup/flush";
        let result = await http.get(api,{id:orderId});
        return result.success && result.data;
    },

    async getPayChannels(params){
        let api = "/payInfo/channel";
        let result = await http.get(api,params);
        return result.success && result.data;
    }
}