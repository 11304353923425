<template>
  <div>
    <banner
      :bgSrc="bannerBg"
      title1="易翻译-联系我们"
      title2=""
      desc="客服联系方式"
      :showDownload="false"
      :showValid="true"
      @toDownload="
        () => {
          this.showModal = true;
        }
      "
    ></banner>
    <!-- <div class="title">售前客服</div> -->
    <template v-if="!isMobile">
      <div class="part part1">
        <div class="mainContent">
          <div class="partTitle">
            <span></span>
            <span>售前客服</span>
          </div>
          <div class="customerBox">
            <template v-for="(item, idx) in QRCodes" :key="idx">
              <div
                v-for="(qr, j) in item.imgSrcArr"
                :key="'qr' + j"
                style="margin: 0 50px"
              >
                <a :href="item.urlArr[j]" target="_blank"
                  ><img
                    :src="qr"
                    style="height: 300px; width: 300px; display: inline"
                /></a>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-left: 12px;
                    margin-top: 4px;
                  "
                >
                  <span>客服平台：</span
                  ><img
                    :src="item.logo"
                    alt=""
                    style="margin-right: 0.25rem"
                  /><span>{{ item.desc }}</span>
                </div>
                <!-- :title="
                    '点击复制' +
                    (item.name === 'wxchat'
                      ? '客服用户名'
                      : item.name === 'whatsapp'
                      ? '客服号码'
                      : '客服ID')
                  " -->
                <!-- title="点击联系客服，选中右键复制" -->
                <div
                  style="margin-left: 12px; cursor: pointer; margin-top: 4px"
                  :title="
                    '点击联系客服，选中右键复制' +
                    (item.name === 'wxchat'
                      ? '客服用户名'
                      : item.name === 'whatsapp'
                      ? '客服号码'
                      : '客服ID')
                  "
                  @click="jump(item.urlArr[j])"
                >
                  {{
                    item.name === "wxchat"
                      ? "客服用户名："
                      : item.name === "whatsapp"
                      ? "客服号码："
                      : "客服ID："
                  }}{{ item.accountList[j] }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        class="part part2"
        v-if="
          afterSaleQRCodes[0].urlArr.length > 0 ||
          afterSaleQRCodes[1].urlArr.length > 0 ||
          afterSaleQRCodes[2].urlArr.length > 0
        "
      >
        <div class="mainContent">
          <div class="partTitle">
            <span></span>
            <span>售后客服</span>
          </div>
          <div class="customerBox">
            <template v-for="(item, idx) in afterSaleQRCodes" :key="idx">
              <div
                v-for="(qr, j) in item.imgSrcArr"
                :key="'qr' + j"
                style="margin: 0 50px"
              >
                <a :href="item.urlArr[j]" target="_blank"
                  ><img
                    :src="qr"
                    style="height: 300px; width: 300px; display: inline"
                /></a>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-left: 12px;
                    margin-top: 4px;
                  "
                >
                  <span>客服平台：</span
                  ><img
                    :src="item.logo"
                    alt=""
                    style="margin-right: 0.25rem"
                  /><span>{{ item.desc }}</span>
                </div>
                <div
                  style="margin-left: 12px; cursor: pointer; margin-top: 4px"
                  :title="
                    '点击联系客服，选中右键复制' +
                    (item.name === 'wxchat'
                      ? '客服用户名'
                      : item.name === 'whatsapp'
                      ? '客服号码'
                      : '客服ID')
                  "
                  @click="jump(item.urlArr[j])"
                >
                  {{
                    item.name === "wxchat"
                      ? "客服用户名："
                      : item.name === "whatsapp"
                      ? "客服号码："
                      : "客服ID："
                  }}{{ item.accountList[j] }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>

    <template v-if="isMobile">
      <div class="part part1">
        <div class="mainContent">
          <div class="partTitle">
            <span></span>
            <span>售前客服</span>
          </div>
          <div class="mCustomerBox">
            <template v-for="(item, idx) in QRCodes" :key="idx">
              <div
                v-for="(qr, j) in item.imgSrcArr"
                :key="'qr' + j"
                style="text-align: center; margin-bottom: 30px"
              >
                <a :href="item.urlArr[j]" target="_blank"
                  ><img
                    :src="qr"
                    style="height: 300px; width: 300px; display: inline"
                /></a>
                <div
                  style="
                    display: inline-flex;
                    align-items: center;
                    margin-left: 12px;
                    margin-top: 4px;
                  "
                >
                  <span>客服平台：</span
                  ><img
                    :src="item.logo"
                    alt=""
                    style="margin-right: 0.25rem; height: 30px; width: 30px"
                  /><span>{{ item.desc }}</span>
                </div>
                <div
                  style="margin-left: 12px; cursor: pointer; margin-top: 4px"
                  :title="
                    '点击联系客服，选中右键复制' +
                    (item.name === 'wxchat'
                      ? '客服用户名'
                      : item.name === 'whatsapp'
                      ? '客服号码'
                      : '客服ID')
                  "
                  @click="jump(item.urlArr[j])"
                >
                  {{
                    item.name === "wxchat"
                      ? "客服用户名："
                      : item.name === "whatsapp"
                      ? "客服号码："
                      : "客服ID："
                  }}{{ item.accountList[j] }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        class="part part2"
        v-if="
          afterSaleQRCodes[0].urlArr.length > 0 ||
          afterSaleQRCodes[1].urlArr.length > 0 ||
          afterSaleQRCodes[2].urlArr.length > 0
        "
      >
        <div class="mainContent">
          <div class="partTitle">
            <span></span>
            <span>售后客服</span>
          </div>
          <div class="mCustomerBox">
            <template v-for="(item, idx) in afterSaleQRCodes" :key="idx">
              <div
                v-for="(qr, j) in item.imgSrcArr"
                :key="'qr' + j"
                style="text-align: center; margin-bottom: 30px"
              >
                <a :href="item.urlArr[j]" target="_blank"
                  ><img
                    :src="qr"
                    style="height: 300px; width: 300px; display: inline"
                /></a>
                <div
                  style="
                    display: inline-flex;
                    align-items: center;
                    margin-left: 12px;
                    margin-top: 4px;
                  "
                >
                  <span>客服平台：</span
                  ><img
                    :src="item.logo"
                    alt=""
                    style="margin-right: 0.25rem; height: 30px; width: 30px"
                  /><span>{{ item.desc }}</span>
                </div>
                <div
                  style="margin-left: 12px; cursor: pointer; margin-top: 4px"
                  :title="
                    '点击联系客服，选中右键复制' +
                    (item.name === 'wxchat'
                      ? '客服用户名'
                      : item.name === 'whatsapp'
                      ? '客服号码'
                      : '客服ID')
                  "
                  @click="jump(item.urlArr[j])"
                >
                  {{
                    item.name === "wxchat"
                      ? "客服用户名："
                      : item.name === "whatsapp"
                      ? "客服号码："
                      : "客服ID："
                  }}{{ item.accountList[j] }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <Modal v-model="showError" @on-ok="showError = false" title="验证结果">
      <div style="color: rgb(192, 12, 12)">
        注意：该账号未通过验证，请谨慎操作！
      </div>
      <template #footer>
        <div style="text-align: center">
          <Button class="saveBtn" long @click="showError = false">确认</Button>
        </div>
      </template>
    </Modal>
    <Modal v-model="showSuccess" @on-ok="showError = false" title="验证结果">
      <div style="color: rgb(13, 128, 0)">客服账号验证通过！</div>
      <template #footer>
        <div style="text-align: center">
          <Button class="saveBtn" long @click="showSuccess = false"
            >确认</Button
          >
        </div>
      </template>
    </Modal>
    <Modal
      v-model="showModal"
      :loading="loading"
      @on-ok="submit"
      title="验证客服"
    >
      <div>
        <Form
          style="padding: 0px"
          ref="form"
          :model="formData"
          :rules="rules"
          :label-width="120"
        >
          <FormItem label="客服平台" prop="platform">
            <Select v-model="formData.platform" placeholder="请选择客服平台">
              <Option v-for="v in platformList" :value="v" :key="v">
                {{ v }}
              </Option>
            </Select>
          </FormItem>
          <FormItem
            :label="
              formData.platform === 'Telegram'
                ? '客服ID'
                : formData.platform === 'WeChat'
                ? '客服用户名'
                : '客服号码'
            "
            prop="account"
          >
            <Input
              v-model="formData.account"
              :placeholder="
                formData.platform === 'Telegram'
                  ? '请输入客服ID'
                  : formData.platform === 'WeChat'
                  ? '请输入客服用户名'
                  : '请输入客服号码'
              "
            ></Input>
          </FormItem>
        </Form>
      </div>
      <template #footer>
        <div style="text-align: center">
          <Button class="saveBtn" long :loading="loading" @click="submit"
            >验证</Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>
<script >
import banner from "@/components/banner.vue";
import bannerBg from "@/assets/imgs/home/bannerBg.png";
import mBannerBg from "@/assets/imgs/mobile/banner/mBanner2.png";
import img1_1 from "@/assets/imgs/useDoc/01_1.png";
import img1_2 from "@/assets/imgs/useDoc/01_2.png";
import img2_1 from "@/assets/imgs/useDoc/02_1.png";
import img2_2 from "@/assets/imgs/useDoc/02_21.png";
import img3_1 from "@/assets/imgs/useDoc/03_1.png";
import img3_2 from "@/assets/imgs/useDoc/03_2.png";
import mUseDoc1 from "@/assets/imgs/mobile/useDoc/mUseDoc1.png";
import mUseDoc2 from "@/assets/imgs/mobile/useDoc/mUseDoc2_1.png";
import mUseDoc3 from "@/assets/imgs/mobile/useDoc/mUseDoc3.png";
import Wechat from "@/assets/imgs/footer/wechat.png";
import whatsapp from "@/assets/imgs/footer/whatsapp.png";
import telegram from "@/assets/imgs/footer/telegram.png";
import telegramLogo from "@/assets/imgs/sidebar/telegram.png";
import whatsappLogo from "@/assets/imgs/sidebar/whatsapp.png";
import wechatLogo from "@/assets/imgs/sidebar/wx.png";
import service from "@/service/customerService";
import ClipboardJS from "clipboard";
export default {
  components: {
    banner,
  },
  data() {
    const isMobile =
      window.screen.width > 768 || window.screen.width == 768 ? false : true;
    return {
      bannerBg: isMobile ? mBannerBg : bannerBg,
      img1_1,
      img1_2,
      img2_1,
      img2_2,
      img3_1,
      img3_2,
      isMobile,
      mUseDoc1,
      mUseDoc2,
      mUseDoc3,
      showModal: false,
      showError: false,
      showSuccess: false,
      formData: {
        platform: "",
        account: "",
      },
      platformList: [],
      rules: {
        platform: [
          { required: true, message: "请选择客服平台", trigger: "blur" },
        ],
        account: [
          { required: true, message: "请输入客服账号", trigger: "blur" },
        ],
      },
      QRCodes: [
        {
          desc: "微信",
          name: "wxchat",
          imgSrc: Wechat,
          logo: wechatLogo,
          imgSrcArr: [],
          urlArr: [],
          accountList: [],
        },
        {
          desc: "Whatsapp",
          imgSrc: whatsapp,
          name: "whatsapp",
          logo: whatsappLogo,
          imgSrcArr: [],
          urlArr: [],
          accountList: [],
        },
        {
          desc: "Telegram",
          imgSrc: telegram,
          name: "telegram",
          logo: telegramLogo,
          imgSrcArr: [],
          urlArr: [],
          accountList: [],
        },
      ],
      afterSaleQRCodes: [
        {
          desc: "微信",
          name: "wxchat",
          logo: wechatLogo,
          imgSrcArr: [],
          urlArr: [],
          accountList: [],
        },
        {
          desc: "Whatsapp",
          name: "whatsapp",
          logo: whatsappLogo,
          imgSrcArr: [],
          urlArr: [],
          accountList: [],
        },
        {
          desc: "Telegram",
          name: "telegram",
          logo: telegramLogo,
          imgSrcArr: [],
          urlArr: [],
          accountList: [],
        },
      ],
    };
  },
  async mounted() {
    let res = await service.list({ height: 800, width: 800 });
    if (res && res.length > 0) {
      res.forEach((j) => {
        let platform = j.platform.toLowerCase();
        if (j.accountList?.length > 0) {
          j.accountList.forEach((r) => {
            if (r.accountType != 1) {
              //售前
              if (platform === "wechat") {
                this.QRCodes[0].imgSrcArr.push(r.qrCode);
                this.QRCodes[0].urlArr.push(r.referUrl);
                this.QRCodes[0].accountList.push(r.account);
              } else if (platform === "whatsapp") {
                this.QRCodes[1].imgSrcArr.push(r.qrCode);
                this.QRCodes[1].urlArr.push(r.referUrl);
                this.QRCodes[1].accountList.push(r.account);
              } else if (platform === "telegram") {
                this.QRCodes[2].imgSrcArr.push(r.qrCode);
                this.QRCodes[2].urlArr.push(r.referUrl);
                this.QRCodes[2].accountList.push(r.account);
              }
            } else {
              //售后
              if (platform === "wechat") {
                this.afterSaleQRCodes[0].imgSrcArr.push(r.qrCode);
                this.afterSaleQRCodes[0].urlArr.push(r.referUrl);
                this.afterSaleQRCodes[0].accountList.push(r.account);
              } else if (platform === "whatsapp") {
                this.afterSaleQRCodes[1].imgSrcArr.push(r.qrCode);
                this.afterSaleQRCodes[1].urlArr.push(r.referUrl);
                this.afterSaleQRCodes[1].accountList.push(r.account);
              } else if (platform === "telegram") {
                this.afterSaleQRCodes[2].imgSrcArr.push(r.qrCode);
                this.afterSaleQRCodes[2].urlArr.push(r.referUrl);
                this.afterSaleQRCodes[2].accountList.push(r.account);
              }
            }
          });
        }
      });
      this.QRCodes = JSON.parse(JSON.stringify(this.QRCodes));
    }
    this.getPlatformList();
  },
  methods: {
    jump(url) {
      window.open(url, "_blank");
    },
    copyText(text) {
      const clipboard = new ClipboardJS(".btn", {
        text: () => text,
      });

      clipboard.on("success", () => {
        this.$Message.success("已复制");
        clipboard.destroy();
      });

      clipboard.on("error", () => {
        this.$Message.error("已复制");
        clipboard.destroy();
      });

      clipboard.onClick(event);
    },
    async getPlatformList() {
      let res = await service.platformList();
      if (res && res.length > 0) {
        this.platformList = res;
      }
    },
    submit() {
      let { account, platform } = this.formData;
      let params = { account, platform };
      let sub = async () => {
        this.loading = true;
        let result = await service.valid(params);
        this.loading = false;
        this.showModal = false;
        if (!result) {
          this.showError = true;
        } else {
          this.showSuccess = true;
        }
        // this.$Message.success("客服账号验证通过！");
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          sub();
        } else {
          return;
        }
      });
    },
    toDownload() {
      this.$emit("toDownload");
    },
    freeTest() {
      this.$emit("on-free-test");
    },
  },
};
</script>
<style scoped>
.title {
  padding-top: 5.625rem;
  background-color: #fff;
  text-align: center;
  color: #292b35;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}
.part {
  padding: 4.4375rem 0 5.3125rem;
}
.part1,
.part3 {
  background-color: #fff;
}
.part2 {
  background-color: #f6f8ff;
}
.partTitle {
  padding: 1rem 0.25rem;
  position: relative;
}
.partTitle > span:first-child {
  color: #b3b3b3;
  font-size: 5.5rem;
  line-height: 6rem;
  font-weight: 600;
  font-style: normal;
}
.partTitle > span:last-child {
  color: #292b35;
  font-size: 1.25rem;
  line-height: 1.25rem;
  position: absolute;
  bottom: 1.6rem;
  font-weight: 600;
  left: 0.3rem;
  font-style: normal;
}
.customerBox {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
}
.part1 .customerBox > img {
  max-width: 39rem;
  height: auto;
  object-fit: contain;
}
.customerBox .desc {
  color: #676767;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  position: absolute;
}
.customerBox .desc {
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  margin-bottom: 0.375rem;
}
@media (max-width: 768px) {
  .title {
    padding-top: 2.5rem;
    margin-bottom: 1.25rem;
    background-color: #fff;
    color: #292b35;
    text-align: center;
    font-family: Inter;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .part {
    padding: 2rem 0.6rem;
  }

  .partTitle {
    padding: 0;
    position: relative;
  }
  .partTitle > span:first-child {
    color: #b3b3b3;
    font-family: Inter;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .partTitle > span:last-child {
    color: #292b35;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    bottom: 0.6rem;
    left: 0rem;
  }

  .mCustomerBox {
    margin-top: 1.2rem;
    position: relative;
  }

  .mCustomerBox img {
    width: 100%;
    height: auto;
  }
  .doc1font1 {
    position: absolute;
    width: 6rem;
    top: 16%;
    left: 4%;
  }

  .doc1font2 {
    position: absolute;
    width: 6rem;
    top: 16%;
    left: calc(50% - 3rem);
  }

  .doc1font3 {
    position: absolute;
    width: 6rem;
    top: 16%;
    right: 4.5%;
  }

  .doc2font1 {
    position: absolute;
    width: 6rem;
    top: 16%;
    left: 4%;
  }

  .doc2font2 {
    position: absolute;
    width: 6rem;
    top: 16%;
    left: calc(50% - 3rem);
  }

  .doc2font3 {
    position: absolute;
    width: 7rem;
    top: 16%;
    right: 2.5%;
  }

  .mTitle {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.4rem;
    word-break: break-all;
  }

  .mDesc {
    color: #676767;
    text-align: center;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: break-all;
  }

  .part2 {
    padding: 2rem 0.6rem 1.56rem;
  }

  .part3 {
    padding: 2rem 0.6rem 1.12rem;
  }

  .doc3font1 {
    position: absolute;
    top: 16%;
    left: 2%;
    right: 2%;
  }
}
</style>
