<style lang="scss" scoped>
.btn-warp {
  padding-bottom: 3px;
  text-align: right;
}
.base-warp {
  display: flex;
  padding: 15px;
  .info-warp {
    flex: 1;
    padding-left: 25px;
    .info {
      margin-top: 15px;
      border-top: 1px solid #dedede;
      padding-top: 20px;
    }
    .top-warp {
      display: flex;
      .lw {
        width: 300px;
      }
      .rw {
        flex: 1;
        display: flex;
        padding-top: 5px;
        .box {
          flex: 1;
          color: #a7a7a7;
          a {
            font-size: 32px;
            font-weight: 600;
            padding: 0 5px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.UserCenterIndex {
  background-image: url("../assets/userCenter/userCenterBG.png");
  border-radius: 14px;
  padding: 0px 0 10px;
  background-repeat: no-repeat;
  color: #333333;
  .memberGrp {
    color: #fff;
    padding: 18px 0;
    padding-left: 32px;
    font-size: 18px;
  }
  .userCenterMain {
    background: #fff;
    padding: 40px 0;
    padding: 40px 32px;
    border-radius: 14px;
  }
  .userInfo {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #cdcdcd;
    padding-bottom: 40px;
    .img-warp {
      text-align: center;
      > div:last-child {
        margin-top: 20px;
        color: #333333;
      }
    }
    .accountWrap {
      margin-left: 22px;
      padding-top: 16px;
      .transEasy {
        font-size: 18px;
        font-weight: bold;
      }
      .accountType {
        margin-left: 12px;
        color: #fff;
        background-image: linear-gradient(to bottom, #fee356, #bfab00);
        font-size: 12px;
        padding: 2px 4px;
        border-radius: 4px;
      }
      .emailWrap {
        color: #656565;
        margin-top: 8px;
        white-space: nowrap;
      }
    }
    .inviteWrap {
      margin-left: 107px;
      margin-top: 16px;
      padding: 19px 46px 19px 12px;
      background-color: #fafafa;
      border-radius: 12px;
      > div:first-child {
        white-space: wrap;
        color: #666666;
        line-height: 15px;
      }
      .copyBtn {
        border-radius: 36px;
        color: #3581ff;
        border: 1px solid #3581ff;
        padding: 4px 12px;
        margin-top: 12px;
        cursor: pointer;
        display: inline-block;
      }
    }
    .changeWrap {
      margin-left: 74px;
      padding-top: 38px;
      > div:first-child {
        margin-bottom: 14px;
      }
      div {
        display: flex;
        align-items: center;
        cursor: pointer;
        > span:first-child {
          margin-right: 9px;
          text-align: center;
          width: 28px;
          height: 28px;
          display: inline-block;
          background: #f5f5f5;
          border-radius: 30px;
          line-height: 32px;
        }
      }
      span {
        white-space: nowrap;
      }
    }
  }

  .userDetail {
    padding-top: 40px;
    > div {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .textRight {
      text-align: right;
      white-space: nowrap;
    }
  }
}
</style>
<template>
  <div class="UserCenterIndex">
    <div class="memberGrp">会员中心：当前为{{ userInfo.memberGroup.name }}</div>
    <div class="userCenterMain">
      <div class="userInfo">
        <div class="img-warp">
          <div>
            <Avatar :size="120">{{ userInfo.nickname }}</Avatar>
          </div>
          <div>邀请人数：{{ userInfo.inviteCount }}</div>
        </div>
        <div class="accountWrap">
          <div>
            <span class="transEasy">易翻译</span
            ><span class="accountType">{{
              userInfo.parentUserId ? "子账户" : "主账户"
            }}</span>
          </div>
          <div class="emailWrap">
            邮箱号：<a href="javascript:void(0)" @click="copyText1(userInfo.account)">{{ userInfo.account }}</a>
          </div>
          <div class="emailWrap">
            用户名：<a href="javascript:void(0)" @click="copyText1(userInfo.username)">{{ userInfo.username }}</a>
          </div>
        </div>
        <div class="inviteWrap">
          <div>{{ userInfo.inviteText }}</div>
          <div class="copyBtn" @click="copyText">点击复制</div>
        </div>
        <!-- <div class="changeWrap">
          <div @click="toInfo">
            <span><User style="width: 16px; color: #333" /></span
            ><span>修改昵称</span>
          </div>
          <div @click="toUserCenterChangePassword">
            <span><Lock style="width: 16px; color: #333" /></span
            ><span>修改密码</span>
          </div>
        </div> -->
      </div>
      <div class="userDetail">
        <Row :gutter="7">
          <Col span="2" class="textRight">会员组别 :</Col>
          <Col span="4">
            {{ userInfo.memberGroup.name }}
          </Col>
          <Col span="2" class="textRight">
            <span v-if="userInfo.memberGroup.translationBillingMode == 'char'"
              >字符数量 :</span
            >
            <span v-if="userInfo.memberGroup.translationBillingMode == 'time'"
              >到期日期 :</span
            >
          </Col>
          <Col span="4">
            <span v-if="userInfo.memberGroup.translationBillingMode == 'char'">
              {{ userInfo.memberAccount.usableCharNum }}个
            </span>
            <span v-if="userInfo.memberGroup.translationBillingMode == 'time'">
              {{ userInfo.memberAccount.expireDateTime }}
            </span>
          </Col>
          <Col v-if="!userInfo.parentUserId" span="4" class="textRight"
            >是否开启字符自动分配 :</Col
          >
          <Col v-if="!userInfo.parentUserId" span="4">
            <Switch
              v-model="userInfo.autoDistribution"
              :true-value="1"
              :false-value="0"
              @click="autoDistribution(userInfo)"
            />
          </Col>
        </Row>
        <Row :gutter="8">
          <Col span="2" class="textRight">语音翻译 :</Col>
          <Col span="4"> {{ userInfo.memberAccount.usableVoiceNum }}条 </Col>
          <Col span="2" class="textRight">翻译渠道 :</Col>
          <Col span="4">
            <translate-channel-label :tranChannelId="userInfo.tranChannelId" />
            <a
              v-if="!userInfo.parentUserId"
              style="padding: 0 5px"
              @click="openTranChannelMappingModal"
              >切换</a
            >
          </Col>
          <Col span="4" class="textRight">计费模式 :</Col>
          <Col span="4">
            <span v-if="userInfo.memberGroup.translationBillingMode == 'char'">
              字符
            </span>
            <span v-if="userInfo.memberGroup.translationBillingMode == 'time'">
              包月
            </span>
          </Col>
        </Row>
        <Row :gutter="8">
          <Col span="2" class="textRight">翻译数量 :</Col>
          <Col span="4">{{ userInfo.memberAccount.usedCharTotal }}个</Col>
          <Col span="2" class="textRight">图片翻译 :</Col>
          <Col span="4"> {{ userInfo.memberAccount.usableImageNum }}张 </Col>
          <Col span="4" class="textRight">充值统计 :</Col>
          <Col span="4"
            >{{
              (userInfo.memberAccount.rechargeAmountTotal / 100).toFixed(2)
            }}元</Col
          >
        </Row>
        <Row :gutter="8">
          <Col span="2" class="textRight">连续登录 :</Col>
          <Col span="4"> {{ userInfo.lastLoginLog.sumDay }}天 </Col>

          <!-- <Col span="2" class="textRight">连续登录 :</Col>
          <Col span="4">
            {{ userInfo.lastLoginLog.maxSumDay }}天
          </Col> -->

          <Col span="2" class="textRight">最后登录 :</Col>
          <Col span="4">{{ userInfo.lastLoginLog.loginDateTime }}</Col>
          <Col span="4" class="textRight">注册时间 :</Col>
          <Col span="4">{{ userInfo.registerDateTime }}</Col>
        </Row>
        <!-- <Row :gutter="8">
          <Col span="2" class="textRight">最后登录 :</Col>
          <Col span="4">{{ userInfo.lastLoginLog.loginDateTime }}</Col>
        </Row> -->
      </div>
    </div>

    <TranChannelMappingModal
      ref="TranChannelMappingModalRef"
      @on-success="init"
    />
    <ChangeUserInfoModal ref="ChangeUserInfoModalRef" @on-success="init" />
    <UserCenterChangePasswordModal ref="UserCenterChangePasswordModalRef" />
  </div>
</template>

<script>
import ClipboardJS from "clipboard";
import userService from "@/service/user";
import TranslateChannelLabel from "../components/TranslateChannelLabel.vue";
import TranChannelMappingModal from "../components/TranChannelMappingModal.vue";
import UserCenterChangePasswordModal from "../components/UserCenterChangePasswordModal.vue";
import ChangeUserInfoModal from "../components/ChangeUserInfoModal.vue";
// import { User, Lock } from "@element-plus/icons-vue";
export default {
  components: {
    TranslateChannelLabel,
    TranChannelMappingModal,
    // User,
    // Lock,
    ChangeUserInfoModal,
    UserCenterChangePasswordModal,
  },
  name: "UserCenterIndex",
  data() {
    return {
      userInfo: {
        memberGroup: {},
        memberAccount: {},
        lastLoginLog: {},
        inviteCode: "",
        inviteText: "",
        inviteCount: 0,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.userInfo = await userService.get();
      this.userInfo.inviteText =
        "我在用易翻译，一键翻译telegram、whatsapp、facebook等社交软件，支持200+语种互译，还有多开，https://www.traneasy.com.cn/?inviteCode=" +
        this.userInfo.inviteCode +
        " 用我的链接注册可获得10000免费字符。";
    },
    toUserCenterChangePassword() {
      this.$refs.UserCenterChangePasswordModalRef.open();
      // this.$router.push({ name: "UserCenterChangePassword" });
    },
    toInfo() {
      // this.$router.push({ name: "UserCenterInfo" });
      this.$refs.ChangeUserInfoModalRef.open();
    },
    dateHello() {
      // 获取当前时间
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      // 设置默认文字
      let text = ``;
      // 判断当前时间段
      if (hours >= 0 && hours <= 10) {
        text = `早上好`;
      } else if (hours > 10 && hours <= 14) {
        text = `中午好`;
      } else if (hours > 14 && hours <= 18) {
        text = `下午好`;
      } else if (hours > 18 && hours <= 24) {
        text = `晚上好`;
      }
      // 返回当前时间段对应的状态
      return text;
    },
    openTranChannelMappingModal() {
      this.$refs.TranChannelMappingModalRef.open();
    },
    autoDistribution(userInfo) {
      let { userId, autoDistribution } = userInfo;
      let status =
        autoDistribution == 0
          ? '<span style="color:red">禁用</span>'
          : '<span style="color:green">启用</span>';
      let content = "您确定要 [" + status + "] 用户自动分配吗?";
      content += "<br/><small>该操作会同时对所有子账号生效</small>";
      this.$Modal.confirm({
        title: "温馨提示",
        content,
        loading: true,
        onOk: async () => {
          let e = autoDistribution == 0 ? 0 : 1;
          let result = await userService.autoDistribution({
            userId,
            autoDistribution: e,
          });
          this.$Modal.remove();
          if (result) {
            this.$Message.success("成功修改");
            this.loadData();
          }
        },
      });
    },
    copyText1(text) {
      const clipboard = new ClipboardJS(".btn", {
        text: () => text,
      });
      clipboard.on("success", () => {
        this.$Message.success("已复制");
        clipboard.destroy();
      });

      clipboard.on("error", () => {
        this.$Message.error("已复制");
        clipboard.destroy();
      });

      clipboard.onClick(event);
    },
    copyText() {
      const text = this.userInfo.inviteText;
      const clipboard = new ClipboardJS(".btn", {
        text: () => text,
      });

      clipboard.on("success", () => {
        this.$Message.success("已复制");
        clipboard.destroy();
      });

      clipboard.on("error", () => {
        this.$Message.error("已复制");
        clipboard.destroy();
      });

      clipboard.onClick(event);
    },
  },
};
</script>

