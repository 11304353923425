<template>
  <div style="background-color: #f5f5f5">
    <div class="features">
      <div class="mainContent">
        <div class="moduleTitle">产品的特点</div>
        <div class="imgBox swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(item, idx) in swiperist"
              :key="idx"
              class="swiper-slide"
            >
              <img
                :src="item.imgSrc"
                alt=""
                style="max-width: 100%; width: 1060px"
              />
              <div
                class="jh descFont"
                v-html="
                  isMobile
                    ? '聚合全球主流社交<br />软件，助力企业发展'
                    : '聚合全球主流社交软件<br />助力企业发展'
                "
              ></div>
              <div
                class="dl descFont"
                v-html="
                  isMobile
                    ? '独立翻译设置<br />交流更便捷'
                    : '独立翻译设置，交流更便捷'
                "
              ></div>
              <div class="xx descFont">
                消息即时自动双向翻译<br />无需频繁手动操作
              </div>
            </div>
          </div>
        </div>
        <div class="btnGrp">
          <div class="button-prev">
            <img :src="leftA" />
          </div>
          <div class="button-next"><img :src="rightA" /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import productFea1 from "@/assets/imgs/home/productFea1.png";
import productFea2 from "@/assets/imgs/home/productFea2.png";
import productFea3 from "@/assets/imgs/home/productFea3.png";
import mFea1 from "@/assets/imgs/mobile/features/mFea1.png";
import mFea2 from "@/assets/imgs/mobile/features/mFea2.png";
import mFea3 from "@/assets/imgs/mobile/features/mFea3.png";
import leftA from "@/assets/imgs/home/leftA.png";
import rightA from "@/assets/imgs/home/rightA.png";
import Swiper from "swiper";
const isMobile =
  window.screen.width > 768 || window.screen.width == 768 ? false : true;
const swiperist = isMobile
  ? [{ imgSrc: mFea1 }, { imgSrc: mFea2 }, { imgSrc: mFea3 }]
  : [{ imgSrc: productFea1 }, { imgSrc: productFea2 }, { imgSrc: productFea3 }];
let swiper = null;
const initSwiper = () => {
  swiper = new Swiper(".swiper-container", {
    autoplay: {
      delay: 3000, // 一秒播放一次
      disableOnInteraction: false, // 用户操作停止后继续
    },
    loop: true, // 自动播放
    loopedSlides: 0, // 定义在结束/开始前应重新排列（循环）幻灯片的数量
    effect: "fade", // 设置Slide的切换效果
    slidesPerView: "1",
    centeredSlides: true,
    spaceBetween: "-50%", // slide之间的距离
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },
    coverflowEffect: {
      // cover flow是类似于苹果将多首歌曲的封面以3D界面的形式显示出来的方式
      rotate: 0,
      stretch: 0,
      depth: 100,
      modifier: 4,
      slideShadows: false,
    },
  });
};
onMounted(() => {
  initSwiper();
  console.info(swiper);
  //   setTimeout(() => {
  //     swiper.autoplay.stop();
  //   }, 6000);
});
</script>
<style scoped>
.features {
  background: url("../../assets/imgs/home/featuresBg.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 12.0625rem 0 4.25rem;
}
.mainContent {
  position: relative;
}
.imgBox {
  position: relative;
  margin-top: 3.75rem;
  text-align: center;
}
.imgBox img {
  margin: 0 auto;
}
.jh {
  position: absolute;
  top: 20rem;
  left: 1.5rem;
}
.dl {
  position: absolute;
  top: 6rem;
  left: calc(50% - 6rem);
}
.xx {
  position: absolute;
  top: 20rem;
  right: 1.5rem;
}
.descFont {
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
}
.btnGrp {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-prev {
  margin-right: 70px;
}
.button-prev,
.button-next {
  cursor: pointer;
}
@media (max-width: 768px) {
  .features {
    padding: 16rem 0 3rem;
  }
  .imgBox {
    margin-top: 2.06rem;
  }

  .jh {
    position: absolute;
    top: 4rem;
    left: 1rem;
  }
  .dl {
    position: absolute;
    top: 4rem;
    left: calc(50% - 2.2rem);
  }
  .xx {
    position: absolute;
    top: 4rem;
    right: 0.4rem;
  }
  .descFont {
    color: #333;
    text-align: center;
    font-family: Inter;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .btnGrp {
    margin-top: 1.39rem;
  }
  .button-prev,
  .button-next {
    width: 1.375rem;
    height: 1.375rem;
  }
}
</style>
<style >
.swiper-slide {
  opacity: 0;
  /* transition: opacity 0.3s ease;  */
}
.swiper-slide.swiper-slide-active {
  opacity: 1;
}
</style>