<template>
  <Modal v-model="show" :loading="loading" @on-ok="submit" title="修改密码">
    <div>
      <Tabs :value="curTab">
        <TabPane label="通过原始密码修改" name="name1">
          <Form
            style="padding: 0px"
            ref="form"
            :model="formData"
            :rules="rules"
            :label-width="100"
          >
            <FormItem label="旧密码" prop="oldPassword">
              <Input
                v-model="formData.oldPassword"
                placeholder="请输入旧密码"
                type="password"
                password
              ></Input>
            </FormItem>
            <FormItem label="新密码" prop="password">
              <Input
                v-model="formData.password"
                placeholder="请输入新密码"
                type="password"
                password
              ></Input>
            </FormItem>
            <FormItem label="确认新密码" prop="password2">
              <Input
                v-model="formData.password2"
                placeholder="请确认新密码"
                type="password"
                password
              ></Input>
            </FormItem>
          </Form>
        </TabPane>
        <TabPane label="通过邮箱验证码修改" name="name2">
          <Form
            style="padding: 0px"
            ref="form2"
            :model="formData2"
            :rules="rules2"
            :label-width="100"
          >
            <FormItem label="邮箱" prop="email">
              <Input
                v-model="formData2.email"
                placeholder="请输入验证码"
                readonly
              ></Input>
            </FormItem>
            <FormItem label="验证码" prop="vcode" style="position: relative">
              <Input
                v-model="formData2.vcode"
                placeholder="请输入验证码"
              ></Input>
              <Button
                style="
                  width: 25%;
                  position: absolute;
                  right: 30px;
                  background-color: transparent;
                  border: none;
                  outline: none;
                  top: 0;
                "
                @click="sendVCode(formData2.email)"
                :disabled="formData2.account || sending > 1"
              >
                {{
                  sending > 1 ? "" + sending + "秒后再次发送" : "点击获取验证码"
                }}
              </Button>
            </FormItem>
            <FormItem label="新密码" prop="password">
              <Input
                v-model="formData2.password"
                placeholder="请输入新密码"
                type="password"
                password
              ></Input>
            </FormItem>
            <FormItem label="确认新密码" prop="password2">
              <Input
                v-model="formData2.password2"
                placeholder="请确认新密码"
                type="password"
                password
              ></Input>
            </FormItem>
          </Form>
        </TabPane>
      </Tabs>
    </div>
    <template #footer>
      <div style="text-align: center">
        <Button class="saveBtn" long :loading="loading" @click="changePwd"
          >修改</Button
        >
      </div>
    </template>
  </Modal>
</template>

<script>
import userService from "@/service/user/index";
import loginService from "@/service/login/index";
export default {
  name: "UserCenterChangePasswordModal",
  data() {
    return {
      show: false,
      loading: false,
      curTab: "name1",
      sending: 0,
      iid: 0,
      userInfo: {
        memberGroup: {},
        memberAccount: {},
        lastLoginLog: {},
      },
      formData: {
        oldPassword: "",
        password: "",
        password2: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "旧密码至少输入6位",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "密码至少输入6位",
            trigger: "blur",
          },
          {
            type: "string",
            max: 32,
            message: "密码最多输入32位",
            trigger: "blur",
          },
        ],
        password2: [
          { required: true, message: "请确认新密码", trigger: "blur" },

          {
            validator: (rule, value, callback) => {
              if (this.formData.password !== value) {
                callback(new Error("两次密码不一致"));
                return;
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
      formData2: {
        email: "",
        vcode: "",
        password: "",
        password2: "",
      },
      rules2: {
        vcode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "新密码至少输入6位",
            trigger: "blur",
          },
          {
            type: "string",
            max: 32,
            message: "密码最多输入32位",
            trigger: "blur",
          },
        ],
        password2: [
          { required: true, message: "请确认新密码", trigger: "blur" },

          {
            validator: (rule, value, callback) => {
              if (this.formData2.password !== value) {
                callback(new Error("两次密码不一致"));
                return;
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    open() {
      this.show = true;
    },
    async init() {
      this.userInfo = await userService.get();
      this.formData2.email = this.userInfo.account;
    },
    async sendVCode(account) {
      if (this.sending > 1) {
        return;
      }
      loginService.sendVCode(account);
      this.$Message.success("验证码发送成功，请前往邮箱查看");

      this.sending = 60;
      this.iid = window.setInterval(() => {
        if (--this.sending < 1) {
          window.clearInterval(this.iid);
        }
      }, 1000);
    },
    changePwd() {
      if (this.curTab == "name1") {
        this.submit();
      } else {
        this.submit2();
      }
    },
    submit() {
      let { oldPassword, password } = this.formData;
      let params = { oldPassword, password };

      let sub = async () => {
        this.loading = true;
        //ajax
        let result = await userService.changePassword(params);
        this.loading = false;
        if (!result) {
          return;
        }
        this.show = false;
        this.$Message.success("修改成功,请重新登陆");
        sessionStorage.removeItem("sk");
        sessionStorage.removeItem("userInfo");
        this.$router.push({ name: "Index" });
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          sub();
        }
      });
    },
    submit2() {
      let sub = async () => {
        let request = {
          email: this.formData2.email,
          vcode: this.formData2.vcode,
          password: this.formData2.password,
        };
        this.loading = true;
        let result = await loginService.resetPwdByVcode(request);
        this.loading = false;
        if (!result) {
          return;
        }
        this.show = false;
        this.$Message.success("修改成功,请重新登陆");
        sessionStorage.removeItem("sk");
        sessionStorage.removeItem("userInfo");
        this.$router.push({ name: "Index" });
      };

      this.$refs.form2.validate((valid) => {
        if (valid) {
          sub();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>