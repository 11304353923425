<style lang="scss" scoped>
.sale-warp {
  display: flex;
  .sale-item {
    width: 345px;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    margin-right: 20px;
    overflow: hidden;
    cursor: pointer;
    &.act {
      border-color: #3581ff;
      box-shadow: 0 6px 16px 0 #ccc;
    }
    .imgTitle1 {
      padding: 8px 22px;
      background-repeat: no-repeat;
      .name {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0px;
        color: #333333;
      }
      .desc {
        color: #676767;
        font-size: 12px;
        white-space: nowrap;
      }
    }
    .imgTitle2 {
      padding: 14px 22px;
      .name {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0px;
        color: #333333;
      }
    }
    .info {
      text-align: left;
      padding: 16px 22px;
      color: #000;
      border-bottom: 1px solid #e6e6e6;
      .price {
        font-weight: bold;
        font-size: 14px;
        color: #d90101;
      }
    }
  }
}
.tz {
  padding: 12px 22px 16px;
  .tzCol {
    display: flex;
    align-items: center;
    white-space: nowrap;
    img {
      margin-right: 4px;
    }
  }
}
.chargeNum {
  margin-top: 30px;
  color: #333;
  > span {
    font-weight: bold;
    font-size: 16px;
    margin-right: 12px;
  }
}

.payType {
  margin-top: 30px;
  color: #333;
  display: flex;
  align-items: flex-start;
  > span {
    font-weight: bold;
    font-size: 16px;
    margin-right: 12px;
  }
  > .pay-item {
    border-radius: 12px;
    width: 168px;
    padding: 7px 9px;
    border: 1px solid transparent;
    img {
      width: 100%;
    }
    &.act {
      border: 1px solid #3581ff;
      box-shadow: 0 6px 16px 0 #ccc;
    }
  }
}
.submitBtn {
  margin-top: 40px;
  padding-left: 80px;
  button {
    width: 360px;
    padding: 10px 31px !important;
  }
}

.modal-warp {
  .info-warp {
    padding: 0 20px;
    > div {
      border-bottom: 1px solid #aaa;
      &:nth-child(2) {
        padding-top: 16px;
      }
      > div {
        margin-bottom: 16px;
        img {
          width: 22px;
        }
      }
    }
  }
  .qrcode-warp {
    width: 100%;
    margin: 20px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tips {
      margin-top: 10px;
      color: #656565;
      font-size: 12px;
      text-align: center;
    }
  }
}

.saleGrpTitle {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  margin: 20px 0;
}

.mb16 {
  margin-bottom: 12px;
}
</style>
<style>
.payModal .ivu-modal-content {
  background-color: rgba(241, 241, 241, 0.7) !important;
  backdrop-filter: blur(20px); /**背景模糊*/
  -webkit-backdrop-filter: blur(20px); /**背景模糊*/
}
.payModal .ivu-modal-header {
  border-bottom: 1px solid #aaa !important;
}
.payModal .ivu-modal-footer {
  border-top: 1px solid #aaa !important;
}
</style>
<template>
  <div class="mainPage">
    <PageHeader :title="'充值（当前为' + userInfo.memberGroup.name + '）'" />
    <div v-for="(sale, idx) in sales" :key="sale.label">
      <div class="saleGrpTitle">{{ sale.label }}</div>
      <div class="sale-warp">
        <div
          class="sale-item"
          :class="{ act: selected.id == item.id }"
          v-for="item in sale.list"
          :key="item.id"
          @click="selectedSale(item)"
        >
          <template v-if="idx === 0">
            <div
              class="imgTitle1"
              :style="
                selected.id == item.id
                  ? 'background-image: url(' + getSelBGImg(item) + ')'
                  : 'background-color:#F1F1F1'
              "
            >
              <div
                class="name"
                :style="selected.id == item.id ? 'color: #fff' : 'color: #333'"
              >
                {{ item.name }}
              </div>
              <div class="desc" :style="selected.id == item.id ? 'color: #fff' : 'color: #656565'">{{ getDesc(item.name) }}</div>
            </div>
            <div class="info">
              <!-- <el-row class="mb16">
                <el-col :span="12">会员组类型</el-col>
                <el-col :span="12">字符会员</el-col>
              </el-row> -->
              <el-row class="mb16">
                <el-col :span="12">字符数</el-col>
                <el-col :span="12"
                  ><div
                    v-if="item.translationBillingMode == 'char'"
                    style="white-space: nowrap"
                  >
                    <span v-if="item.num <= 9999">{{ item.num }} 个字符</span>
                    <span v-else>{{ item.num / 10000 }} 万字符</span>
                  </div>
                  <div v-else style="white-space: nowrap">
                    {{ (item.num / 60 / 24 / 30).toFixed(0) }} 个月
                  </div></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="12">支付金额</el-col>
                <el-col :span="12" class="price"
                  >{{ item.salePrice / 100 }} 元</el-col
                >
              </el-row>
            </div>
            <div class="tz">
              <el-row>
                <el-col :span="12" class="tzCol"
                  ><img :src="support" alt="" />无时间限制</el-col
                >
                <el-col :span="12" class="tzCol"
                  ><img :src="support" alt="" />支持子账号</el-col
                >
              </el-row>
            </div>
          </template>
          <template v-else>
            <div
              class="imgTitle2"
              :style="{
                background:
                  selected.id == item.id
                    ? 'linear-gradient(to right, #1051FF, #3DAFFF)'
                    : '#F0F0F0',
              }"
            >
              <div
                class="name"
                :style="{ color: selected.id == item.id ? '#fff' : '#333' }"
              >
                {{ item.name }}（1个月）
              </div>
            </div>
            <div class="info">
              <!-- <el-row class="mb16">
                <el-col :span="12">会员组类型</el-col>
                <el-col :span="12">包月会员</el-col>
              </el-row> -->
              <el-row class="mb16">
                <el-col :span="12">字符数</el-col>
                <el-col :span="12">不限量</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">支付金额</el-col>
                <el-col :span="12" class="price"
                  >{{ item.salePrice / 100 }} 元</el-col
                >
              </el-row>
            </div>
            <div class="tz">
              <el-row>
                <el-col :span="12" class="tzCol"
                  ><img
                    :src="warnSupport"
                    alt=""
                    style="height: 22px; width: 22px; margin-top: -4px"
                  />时间限制30天</el-col
                >
                <el-col :span="12" class="tzCol"
                  ><img :src="notSupport" alt="" />不支持子账号</el-col
                >
              </el-row>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="chargeNum">
      <span>充值数量 : </span>
      <Input-number
        style="width: 390px"
        :min="1"
        :step="1"
        v-model="saleNum"
      ></Input-number>
    </div>
    <div class="payType">
      <span>支付方式 : </span>
      <div
        v-if="aliEnable"
        class="pay-item"
        style="margin-right: 20px"
        :class="{ act: payType == 'ALI_NATIVE' }"
        @click="selectedPayType('ALI_NATIVE')"
      >
        <img src="@/assets/alipay.png" />
      </div>
      <div
        v-if="wechatEnable"
        class="pay-item"
        :class="{ act: payType == 'WC_NATIVE' }"
        @click="selectedPayType('WC_NATIVE')"
      >
        <img src="@/assets/wechat.png" />
      </div>
    </div>
    <div class="submitBtn">
      <Button
        :disabled="payType ? false : true"
        :loading="loading"
        @click="pay"
        class="saveBtn"
        long
        >立即支付</Button
      >
    </div>
    <Modal
      :mask-closable="false"
      :closable="true"
      v-model="modal.show"
      title="请扫码支付"
      :width="530"
      class="payModal"
      @on-visible-change="modalChange"
    >
      <div class="modal-warp">
        <div class="info-warp">
          <div>
            <el-row>
              <el-col :span="12">支付渠道 </el-col>
              <el-col :span="12">
                <span>
                  <img
                    v-if="payType == 'ALI_NATIVE'"
                    class="icon"
                    src="@/assets/ali.png"
                  />
                  <img v-else class="icon" src="@/assets/wc.png" />
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">订单号 </el-col>
              <el-col :span="12">
                <span>
                  {{ modal.orderNo }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">支付金额 </el-col>
              <el-col :span="12">
                <span style="color: #d90101"
                  >¥{{ (selected.salePrice * saleNum) / 100 }} 元</span
                >
              </el-col>
            </el-row>
          </div>
          <div>
            <el-row>
              <el-col :span="12">会员组类型</el-col>
              <el-col :span="12">
                <span>{{
                  selected.translationBillingMode == "char"
                    ? "字符会员"
                    : "包月会员"
                }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">会员组名称</el-col>
              <el-col :span="12">
                <span>{{ selected.name }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">字符数</el-col>
              <el-col :span="12">
                <span
                  ><span v-if="selected.translationBillingMode == 'time'"
                    >不限量</span
                  >
                  <span v-else
                    >{{ (selected.num * saleNum) / 10000 }}万字</span
                  ></span
                >
              </el-col>
            </el-row>
            <el-row v-if="selected.translationBillingMode == 'time'">
              <el-col :span="12">包月数</el-col>
              <el-col :span="12">
                <span
                  >{{
                    new Number((selected.num / 60 / 24 / 30).toFixed(0)) *
                    saleNum
                  }}个月</span
                >
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="qrcode-warp">
          <div id="qrcode"></div>
          <div class="tips">请尽快支付，5分钟后将自动关闭二维码页面</div>
        </div>
      </div>
    </Modal>
    <Modal
      :mask-closable="false"
      v-model="tipModal.show"
      title="温馨提示"
      :width="300"
      ok-text="已完成充值"
      cancel-text="未完成"
      @on-visible-change="tipModalChange"
      @on-ok="tipModalOk"
      @on-cancel="tipModalCancel"
    >
      <div>请问您是否已经完成充值?</div>
    </Modal>
    <div ref="alipayWap" v-html="alipay" />
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import PageHeader from "../components/common/pageHeader.vue";
import userService from "@/service/user";
import memberGroupService from "@/service/member/group";
import orderBymemberGroupService from "@/service/order/memberGroup";

import support from "@/assets/imgs/home/demand/support.png";
import notSupport from "@/assets/imgs/home/demand/notSupport.png";
import warnSupport from "@/assets/imgs/home/demand/warnSupport.png";

import ai1 from "@/assets/userCenter/ai1.png";
import ai2 from "@/assets/userCenter/ai2.png";
import bz1 from "@/assets/userCenter/bz1.png";
import bz2 from "@/assets/userCenter/bz2.png";
import gj1 from "@/assets/userCenter/gj1.png";
import gj2 from "@/assets/userCenter/gj2.png";
import zy1 from "@/assets/userCenter/zy1.png";
import zy2 from "@/assets/userCenter/zy2.png";

export default {
  name: "UserCenterRecharge",
  components: { PageHeader },
  data() {
    return {
      ai1,
      ai2,
      bz1,
      bz2,
      gj1,
      gj2,
      zy1,
      zy2,
      support,
      notSupport,
      warnSupport,
      alipay: "",

      loading: false,
      userInfo: {
        memberGroup: {},
        memberAccount: {},
        lastLoginLog: {},
      },
      sales: [],
      selected: {},
      saleNum: 1,
      payType: "WC_NATIVE",
      modal: {
        show: false,
        url: "",
        orderId: 0,
        orderNo: "",
      },
      tipModal: {
        show: false,
      },
      iid: 0,
      aliEnable: false,
      wechatEnable: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loadUserInfo();
      this.loadSales();
      this.loadPayChannel();
    },
    getBGImg(item) {
      switch (item.name) {
        case "高级版":
          return gj1;
        case "专业版":
          return zy1;
        case "标准版":
          return bz1;
        case "AI翻译":
          return ai1;
        default:
          return bz1;
      }
    },
    getSelBGImg(item) {
      switch (item.name) {
        case "高级版":
          return gj2;
        case "专业版":
          return zy2;
        case "标准版":
          return bz2;
        case "AI翻译":
          return ai2;
        default:
          return bz2;
      }
    },
    getDesc(name) {
      switch (name) {
        case "高级版":
          return "支持谷歌、微软两种翻译通道任意切换";
        case "专业版":
          return "支持Deepl精准翻译";
        case "标准版":
          return "支持腾讯、有道两种翻译通道任意切换";
        case "AI翻译":
          return "支持ChatGpt智能翻译";
        case "GPT会员":
          return "支持ChatGpt智能翻译";
        default:
          return "支持腾讯、有道两种翻译通道任意切换";
      }
    },
    async loadUserInfo() {
      this.userInfo = await userService.get();
    },
    async loadSales() {
      let sales = await memberGroupService.saleList();
      let time = { label: "包月用户", list: [] };
      let char = { label: "字符用户", list: [] };
      for (let i = 0; i < sales.length; i++) {
        let item = sales[i];
        let target = item.translationBillingMode == "char" ? char : time;
        target.list.push(item);
      }
      this.sales.push(char);
      this.sales.push(time);
    },
    async loadPayChannel() {
      let channels = await orderBymemberGroupService.getPayChannels();
      for (let i = 0; i < channels.length; i++) {
        if (channels[i] === "ali") {
          this.aliEnable = true;
        }
        if (channels[i] === "wechat") {
          this.wechatEnable = true;
        }
      }
    },
    selectedSale(item) {
      this.selected = item;
    },
    selectedPayType(payType) {
      this.payType = payType;
    },
    qpsRateFmt(item) {
      if (!item || !item.qpsRate) {
        return "无限制";
      }
      let arr = JSON.parse(item.qpsRate);
      let sec = arr[0];
      let min = arr[1];
      if (!sec && !min) {
        return "无限制";
      }
      return (
        "每秒 " +
        ((sec ? sec : "无限制") + " 次") +
        "," +
        "每分 " +
        ((min ? min : "无限制") + " 次")
      );
    },
    async pay() {
      let params = {
        memberGroupId: this.selected.id,
        salesNum: this.saleNum,
        payChannel: this.payType,
      };
      this.loading = true;
      let result = await orderBymemberGroupService.pay(params);
      if (!result) {
        this.loading = false;
        return;
      }
      this.toPayPage(result);
    },
    toPayPage(payInfo) {
      if (this.$data.payType === "ALI_NATIVE") {
        // 渲染支付页面
        this.alipay = payInfo.scanQRCodeUrl;
        console.log(this.alipay);
        // 防抖避免重复支付
        this.$nextTick(() => {
          // 提交支付表单
          console.log(this.$refs.alipayWap.children[0]);
          this.$refs.alipayWap.children[0].acceptCharset = "GBK";
          this.$refs.alipayWap.children[0].submit();
          setTimeout(() => {
            // this.toPayFlag = false;
          }, 500);
        });
        return;
      }
      let qrCodeUrl = payInfo.scanQRCodeUrl;
      this.modal.show = true;
      this.modal.orderId = payInfo.orderId;
      this.modal.orderNo = payInfo.orderNo;
      this.modal.url = qrCodeUrl;
      window.document.getElementById("qrcode").innerHTML = "";
      new QRCode("qrcode", {
        width: 200,
        height: 200,
        text: qrCodeUrl,
      });
      window.clearInterval(this.iid);
      window.clearTimeout(this.tid);
      this.iid = window.setInterval(() => {
        this.queryOrderPayInfo();
      }, 500);

      this.tid = window.setTimeout(() => {
        this.modal.show = false;
      }, 5 * 60 * 1000);
    },
    async queryOrderPayInfo() {
      let payInfo = await orderBymemberGroupService.getPayInfo(
        this.modal.orderId
      );
      if (payInfo && payInfo.payStatus == "P") {
        this.paySuccess(payInfo);
      }
    },
    paySuccess(payInfo) {
      window.clearInterval(this.iid);
      window.clearTimeout(this.tid);
      let str = encodeURIComponent(JSON.stringify(payInfo));
      this.$router.push({ name: "PaySuccess", query: { payInfo: str } });
    },
    modalChange(e) {
      if (e) {
        return;
      }
      this.tipModal.show = true;
    },
    async tipModalOk() {
      window.clearInterval(this.iid);
      window.clearTimeout(this.tid);
      this.loading = true;
      let result = await orderBymemberGroupService.flush(this.modal.orderId);
      if (result) {
        this.queryOrderPayInfo();
      }
      this.loading = false;
    },
    tipModalCancel() {
      window.clearInterval(this.iid);
      window.clearTimeout(this.tid);
    },
    tipModalChange(e) {
      if (e) {
        return;
      }
      this.loading = false;
    },
  },
  beforeUnmount() {
    window.clearInterval(this.iid);
    window.clearTimeout(this.tid);
  },
};
</script>

