import http from "@/utils/http.js"

export default{
    async list(){
        let api = "/translationLanguages/list";
        let result = await http.get(api);
        return result.success && result.data;
    },
    async text(params){
        let api = "/translation/text";
        let result = await http.post(api,params);
        return result.success && result.data;
    },
    
}