import http from "@/utils/http.js"

export default {
    async list() {
        // let api = "/login/notice/list";
        // let api = "/notice/list";
        // let api = "/xxx/list";
        // let api = "/member/test/get";
        let api = "/notice/list";
        let result = await http.get(api);
        return result.success && result.data;
    },


    async closeSse(clientId) {
        let api = "/notice/closeSse/" + clientId;
        await http.delete(api);
    },
}