import http from "@/utils/http.js"

export default{
    async get(id){
        let api = "/dataCus/get";
        let result = await http.get(api,{id});
        return result.success && result.data;
    },
    async save(params){
        let api = "/dataCus/save";
        let result = await http.post(api,params);
        return result.success && result.data;
    },
    
}