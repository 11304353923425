import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/Index.vue'
import PaySuccess from '../views/PaySuccess.vue'
import UserCenter from '../views/UserCenter.vue'
import UserCenterIndex from '../views/UserCenterIndex.vue'
import UserCenterInfo from '../views/UserCenterInfo.vue'
import UserCenterChangePassword from '../views/UserCenterChangePassword.vue'
import UserCenterExit from '../views/UserCenterExit.vue'
import UserCenterLoginLog from '../views/UserCenterLoginLog.vue'
import UserCenterChild from '../views/UserCenterChild.vue'
import UserCenterRecharge from '../views/UserCenterRecharge.vue'
import UserCenterAccountLog from '../views/UserCenterAccountLog.vue'
import UserCenterChangeTranslateLog from '../views/UserCenterChangeTranslateLog.vue'
import UserCenterChangeTranslateTest from '../views/UserCenterChangeTranslateTest.vue'
import UserCenterOrder from '../views/UserCenterOrder.vue'
// import Download from '../views/Download.vue'
import login from '../views/LoginDrawer.vue'
import UserWorkSpace from '../views/UserWorkSpace.vue'
import Home from "@/components/index/Home";
import price from "@/components/pageComponets/price";
import useDoc from "@/components/pageComponets/useDoc";
import contactUs from "@/components/pageComponets/contactUs";
import download from "@/components/pageComponets/download";
import Instagram from "@/components/pageComponets/Instagram";
const routes = [
  {
    path: '',
    redirect: "/home"
  },
  // {
  //   path: "/Download",
  //   name: "Download",
  //   component: Download
  // },
  {
    path: '/',
    name: 'Index',
    component: Index,
    children: [
      { path: "/home", name: "home", component: Home },
      { path: "/price", name: "price", component: price },
      { path: "/useDoc", name: "useDoc", component: useDoc },
      { path: "/contactUs", name: "contactUs", component: contactUs },
      { path: "/download", name: "download", component: download },
      { path: "/Instagram", name: "Instagram", component: Instagram },
    ]
  },
  {
    path: '/PaySuccess',
    name: 'PaySuccess',
    component: PaySuccess
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/userCenter',
    name: 'UserCenter',
    component: UserCenter,
    children: [
      { path: "/UserCenterIndex", name: "UserCenterIndex", component: UserCenterIndex },
      { path: "/UserCenterInfo", name: "UserCenterInfo", component: UserCenterInfo },
      { path: "/UserCenterChangePassword", name: "UserCenterChangePassword", component: UserCenterChangePassword },
      { path: "/UserCenterExit", name: "UserCenterExit", component: UserCenterExit },
      { path: "/UserCenterLoginLog", name: "UserCenterLoginLog", component: UserCenterLoginLog },
      { path: "/UserCenterChild", name: "UserCenterChild", component: UserCenterChild },
      { path: "/UserCenterRecharge", name: "UserCenterRecharge", component: UserCenterRecharge },
      { path: "/UserCenterAccountLog", name: "UserCenterAccountLog", component: UserCenterAccountLog },
      { path: "/UserCenterChangeTranslateLog", name: "UserCenterChangeTranslateLog", component: UserCenterChangeTranslateLog },
      { path: "/UserCenterChangeTranslateTest", name: "UserCenterChangeTranslateTest", component: UserCenterChangeTranslateTest },
      { path: "/UserCenterOrder", name: "UserCenterOrder", component: UserCenterOrder },
      { path: "/UserWorkSpace", name: "UserWorkSpace", component: UserWorkSpace },
    ]
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/home"
  },
]

const router = createRouter({
  // mode: "hash",
  history: createWebHashHistory(),
  routes
})

export default router
