import { createApp } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
import store from './store'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import 'swiper/css/swiper.min.css'
import dayjs from "dayjs"
import './assets/css/main.css';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import ElementPlus from 'element-plus';

// =========== 全局组件 ==============

import TranslateChannelSelect from "@/components/TranslateChannelSelect"
import TranslateChannelLabel from "@/components/TranslateChannelLabel"

let vm = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    vm.component(key, component)
}
vm.config.globalProperties.$dayjs = dayjs;
vm.component('TranslateChannelSelect', TranslateChannelSelect);
vm.component('TranslateChannelLabel', TranslateChannelLabel);

vm.use(ElementPlus)
vm.use(ElementPlusIconsVue)
vm
    .use(store)
    .use(ViewUIPlus)
    .use(router)
    .mount('#app')
