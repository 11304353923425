

<style lang="scss">
.ivu-drawer-body {
  padding: 0 !important;
  margin: 0;
}
.tabs {
  display: flex;
  .tab {
    width: 50%;
    text-align: center;
    margin-bottom: 25px;
    background: #dededd;
    &:first-child {
      border-right: 0;
    }
    padding: 10px 0;
    border: 1px solid #606060;
    &.act {
      border-color: #2d8cf0;
      background: #2d8cf0;
      color: #fff;
    }
    cursor: pointer;
  }
  .quick-btn-warp {
    padding-top: 155px;
  }
}
</style>
<template>
  <Drawer
    :width="450"
    :mask-closable="false"
    v-model="show"
    title="分配子账号语音条数"
  >
    <Form
      style="padding: 20px !important"
      ref="form"
      :model="formData"
      :rules="rules"
      label-position="top"
    >
      <FormItem label="子账户账号">
        <Input disabled v-model="formData.account" />
      </FormItem>
      <FormItem label="子账户昵称">
        <Input disabled v-model="formData.nickname" />
      </FormItem>
      <FormItem label="子账户当前语音数">
        <Input disabled v-model="formData.usableVoiceNum" />
      </FormItem>
      <FormItem label="可分配语音数">
        <Input disabled v-model="userInfo.memberAccount.usableVoiceNum" />
      </FormItem>
      <FormItem label="变更语音条数" prop="changeVoiceNum">
        <InputNumber
          style="width: 100%"
          :max="userInfo.memberAccount.usableVoiceNum"
          :step="10000"
          :min="0"
          v-model="formData.changeVoiceNum"
        />
        <!-- <div style="padding-top:15px">
                    <Button class="btn" style="margin-right:5px" 
                        v-for="item in btns" :key="item.num"
                        :disabled="userInfo.memberAccount.usableVoiceNum < item.num" 
                        @click="formData.changeVoiceNum = item.num"
                    >
                        {{item.label}}
                    </Button>
                </div> -->
      </FormItem>
      <FormItem label="变更详情" prop="changeVoiceNum">
        <Row>
          <Divider orientation="left">子账户信息</Divider>
          <Col span="6">变更前</Col>
          <Col span="18">{{ formData.usableVoiceNum }}</Col>
          <Col span="6">变更后</Col>
          <Col span="18">{{ formData.changeVoiceNum }}</Col>
          <Col span="6">净值差</Col>
          <Col span="18">
            <span
              style="color: red"
              v-if="formData.changeVoiceNum > formData.usableVoiceNum"
              >+{{ formData.changeVoiceNum - formData.usableVoiceNum }}</span
            >
            <span
              style="color: green"
              v-if="formData.changeVoiceNum < formData.usableVoiceNum"
              >-{{ formData.usableVoiceNum - formData.changeVoiceNum }}</span
            >
            <span
              style="color: #a7a7a7"
              v-if="formData.changeVoiceNum == formData.usableVoiceNum"
              >无变更</span
            >
          </Col>
          <Divider orientation="left">主账户信息</Divider>
          <Col span="6">变更前</Col>
          <Col span="18">{{ userInfo.memberAccount.usableVoiceNum }}</Col>
          <Col span="6">变更后</Col>
          <Col span="18">
            <span
              v-if="
                userInfo.memberAccount.usableVoiceNum +
                  formData.usableVoiceNum -
                  formData.changeVoiceNum >
                -1
              "
            >
              {{
                userInfo.memberAccount.usableVoiceNum +
                formData.usableVoiceNum -
                formData.changeVoiceNum
              }}
            </span>
            <span v-else>余额不足</span>
          </Col>
          <Col span="6">净值差</Col>
          <Col span="18">
            <span
              style="color: green"
              v-if="formData.changeVoiceNum > formData.usableVoiceNum"
            >
              -{{ formData.changeVoiceNum - formData.usableVoiceNum }}
            </span>
            <span
              style="color: red"
              v-if="formData.changeVoiceNum < formData.usableVoiceNum"
            >
              +{{ formData.usableVoiceNum - formData.changeVoiceNum }}
            </span>
            <span
              style="color: #a7a7a7"
              v-if="formData.changeVoiceNum == formData.usableVoiceNum"
              >无变更</span
            >
          </Col>
        </Row>
      </FormItem>
      <FormItem>
        <Button
          @click="submit"
          :loading="loading"
          :disabled="
            userInfo.memberAccount.usableVoiceNum +
              formData.usableVoiceNum -
              formData.changeVoiceNum <
              0 || formData.changeVoiceNum == formData.usableVoiceNum
          "
          long
          type="primary"
          size="large"
        >
          分配
        </Button>
      </FormItem>
    </Form>
  </Drawer>
</template>

<script>
import userService from "@/service/user/index";
export default {
  name: "ChildUserDistributionDrawer",
  emits: ["on-success"],
  data() {
    return {
      show: false,
      userInfo: {
        memberAccount: {},
      },
      btns: [
        { num: 10000, label: "1万字" },
        { num: 50000, label: "5万字" },
        { num: 100000, label: "10万字" },
        { num: 200000, label: "20万字" },
        { num: 500000, label: "50万字" },
      ],
      rules: {},
      formData: {
        userId: null,
        account: "",
        nickname: "",
        usableVoiceNum: 0,
        changeVoiceNum: 0,
      },
      loading: false,
    };
  },
  mounted() {},
  methods: {
    async open(userId) {
      this.show = true;
      this.userInfo = await userService.get();
      this.initUpdateForm(userId);
    },
    async initUpdateForm(userId) {
      let userInfo = await userService.getChildInfo(userId);
      let usableVoiceNum = userInfo.memberAccount.usableVoiceNum;
      usableVoiceNum = usableVoiceNum ? usableVoiceNum : 0;
      this.formData = {
        userId: userInfo.userId,
        account: userInfo.account,
        nickname: userInfo.nickname,
        usableVoiceNum: usableVoiceNum,
        changeVoiceNum: usableVoiceNum,
      };
    },
    submit() {
      let { userId, changeVoiceNum } = this.formData;
      let params = { childUserId: userId, changeCharNum: changeVoiceNum };

      let sub = async () => {
        this.loading = true;
        //ajax
        let result = await userService.distributionVoice(params);
        this.loading = false;
        if (!result) {
          return;
        }
        this.success();
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          sub();
        }
      });
    },
    success() {
      this.$Message.success("操作成功");
      this.$emit("on-success");
      this.show = false;
    },
  },
};
</script>