<style lang="scss" scoped>
.sale-warp {
  display: flex;
  margin-bottom: 30px;
  .sale-item {
    width: 345px;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    margin-right: 20px;
    overflow: hidden;
    cursor: pointer;
    &.act {
      border-color: #3581ff;
      box-shadow: 0 6px 16px 0 #ccc;
    }
    .imgTitle1 {
      padding: 17px 22px;
      .name {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 0px;
        color: #fff;
      }
    }
    .info {
      text-align: left;
      padding: 16px 22px;
      color: #000;
      border-bottom: 1px solid #e6e6e6;
    }
  }
}

.mb16 {
  margin-bottom: 12px;
}

.base-warp {
  display: flex;
  max-width: 100%;
  .source-warp {
    max-width: 650px;
    flex: 1;
    height: 250px;
    padding-top: 0;
    padding-left: 0;
    position: relative;
    .tranSel {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 999;
      width: 390px;
      height: 36px;
    }
  }
  .trans {
    width: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: inline;
    }
  }
  .tran-warp {
    max-width: 650px;
    height: 250px;
    flex: 1;
    padding-right: 0;
    position: relative;
    .tranSel {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 999;
      width: 390px;
      height: 36px;
    }
  }
}
.transBtn {
    width: 96px;
    height: 96px;
    border-radius: 80px;
    background-image: linear-gradient(to bottom, #1051FF, #3DAFFF);
}
</style>
<style lang="scss">
.textAreaIpt {
  max-width: 650px !important;
  .ivu-input {
    max-width: 650px !important;
    background-color: #f6f6f6 !important;
    padding: 60px 20px 20px;
  }
}
</style>
<template>
  <div class="mainPage">
    <PageHeader title="翻译测试" />
    <div>
      <div class="sale-warp">
        <div class="sale-item">
          <div
            class="imgTitle1"
            :style="{
              backgroundImage: 'url(' + bz2 + ')',
            }"
          >
            <div class="name">{{ userInfo.memberGroup.name }}</div>
          </div>
          <div class="info">
            <el-row class="mb16">
              <el-col :span="10">会员类型</el-col>
              <el-col :span="8">{{
                userInfo.memberGroup.translationBillingMode == "time"
                  ? "包月用户"
                  : "字符用户"
              }}</el-col>
            </el-row>
            <el-row class="mb16">
              <el-col :span="10">翻译渠道</el-col>
              <el-col :span="8"
                ><TranslateChannelLabel :tranChannelId="userInfo.tranChannelId"
              /></el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div class="base-warp">
      <div class="source-warp">
        <div style="margin-bottom: 5px">
          <Select
            placeholder="请选择原文语种"
            filterable
            v-model="formData.source"
            class="tranSel"
          >
            <Option
              v-for="item in sourceList"
              :key="item.id"
              :value="item.langCode"
            >
              {{ item.chineseName }}
            </Option>
          </Select>
        </div>
        <Input
          class="textAreaIpt"
          :rows="8"
          type="textarea"
          v-model="formData.text"
        />
      </div>
      <div class="trans">
        <img :src="trans" alt="" />
      </div>
      <div class="tran-warp">
        <div style="margin-bottom: 5px">
          <Select
            placeholder="请选择原文语种"
            filterable
            v-model="formData.target"
            class="tranSel"
          >
            <Option
              v-for="item in targetList"
              :key="item.id"
              :value="item.langCode"
            >
              {{ item.chineseName }}
            </Option>
          </Select>
        </div>
        <Input
          class="textAreaIpt"
          readonly
          v-model="tranText"
          :rows="8"
          type="textarea"
        />
      </div>
    </div>
    <div style="margin-top: 40px; text-align: center;width: 100%;max-width: 1400px;">
      <Button long size="large" :loading="loading" type="primary" @click="tran" class="transBtn"
        >翻译测试</Button
      >
    </div>
  </div>
</template>

<script>
import bz2 from "@/assets/userCenter/bz2.png";
import userService from "@/service/user";
import translationService from "@/service/translation";
import PageHeader from "../components/common/pageHeader.vue";
import trans from "@/assets/userCenter/trans.png";

export default {
  name: "UserCenterChangeTranslateTest",
  components: { PageHeader },
  data() {
    return {
      bz2,
      trans,
      userInfo: {
        memberGroup: {},
      },
      loading: false,
      sourceList: [],
      targetList: [],
      tranText: "",
      formData: {
        source: "",
        target: "",
        text: "",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loadUserInfo();
      this.loadData();
    },
    async loadUserInfo() {
      let userInfo = await userService.get();
      this.userInfo = userInfo;
    },
    async loadData() {
      let res = await translationService.list();
      this.sourceList = res.sourceList;
      this.targetList = res.targetList;
      console.log(res);
    },
    async tran() {
      this.loading = true;
      let params = this.formData;
      let res = await translationService.text(params);
      this.loading = false;
      if (!res) {
        return;
      }
      this.$Message.success("翻译成功");
      this.tranText = res.translated;
    },
  },
};
</script>

