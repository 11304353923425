
<style lang="scss" scoped>
@import "~@/css/common";

$headerHeight: 74px;
.content {
  header {
    width: 100%;
    // height: $headerHeight;
    background: #e0eaf6;
    .content2 {
      display: flex;
      .logo {
        img {
          padding-top: 5px;
          width: 100%;
          height: 90%;
        }
      }
      .nav-warp {
        display: flex;
        margin-left: auto;
        .nav-item {
          line-height: $headerHeight;
          padding: 0 15px;
          cursor: pointer;
          &:hover,
          &.act {
            border-bottom: 4px solid #016cff;
            transition: 0.2s;
          }
        }
      }
    }
  }
  .base-warp {
    position: absolute;
    left: 0;
    top: $headerHeight;
    width: 100%;
    height: calc(100% - #{$headerHeight});
    // overflow: hidden;
    .center {
      min-width: $MINW;
      margin: 0 auto;
      height: 100%;
      display: flex;
      .menu-warp {
        width: 280px;
        padding: 25px;
        padding-right: 10px;
      }
      .router-warp {
        flex: 1;
        padding: 25px 0;
        padding-right: 10px;
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }
}
.content2 {
  margin: 0 auto;
  height: $headerHeight !important;
  width: 1024px !important;
}
.nav {
  position: relative;
}
.logoImg {
  height: 2.625rem;
}
.menuItem {
  font-size: 1rem;
  color: #000000;
  margin-left: 2rem;
  font-weight: 600;
}
.menuItem.active {
  color: #3581ff;
}
</style>
<template>
  <div class="userCenterHeader content">
    <header style="padding: 8px 0; position: relative; z-index: 99">
      <div class="flex justify-between nav mainContent">
        <div class="cursor-pointer">
          <img
            src="@/assets/imgs/footer/footerLogo.png"
            alt=""
            class="logoImg"
            @click="clickLogo"
          />
        </div>
        <div class="flex items-center">
          <div class="flex items-center">
            <div
              @click="actdNav(nav)"
              ref="nav"
              class="menuItem cursor-pointer"
              v-for="nav in navs"
              :key="nav.label"
            >
              {{ nav.label }}
            </div>
            <div
              v-if="userInfo.userId"
              class="menuItem cursor-pointer active"
              @click="toIndex"
            >
              控制台
            </div>
            <div class="menuItem cursor-pointer" v-if="userInfo.userId">
              <Poptip trigger="hover" title="更多操作">
                <div style="display: flex; align-items: center">
                  <img
                    :src="ava"
                    alt=""
                    style="display: inline; height: 40px; width: 40px"
                  />
                  <a style="padding: 0 8px">{{ userInfo.nickname }}</a>
                </div>

                <template #content>
                  <div><a @click="toUserCenter">会员中心</a></div>
                  <div><a @click="toUserCenterChangePassword">修改密码</a></div>
                  <div><a style="color: #ed4014" @click="logout">注销</a></div>
                </template>
              </Poptip>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="base-warp">
      <div class="center">
        <div class="menu-warp">
          <Menu :active-name="activeName" @on-select="menuClick">
            <MenuGroup
              :title="menu.label"
              v-for="(menu, idx) in menus"
              :key="menu.id"
            >
              <MenuItem
                v-show="item.showFn ? item.showFn(userInfo) : true"
                :to="item.name"
                :name="item.id"
                v-for="(item, j) in menu.child"
                :key="item.id"
              >
                <div style="display: flex; align-item: center">
                  <img
                    :src="
                      activeName == item.id
                        ? getSelMenuIcon(idx * menu.child.length + j)
                        : getMenuIcon(idx * menu.child.length + j)
                    "
                    alt=""
                    style="margin-right: 6px"
                  />
                  {{ item.label }}
                </div>
              </MenuItem>
            </MenuGroup>
          </Menu>
        </div>
        <div class="router-warp">
          <router-view />
        </div>
      </div>
    </div>
    <UserCenterChangePasswordModal ref="UserCenterChangePasswordModalRef" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import userService from "@/service/user";
import UserCenterChangePasswordModal from "@/components/UserCenterChangePasswordModal.vue";
import ava from "@/assets/userCenter/ava.png";
import use1 from "@/assets/userCenter/nav/user1.png";
import use2 from "@/assets/userCenter/nav/user2.png";
import grzl1 from "@/assets/userCenter/nav/grzl1.png";
import grzl2 from "@/assets/userCenter/nav/grzl2.png";
import xgmm1 from "@/assets/userCenter/nav/xgmm1.png";
import xgmm2 from "@/assets/userCenter/nav/xgmm2.png";
import dljl1 from "@/assets/userCenter/nav/dljl1.png";
import dljl2 from "@/assets/userCenter/nav/dljl2.png";
import zzh1 from "@/assets/userCenter/nav/zzh1.png";
import zzh2 from "@/assets/userCenter/nav/zzh2.png";
import cz1 from "@/assets/userCenter/nav/cz1.png";
import cz2 from "@/assets/userCenter/nav/cz2.png";
import czdd1 from "@/assets/userCenter/nav/czdd1.png";
import czdd2 from "@/assets/userCenter/nav/czdd2.png";
import zhrz1 from "@/assets/userCenter/nav/zhrz1.png";
import zhrz2 from "@/assets/userCenter/nav/zhrz2.png";
import fyrz1 from "@/assets/userCenter/nav/fyrz1.png";
import fyrz2 from "@/assets/userCenter/nav/fyrz2.png";
import fycs1 from "@/assets/userCenter/nav/fycs1.png";
import fycs2 from "@/assets/userCenter/nav/fycs2.png";
export default {
  name: "UserCenter",
  components: { UserCenterChangePasswordModal },
  data() {
    return {
      activeName: 2,
      ava,
      navIcon: [
        use1,
        grzl1,
        xgmm1,
        dljl1,
        zzh1,
        cz1,
        czdd1,
        zhrz1,
        fyrz1,
        fycs1,
      ],
      selectedNavIcon: [
        use2,
        grzl2,
        xgmm2,
        dljl2,
        zzh2,
        cz2,
        czdd2,
        zhrz2,
        fyrz2,
        fycs2,
      ],
      userInfo: {},
      // navs: [
      //     {label: "首页", img: require("@/assets/bg/home.png"),component:"Home"},
      //     {label: "定价标准", img: require("@/assets/bg/price.png"),component:"Price"},
      //     {label: "使用文档", img: require("@/assets/bg/doc.png"),component:"Doc"},
      //     {label: "联系我们", img: require("@/assets/bg/home.png"),component:"Home"},
      // ],
      navs: [
        {
          label: "首页",
          img: require("@/assets/bg/home.png"),
          component: "Home",
          url: "/home",
        },
        {
          label: "定价标准",
          img: require("@/assets/bg/price.png"),
          component: "price",
          url: "/price",
        },
        {
          label: "使用文档",
          img: require("@/assets/bg/doc.png"),
          component: "useDoc",
          url: "/useDoc",
        },
        // {
        //   label: "软件下载",
        //   img: require("@/assets/bg/home.png"),
        //   component: "download",
        //   url: "/download",
        // },
        {
          label: "联系我们",
          img: require("@/assets/bg/doc.png"),
          component: "contactUs",
          url: "/contactUs",
        },
        {
          label: "Instagram营销",
          img: require("@/assets/bg/price.png"),
          component: "Instagram",
          url: "/Instagram",
        },
      ],
      actNav: {},
      menus: [
        {
          id: 1,
          label: "会员中心",
          child: [
            { id: 2, label: "会员中心", icon: "", name: "UserCenterIndex" },
            { id: 3, label: "个人资料", icon: "", name: "UserCenterInfo" },
            {
              id: 4,
              label: "修改密码",
              icon: "",
              name: "UserCenterChangePassword",
            },
            { id: 5, label: "登陆记录", icon: "", name: "UserCenterLoginLog" },
            {
              id: 6,
              label: "子账户管理",
              icon: "",
              name: "UserCenterChild",
              showFn: (userInfo) => {
                if (userInfo.parentUserId) {
                  return false;
                }
                return true;
              },
            },
          ],
        },
        {
          id: 11,
          label: "功能管理",
          child: [
            {
              id: 12,
              label: "充值",
              icon: "",
              name: "UserCenterRecharge",
              showFn: (userInfo) => {
                if (userInfo.parentUserId) {
                  return false;
                }
                return true;
              },
            },
            {
              id: 13,
              label: "充值订单",
              icon: "",
              name: "UserCenterOrder",
              showFn: (userInfo) => {
                if (userInfo.parentUserId) {
                  return false;
                }
                return true;
              },
            },
            {
              id: 14,
              label: "账户日志",
              icon: "",
              name: "UserCenterAccountLog",
            },
            {
              id: 15,
              label: "翻译日志",
              icon: "",
              name: "UserCenterChangeTranslateLog",
            },
            {
              id: 16,
              label: "翻译测试",
              icon: "",
              name: "UserCenterChangeTranslateTest",
            },
            // todo
            // {
            //   id: 17,
            //   label: "工作空间",
            //   icon: "",
            //   name: "UserWorkSpace",
            // },
          ],
        },
      ],
    };
  },
  mounted() {
    this.init();
    this.FLUSH_TRAN_CHANNEL();
  },
  methods: {
    ...mapActions(["FLUSH_TRAN_CHANNEL"]),
    getSelMenuIcon(idx) {
      if (this.selectedNavIcon[idx]) {
        return this.selectedNavIcon[idx];
      } else {
        return czdd2;
      }
    },
    getMenuIcon(idx) {
      if (this.navIcon[idx]) {
        return this.navIcon[idx];
      } else {
        return czdd1;
      }
    },
    async init() {
      this.userInfo = await userService.get();
    },
    selectedNav(nav) {
      this.go(nav);
    },
    go(nav) {
      let { url } = nav;
      if (!url) {
        this.toIndex();
        return;
      }
      window.open(url, nav.target);
    },
    toIndex() {
      this.$router.push({ name: "Index" });
    },
    toUserCenter() {
      this.$router.push({ name: "UserCenterIndex" });
    },
    toUserCenterChangePassword() {
      this.$refs.UserCenterChangePasswordModalRef.open();
      // this.$router.push({ name: "UserCenterChangePassword" });
    },
    logout() {
      this.userInfo = {};
      sessionStorage.removeItem("sk");
      sessionStorage.removeItem("userInfo");
      this.$router.push("/home");
    },
    actdNav(nav) {
      this.$router.push({ path: nav.url, query: { act: nav.label } });
      // this.$router.push({ name: "Index", query: { act: nav.label } });
    },
    menuClick(e) {
      this.activeName = e;
    },
  },
};
</script>
