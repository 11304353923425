

<style lang="scss">
.distributionForm {
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  flex-wrap: wrap;
  .formItem {
    width: 50%;
    &:nth-child(2) {
      margin-top: 0px !important;
    }
  }
  .formItemIpt {
    width: 256px;
  }
  .numSelGrp {
    display: inline-block;
    margin-left: 10px;
    button {
      border-radius: 33px;
    }
  }
}
.changeDetail {
  padding-top: 20px;
  color: #333333;
  .detailRow {
    padding: 0 36px;
    padding-top: 10px;
  }
  .detailTitle {
    color: #000000;
    font-weight: bold;
  }
}
</style>
<template>
  <Modal
    v-model="show"
    :loading="loading"
    @on-ok="submit"
    title="分配子账户"
    style="top: 15%"
    width="850px"
  >
    <div>
      <Tabs :value="curTab">
        <TabPane label="分配子账户字符" name="char">
          <Form
            class="distributionForm"
            ref="form"
            style="padding-bottom: 20px !important"
            :model="formData"
            :rules="rules"
            :label-width="130"
          >
            <FormItem label="子账户账号:" class="formItem">
              <Input disabled v-model="formData.account" class="formItemIpt" />
            </FormItem>
            <FormItem label="子账户昵称:" class="formItem">
              <Input disabled v-model="formData.nickname" class="formItemIpt" />
            </FormItem>
            <FormItem label="子账户当前字符数:" class="formItem">
              <Input
                disabled
                v-model="formData.usableCharNum"
                class="formItemIpt"
              />
            </FormItem>
            <FormItem label="可分配字符数:" class="formItem">
              <Input
                disabled
                v-model="userInfo.memberAccount.usableCharNum"
                class="formItemIpt"
              />
            </FormItem>
            <FormItem
              label="变更字符数量:"
              prop="changeCharNum"
              class="formItem"
              style="width: 100%"
            >
              <InputNumber
                class="formItemIpt"
                :max="userInfo.memberAccount.usableCharNum"
                :step="10000"
                :min="0"
                v-model="formData.changeCharNum"
              />
              <div class="numSelGrp">
                <Button
                  style="margin-right: 10px"
                  v-for="item in btns"
                  :key="item.num"
                  :disabled="userInfo.memberAccount.usableCharNum < item.num"
                  @click="formData.changeCharNum = item.num"
                >
                  {{ item.label }}
                </Button>
              </div>
            </FormItem>
          </Form>
          <div class="changeDetail">
            <div class="title">变更详情</div>
            <Row class="detailRow">
              <Col span="4" class="detailTitle">子账户信息</Col>
              <Col span="6">变更前 : {{ formData.usableCharNum }}</Col>
              <Col span="6">变更后 : {{ formData.changeCharNum }}</Col>
              <Col span="6">
                净值差 :
                <span
                  style="color: #ff1919"
                  v-if="formData.changeCharNum > formData.usableCharNum"
                  >+{{ formData.changeCharNum - formData.usableCharNum }}</span
                >
                <span
                  style="color: #43c93e"
                  v-if="formData.changeCharNum < formData.usableCharNum"
                  >-{{ formData.usableCharNum - formData.changeCharNum }}</span
                >
                <span
                  style="color: #a7a7a7"
                  v-if="formData.changeCharNum == formData.usableCharNum"
                  >无变更</span
                >
              </Col>
            </Row>
            <Row class="detailRow">
              <Col span="4" class="detailTitle">主账户信息</Col>
              <Col span="6"
                >变更前 : {{ userInfo.memberAccount.usableCharNum }}</Col
              >
              <Col span="6"
                >变更后 :
                <span
                  v-if="
                    userInfo.memberAccount.usableCharNum +
                      formData.usableCharNum -
                      formData.changeCharNum >
                    -1
                  "
                >
                  {{
                    userInfo.memberAccount.usableCharNum +
                    formData.usableCharNum -
                    formData.changeCharNum
                  }}
                </span>
                <span v-else>余额不足</span></Col
              >
              <Col span="6">
                净值差 :
                <span
                  style="color: #43c93e"
                  v-if="formData.changeCharNum > formData.usableCharNum"
                >
                  -{{ formData.changeCharNum - formData.usableCharNum }}
                </span>
                <span
                  style="color: #ff1919"
                  v-if="formData.changeCharNum < formData.usableCharNum"
                >
                  +{{ formData.usableCharNum - formData.changeCharNum }}
                </span>
                <span
                  style="color: #a7a7a7"
                  v-if="formData.changeCharNum == formData.usableCharNum"
                  >无变更</span
                >
              </Col>
            </Row>
          </div>
        </TabPane>
        <TabPane label="分配子账户语音" name="image">
          <Form
            class="distributionForm"
            ref="form2"
            style="padding-bottom: 20px !important"
            :rules="rules"
            :label-width="130"
          >
            <FormItem label="子账户账号:" class="formItem">
              <Input disabled v-model="formData.account" class="formItemIpt" />
            </FormItem>
            <FormItem label="子账户昵称:" class="formItem">
              <Input disabled v-model="formData.nickname" class="formItemIpt" />
            </FormItem>
            <FormItem label="子账户当前语音数:" class="formItem">
              <Input
                disabled
                v-model="formData.usableVoiceNum"
                class="formItemIpt"
              />
            </FormItem>
            <FormItem label="可分配语音数:" class="formItem">
              <Input
                disabled
                v-model="userInfo.memberAccount.usableVoiceNum"
                class="formItemIpt"
              />
            </FormItem>
            <FormItem
              label="变更语音条数:"
              prop="changeVoiceNum"
              class="formItem"
            >
              <InputNumber
                class="formItemIpt"
                :max="userInfo.memberAccount.usableVoiceNum"
                :step="1"
                :min="0"
                v-model="formData.changeVoiceNum"
              />
            </FormItem>
          </Form>
          <div class="changeDetail">
            <div class="title">变更详情</div>
            <Row class="detailRow">
              <Col span="4" class="detailTitle">子账户信息</Col>
              <Col span="6">变更前: {{ formData.usableVoiceNum }}</Col>
              <Col span="6">变更后: {{ formData.changeVoiceNum }}</Col>
              <Col span="6"
                >净值差:
                <span
                  style="color: #ff1919"
                  v-if="formData.changeVoiceNum > formData.usableVoiceNum"
                  >+{{
                    formData.changeVoiceNum - formData.usableVoiceNum
                  }}</span
                >
                <span
                  style="color: #43c93e"
                  v-if="formData.changeVoiceNum < formData.usableVoiceNum"
                  >-{{
                    formData.usableVoiceNum - formData.changeVoiceNum
                  }}</span
                >
                <span
                  style="color: #a7a7a7"
                  v-if="formData.changeVoiceNum == formData.usableVoiceNum"
                  >无变更</span
                ></Col
              >
            </Row>
            <Row class="detailRow">
              <Col span="4" class="detailTitle">主账户信息</Col>
              <Col span="6"
                >变更前: {{ userInfo.memberAccount.usableVoiceNum }}</Col
              >
              <Col span="6"
                >变更后:
                <span
                  v-if="
                    userInfo.memberAccount.usableVoiceNum +
                      formData.usableVoiceNum -
                      formData.changeVoiceNum >
                    -1
                  "
                >
                  {{
                    userInfo.memberAccount.usableVoiceNum +
                    formData.usableVoiceNum -
                    formData.changeVoiceNum
                  }}
                </span>
                <span v-else>余额不足</span></Col
              >
              <Col span="6"
                >净值差:
                <span
                  style="color: #43c93e"
                  v-if="formData.changeVoiceNum > formData.usableVoiceNum"
                >
                  -{{ formData.changeVoiceNum - formData.usableVoiceNum }}
                </span>
                <span
                  style="color: #ff1919"
                  v-if="formData.changeVoiceNum < formData.usableVoiceNum"
                >
                  +{{ formData.usableVoiceNum - formData.changeVoiceNum }}
                </span>
                <span
                  style="color: #a7a7a7"
                  v-if="formData.changeVoiceNum == formData.usableVoiceNum"
                  >无变更</span
                ></Col
              >
            </Row>
          </div>
        </TabPane>
        <TabPane label="分配子账户图片" name="voive">
          <Form
            class="distributionForm"
            ref="form3"
            style="padding-bottom: 20px !important"
            :rules="rules"
            :label-width="130"
          >
            <FormItem label="子账户账号:" class="formItem">
              <Input disabled v-model="formData.account" class="formItemIpt" />
            </FormItem>
            <FormItem label="子账户昵称:" class="formItem">
              <Input disabled v-model="formData.nickname" class="formItemIpt" />
            </FormItem>
            <FormItem label="子账户当前图片数:" class="formItem">
              <Input
                disabled
                v-model="formData.usableImageNum"
                class="formItemIpt"
              />
            </FormItem>
            <FormItem label="可分配图片数:" class="formItem">
              <Input
                disabled
                v-model="userInfo.memberAccount.usableImageNum"
                class="formItemIpt"
              />
            </FormItem>
            <FormItem
              label="变更图片张数:"
              prop="changeImageNum"
              class="formItem"
            >
              <InputNumber
                class="formItemIpt"
                :max="userInfo.memberAccount.usableImageNum"
                :step="1"
                :min="0"
                v-model="formData.changeImageNum"
              />
            </FormItem>
          </Form>
          <div class="changeDetail">
            <div class="title">变更详情</div>
            <Row class="detailRow">
              <Col span="4" class="detailTitle">子账户信息</Col>
              <Col span="6">变更前: {{ formData.usableImageNum }}</Col>
              <Col span="6">变更后: {{ formData.changeImageNum }}</Col>
              <Col span="6"
                >净值差:
                <span
                  style="color: #ff1919"
                  v-if="formData.changeImageNum > formData.usableImageNum"
                  >+{{
                    formData.changeImageNum - formData.usableImageNum
                  }}</span
                >
                <span
                  style="color: #43c93e"
                  v-if="formData.changeImageNum < formData.usableImageNum"
                  >-{{
                    formData.usableImageNum - formData.changeImageNum
                  }}</span
                >
                <span
                  style="color: #a7a7a7"
                  v-if="formData.changeImageNum == formData.usableImageNum"
                  >无变更</span
                ></Col
              >
            </Row>
            <Row class="detailRow">
              <Col span="4" class="detailTitle">主账户信息</Col>
              <Col span="6"
                >变更前: {{ userInfo.memberAccount.usableImageNum }}</Col
              >
              <Col span="6"
                >变更后:
                <span
                  v-if="
                    userInfo.memberAccount.usableImageNum +
                      formData.usableImageNum -
                      formData.changeImageNum >
                    -1
                  "
                >
                  {{
                    userInfo.memberAccount.usableImageNum +
                    formData.usableImageNum -
                    formData.changeImageNum
                  }}
                </span>
                <span v-else>余额不足</span></Col
              >
              <Col span="6"
                >净值差:
                <span
                  style="color: #43c93e"
                  v-if="formData.changeImageNum > formData.usableImageNum"
                >
                  -{{ formData.changeImageNum - formData.usableImageNum }}
                </span>
                <span
                  style="color: #ff1919"
                  v-if="formData.changeImageNum < formData.usableImageNum"
                >
                  +{{ formData.usableImageNum - formData.changeImageNum }}
                </span>
                <span
                  style="color: #a7a7a7"
                  v-if="formData.changeImageNum == formData.usableImageNum"
                  >无变更</span
                ></Col
              >
            </Row>
          </div>
        </TabPane>
      </Tabs>
    </div>
    <template #footer>
      <div style="text-align: center">
        <Button
          class="saveBtn"
          long
          :loading="loading"
          @click="submit"
          :disabled="disabledBtn"
          >确认分配</Button
        >
      </div>
    </template>
  </Modal>
</template>

<script>
import userService from "@/service/user/index";
export default {
  name: "ChildUserDistributionDrawer",
  emits: ["on-success"],
  data() {
    return {
      show: false,
      curTab: "char",
      userInfo: {
        memberAccount: {},
      },
      btns: [
        { num: 10000, label: "1万字" },
        { num: 50000, label: "5万字" },
        { num: 100000, label: "10万字" },
        { num: 200000, label: "20万字" },
        { num: 500000, label: "50万字" },
      ],
      rules: {},
      formData: {
        userId: null,
        account: "",
        nickname: "",
        usableCharNum: 0,
        changeCharNum: 0,
        usableVoiceNum: 0,
        changeVoiceNum: 0,
        usableImageNum: 0,
        changeImageNum: 0,
      },
      loading: false,
    };
  },
  mounted() {},
  computed: {
    disabledBtn() {
      return (
        (this.userInfo.memberAccount.usableCharNum +
          this.formData.usableCharNum -
          this.formData.changeCharNum <
          0 &&
          this.userInfo.memberAccount.usableVoiceNum +
            this.formData.usableVoiceNum -
            this.formData.changeVoiceNum <
            0) ||
        (this.formData.changeCharNum == this.formData.usableCharNum &&
          this.formData.changeVoiceNum == this.formData.usableVoiceNum)
      );
    },
  },
  methods: {
    async open(userId) {
      this.show = true;
      this.userInfo = await userService.get();
      this.initUpdateForm(userId);
    },
    async initUpdateForm(userId) {
      let userInfo = await userService.getChildInfo(userId);
      let usableCharNum = userInfo.memberAccount.usableCharNum;
      usableCharNum = usableCharNum ? usableCharNum : 0;
      let usableVoiceNum = userInfo.memberAccount.usableVoiceNum;
      usableVoiceNum = usableVoiceNum ? usableVoiceNum : 0;
      let usableImageNum = userInfo.memberAccount.usableImageNum;
      usableImageNum = usableImageNum ? usableImageNum : 0;
      this.formData = {
        userId: userInfo.userId,
        account: userInfo.account,
        nickname: userInfo.nickname,
        usableCharNum: usableCharNum,
        changeCharNum: usableCharNum,
        usableVoiceNum: usableVoiceNum,
        changeVoiceNum: usableVoiceNum,
        usableImageNum: usableImageNum,
        changeImageNum: usableImageNum,
      };
    },
    submit() {
      let { userId, changeCharNum, changeVoiceNum, changeImageNum } =
        this.formData;
      let params = {
        childUserId: userId,
        changeCharNum: changeCharNum,
        changeVoiceNum: changeVoiceNum,
        changeImageNum: changeImageNum,
      };

      let sub = async () => {
        this.loading = true;
        //ajax
        let result = await userService.distributionAll(params);
        this.loading = false;
        if (!result) {
          return;
        }
        this.success();
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          sub();
        }
      });
    },
    success() {
      this.$Message.success("操作成功");
      this.$emit("on-success");
      this.show = false;
    },
  },
};
</script>