<template>
    <div>
        <banner
            :bgSrc="isMobile ? mBannerBg : bannerBg"
            title1="Instagram出海群发"
            title2=""
            desc="Instagram官方API接口，打通出海营销之路，实现精准意向客户私信。<br/>热门帖子评论，官方接口发布内容实现广告营销全球。"
            @toDownload="toDownload"
        ></banner>
        <div class="part1">
            <div class="mainContent flex">
                <div class="left">
                    <div class="title">Instagram采集</div>
                    <div class="desc">
                        采集数据-矩阵发帖-私信拉群-弹窗提<br />
                        醒-挖掘粉丝-链接文案-人机验证
                    </div>
                    <div class="itemContent">
                        <div
                            class="item flex"
                            v-for="(i, idx) in features"
                            :key="idx"
                        >
                            <div><img :src="support" alt="" /></div>
                            <div class="itemBox">
                                <div class="itemTitle">{{ i.title }}</div>
                                <div class="itemDesc">{{ i.desc }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right"><img :src="productFea1" alt="" /></div>
            </div>
        </div>
        <div class="part2">
            <div class="mainContent">
                <div class="title">Instagram跨境云控强势功能</div>
                <div class="titleDesc">
                    Instagram跨境云控是一款跨平台的社交营销系统，在这里我们可以帮您实现批量导入Instagram账户，<br />
                    一键快捷批量群发消息，筛选海量客户的精准营销，完成您对Instagram平台用户的超高效管理，帮助您快速获取具有价值的用户。
                </div>
                <div class="part2Main">
                    <div>
                        <img :src="feature1" alt="" />
                        <div>
                            <div class="rTitle">自动发帖</div>
                            <div class="rDesc">
                                Instagram伴侣根据策略标记发帖坐标、@
                                和标记指定用户群，让目标用户在合适的时
                                间看到我们发布的内容。
                            </div>
                        </div>
                    </div>
                    <div>
                        <img :src="feature2" alt="" />
                        <div>
                            <div class="rTitle">自动点赞和评论</div>
                            <div class="rDesc">
                                根据策略模仿人工操作自动点赞和评论特定
                                人群发布的内容。
                            </div>
                        </div>
                    </div>
                    <div>
                        <img :src="feature3" alt="" />
                        <div>
                            <div class="rTitle">自动关注和取关</div>
                            <div class="rDesc">
                                根据策略自动关注或取消关注特定的账号群
                                ，同时对设置关注的目标用户的帖子进行点
                                赞、评论等操作。
                            </div>
                        </div>
                    </div>
                    <div>
                        <img :src="feature4" alt="" />
                        <div>
                            <div class="rTitle">定向精准推广</div>
                            <div class="rDesc">
                                根据策略可以把自己或者其它账号发布的内
                                容推荐给特定人群，实现精准推广目的。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="part3">
            <div class="mainContent flex">
                <div class="left"><img :src="productFea3" alt="" /></div>
                <div class="right">
                    <div class="title">Instagram互动</div>
                    <div class="itemContent">
                        <div
                            class="item flex"
                            v-for="(i, idx) in features3"
                            :key="idx"
                        >
                            <div><img :src="support" alt="" /></div>
                            <div class="itemBox">
                                <div class="itemDesc">{{ i.desc }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import banner from "@/components/banner.vue";
import { onMounted } from "vue";
import bannerBg from "@/assets/imgs/price/priceBg.png";
import mBannerBg from "@/assets/imgs/mobile/banner/mBanner3.png";
import support from "@/assets/imgs/home/demand/support.png";
import productFea1 from "@/assets/imgs/ins/fea_1.png";
import productFea3 from "@/assets/imgs/ins/fea_2.png";
import feature1 from "@/assets/imgs/ins/function_1.png";
import feature2 from "@/assets/imgs/ins/function_2.png";
import feature3 from "@/assets/imgs/ins/function_3.png";
import feature4 from "@/assets/imgs/ins/function_4.png";
const isMobile =
    window.screen.width > 768 || window.screen.width == 768 ? false : true;
const toDownload = () => {
    this.$emit("toDownload");
};
const features = [
    {
        title: "日进千粉",
        desc: "模拟真机，弹窗提醒，自动化操作，链接文案直达，官方API",
    },
    {
        title: "精确获客",
        desc: "精准获客/越南欧美等，AI精确地区/国家/地区/性别/兴趣",
    },
    {
        title: "日曝百万",
        desc: "百万日曝光，文案/图片链接随机变化。发帖点赞评论关注。曝光知阵",
    },
];
const features3 = [
    {
        desc: "支持同时登录无数个Instgram账号独立IP环境自动化操作",
    },
    {
        title: "",
        desc: "支持采集粉丝和关注列表",
    },
    {
        title: "",
        desc: "支持采集帖子评论区用户",
    },
    {
        title: "",
        desc: "支持多个账号同时发送私信、加关注、取消关注",
    },
];
onMounted(async () => {});
</script>
<style scoped lang="scss">
.part1 {
    .left {
        width: 35%;
    }
    .right {
        width: 65%;
        > img {
            width: 100%;
            height: auto;
        }
    }
}
.part3 {
    .left {
        width: 65%;
        > img {
            width: 100%;
            height: auto;
        }
    }
    .right {
        width: 32%;
        margin-left: 3%;
    }
    .itemDesc {
        color: #000 !important;
    }
}
.part1,
.part3 {
    background-color: #fff;
    .title {
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
    }
    .desc {
        font-size: 0.875rem;
        margin-bottom: 1.5rem;
    }
    .item {
        margin-bottom: 1.5rem;
        img {
            margin-right: 0.5rem;
        }
        .itemTitle {
            font-size: 0.875rem;
            font-weight: bold;
            margin-bottom: 0.25rem;
        }
        .itemDesc {
            color: #676767;
            font-size: 0.875rem;
        }
    }
}
.part2 {
    background-color: #f6f8ff;
    .title {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
        text-align: center;
    }
    .titleDesc {
        font-size: 1rem;
        margin-bottom: 3rem;
        text-align: center;
    }
    .part2Main {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        // grid-template-rows: repeat(2, 50%);
        row-gap: 4rem;
        column-gap: 4rem;
        > div {
            display: flex;
            border-radius: 1rem;
            > img {
                height: fit-content;
                width: 25%;
            }
            > div {
                flex-grow: 1;
                height: 100%;
                background-color: #f2f2f2;
                padding: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .rTitle {
                    font-size: 1.125rem;
                    font-weight: bold;
                    margin-bottom: 1rem;
                }
                .rDesc {
                    font-size: 0.875rem;
                }
            }
        }
    }
}

.mainContent {
    padding: 5.625rem 0 8.125rem;
}
.moduleTitle {
    margin-bottom: 4.5rem;
    font-size: 2rem;
}
@media (max-width: 768px) {
    .mainContent {
        padding: 1rem !important;
    }
    .part1,
    .part3 {
        > .mainContent {
            display: block !important;
            .left,.right {
                width: 100% !important;
                height: auto !important;
                .title,.desc {
                    text-align: center;
                }
            }
        }
    }
    .part2 {
        .part2Main {
            display: grid;
            grid-template-columns: repeat(1, 100%);
            row-gap: 4rem;
            column-gap: 0rem;
            > div {
                display: flex;
                border-radius: 1rem;
                > img {
                    height: fit-content;
                    width: 30%;
                }
                > div {
                    flex-grow: 1;
                    height: 100%;
                    background-color: #f2f2f2;
                    padding: 0.25rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .rTitle {
                        font-size: 0.875rem;
                        font-weight: bold;
                        margin-bottom: 0.25rem;
                    }
                    .rDesc {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}
</style>