<style lang="scss" scoped>
.base-warp {
  .page-warp {
    text-align: right;
    position: relative;
  }
}
.icon {
  width: 15px;
  height: 15px;
}
</style>
<template>
  <div class="mainPage">
    <PageHeader title="充值订单" />
    <div class="base-warp">
      <div class="table-warp">
        <Table
          :loading="loading"
          size="small"
          :height="556"
          stripe
          :columns="columns"
          :data="list"
        >
          <template #num="{ row }">
            <span v-if="row.translationBillingMode == 'time'"
              >{{
                new Number((row.num / 60 / 24 / 30).toFixed(0))
              }}&nbsp;月</span
            >
            <span v-else>{{ row.num }}&nbsp;字</span>
          </template>
          <template #tranChannelId="{ row }">
            <TranslateChannelLabel :tranChannelId="row.tranChannelId" />
          </template>
          <template #amount="{ row }">
            <span>{{ (row.amount / 100).toFixed(2) }}</span>
          </template>
          <template #payInfo="{ row }">
            <div style="display:flex;align-items: center">
              <img
                v-if="
                  row.payChannel == 'ALI_NATIVE' &&
                  (row.payStatus == 'P' || row.payStatus == 'W')
                "
                class="icon"
                style="display: inline"
                src="@/assets/userCenter/zfb-s.png"
              />
              <img
                v-if="
                  row.payChannel == 'ALI_NATIVE' &&
                  (row.payStatus == 'T' || row.payStatus == 'C')
                "
                class="icon"
                style="display: inline"
                src="@/assets/userCenter/zfb-f.png"
              />
              <img
                v-if="
                  row.payChannel !== 'ALI_NATIVE' &&
                  (row.payStatus == 'P' || row.payStatus == 'W')
                "
                class="icon"
                style="display: inline"
                src="@/assets/userCenter/wx-s.png"
              />
              <img
                v-if="
                  row.payChannel !== 'ALI_NATIVE' &&
                  (row.payStatus == 'T' || row.payStatus == 'C')
                "
                class="icon"
                style="display: inline"
                src="@/assets/userCenter/wx-f.png"
              />
              <span style="padding-left: 5px">
                <span v-if="row.payStatus == 'W'" style="color: #fb8800"
                  >待支付</span
                >
                <span v-if="row.payStatus == 'P'" style="color: #19be6b"
                  >已支付</span
                >
                <span v-if="row.payStatus == 'T'" style="color: #b2b2b2"
                  >超时关闭</span
                >
                <span v-if="row.payStatus == 'C'" style="color: #b2b2b2"
                  >取消支付</span
                >
              </span>
            </div>
          </template>
          <template #option="{ row }">
            <a
              v-if="row.payStatus == 'W'"
              style="padding-right: 5px"
              @click="flush(row.id)"
              >刷新</a
            >
          </template>
        </Table>
      </div>
      <div class="page-warp">
        <Page
          style="margin-top: 25px"
          show-total
          :total="page.total"
          :page-size="page.size"
          v-model="page.num"
          @on-change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import orderService from "@/service/order/memberGroup";
import PageHeader from "../components/common/pageHeader.vue";
export default {
  name: "UserCenterOrder",
  components: { PageHeader },
  data() {
    return {
      loading: false,
      columns: [
        { title: "订单号", key: "no", width: 260 },
        { title: "支付信息", slot: "payInfo" },
        { title: "会员组", key: "memberGroupName" },
        { title: "翻译渠道", slot: "tranChannelId" },
        { title: "生效数量", slot: "num" },
        { title: "订单金额(元)", slot: "amount",width: 120, },

        {
          title: "创建时间",
          key: "createDateTime",
          width: 220,
        },
        { title: "操作", width: 150, slot: "option" },
      ],
      page: {
        size: 10,
        num: 1,
        total: 0,
      },
      list: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loadData();
    },
    async loadData() {
      this.loading = true;
      let params = {
        pageSize: this.page.size,
        pageNum: this.page.num,
      };
      let result = await orderService.page(params);
      this.list = result.rows;
      this.page.total = result.total;
      console.log(result);
      this.loading = false;
    },
    pageChange() {
      this.loadData();
    },
    async flush(orderId) {
      this.loading = true;
      let result = await orderService.flush(orderId);
      if (result) {
        this.loadData();
      }
      this.loading = false;
    },
  },
};
</script>

