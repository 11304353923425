<style lang="scss" scoped>
</style>
<template>
    <Card>
        <template #title><h2>注销登录</h2></template>
        <div style="text-align: center;padding:25px;">
            注销成功，系统将在{{num}}秒后跳转到首页
        </div>
    </Card>
</template>

<script>
    export default {
        name: 'UserCenterExit',
        data() {
            return {
                num : 3
            }
        },
        mounted(){
            this.iid = setInterval(() => {
              if(this.num-- == 0){
                clearInterval(this.iid);
                sessionStorage.removeItem("sk");
                sessionStorage.removeItem("userInfo");
                this.$router.push({name : "Index"});
              }  
            }, 1000);
        }
    }
</script>

