<template>
  <div class="whyChoose">
    <div class="mainContent">
      <div class="moduleTitle">为什么选择我们</div>
      <template v-if="!isMobile">
        <div class="flex justify-between">
          <div class="itemBox mr20 w23_5">
            <div class="itemNum">{{ contents[1].order }}</div>
            <div class="itemTitle mb20">{{ contents[1].title }}</div>
            <div class="itemDesc">
              {{ contents[1].desc1 }}
              {{ contents[1].desc2 }}
            </div>
          </div>
          <div class="itemBox grow item01 flex">
            <div class="relative w-50">
              <img :src="curChooseImg" alt="" class="chooseImg" />
            </div>
            <div class="w-50 item01Content">
              <div class="itemNum">{{ contents[0].order }}</div>
              <div>
                <div class="itemTitle mb20">{{ contents[0].title }}</div>
                <div class="mb20">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="63"
                    height="8"
                    viewBox="0 0 63 8"
                    fill="none"
                  >
                    <circle
                      cx="4"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                    <circle
                      cx="15"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                    <circle
                      cx="26"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                    <circle
                      cx="37"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                    <circle
                      cx="48"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                    <circle
                      cx="59"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div class="itemDesc mb10">
                  {{ contents[0].desc1 }}
                </div>
                <div class="itemDesc">
                  {{ contents[0].desc2 }}
                </div>
              </div>
            </div>
            <img
              :src="leftA"
              alt=""
              class="arrow left"
              @click="clickArrow('left')"
            />
            <img
              :src="rightA"
              alt=""
              class="arrow right"
              @click="clickArrow('right')"
            />
          </div>
          <div class="itemBox ml20 w23_5">
            <div class="itemNum">{{ contents[2].order }}</div>
            <div class="itemTitle mb20">{{ contents[2].title }}</div>
            <div class="itemDesc">
              {{ contents[2].desc1 }}
              {{ contents[2].desc2 }}
            </div>
          </div>
        </div>
      </template>
      <template v-if="isMobile">
        <div>
          <div
            class="itemBox item01 flex"
            v-for="(item, idx) in contents"
            :key="idx"
          >
            <div class="relative" style="width: 40%; text-align: center">
              <img :src="item.imgSrc" alt="" class="chooseImg" />
            </div>
            <div class="item01Content" style="width: 60%">
              <div class="itemNum">{{ item.order }}</div>
              <div>
                <div class="itemTitle">{{ item.title }}</div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="63"
                    height="8"
                    viewBox="0 0 63 8"
                    fill="none"
                  >
                    <circle
                      cx="4"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                    <circle
                      cx="15"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                    <circle
                      cx="26"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                    <circle
                      cx="37"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                    <circle
                      cx="48"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                    <circle
                      cx="59"
                      cy="4"
                      r="3"
                      stroke="#FDB426"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div class="itemDesc">
                  {{ item.desc1 }}
                </div>
                <div class="itemDesc">
                  {{ item.desc2 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import choose from "@/assets/imgs/home/choose/choose.png";
import choose1 from "@/assets/imgs/home/choose/choose1.png";
import choose2 from "@/assets/imgs/home/choose/choose2.png";
import { ref } from "vue";
import leftA from "@/assets/imgs/home/leftA.png";
import rightA from "@/assets/imgs/home/rightA.png";
const isMobile =
  window.screen.width > 768 || window.screen.width == 768 ? false : true;
const contents = ref([
  {
    order: "01",
    title: "全球200＋语种",
    desc1: "支持全球主流社交通讯软件在线实时精准翻译(可多开)支持全球200+语种。",
    desc2:
      "配置多个翻译渠道自由切换自动双向翻译更精准无需复制粘贴畅享极速交流体验。",
    imgSrc: choose,
  },
  {
    order: "02",
    title: "超乎想象的翻译能力",
    desc1: "专业技术服务团队为翻译稳定性和准确性保驾护航",
    desc2: "",
    imgSrc: choose1,
  },
  {
    order: "03",
    title: "安全私密地收发信息",
    desc1:
      "发送内容多重数据加密,不留存任何发送数据。端到端加密让您的个人消息和通话更有安全保障",
    desc2: "",
    imgSrc: choose2,
  },
]);
const curChooseImg = ref(choose);
const clickArrow = (type) => {
  let item0 = contents.value[0],
    item1 = contents.value[1],
    item2 = contents.value[2];
  if (type === "left") {
    contents.value = [item2, item0, item1];
  } else {
    contents.value = [item1, item2, item0];
  }
  curChooseImg.value = contents.value[0].imgSrc;
};
</script>
<style scoped>
.whyChoose {
  background: #fff;
  padding: 5.625rem 0 8.25rem;
}
.moduleTitle {
  margin-bottom: 6.4375rem;
}
.itemBox {
  padding: 0.75rem 1.5rem 3.5rem;
  text-align: left;
  border-radius: 12px;
  background: #f6f6f6;
  position: relative;
}
.w23_5 {
  width: 23.5%;
}
.itemNum {
  color: #e5e5e5;
  font-size: 4.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.itemTitle {
  color: #333030;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.itemDesc {
  color: #666;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
}
.item01 {
  background-image: url("../../assets/imgs/home/choose/chooseBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.item01 .itemTitle,
.item01 .itemDesc {
  color: #fff;
}
.item01 .itemTitle {
  margin-top: -2.5rem;
}
.chooseImg {
  position: absolute;
  top: -3rem;
  height: 18.75rem;
}
.item01 .itemNum {
  color: #5d9aff;
}
.item01Content {
  margin-left: -2rem;
}
.arrow {
  position: absolute;
  cursor: pointer;
}
.left {
  top: calc(50% - 16px);
  left: -16px;
}
.right {
  top: calc(50% - 16px);
  right: -16px;
}
@media (max-width: 768px) {
  .whyChoose {
    padding: 2.5rem 0 1.5rem;
  }
  .moduleTitle {
    margin-bottom: 2.2rem;
  }
  .mainContent {
    padding: 0 0.75rem;
  }

  .itemBox {
    padding: 1.25rem 0.75rem;
    margin-bottom: 1.5rem;
  }

  .chooseImg {
    position: static;
    height: auto;
    width: 100%;
  }
  .item01Content {
    margin-left: 2rem;
    margin-top: -2rem;
  }
  .itemNum {
    color: #fff;
    font-family: Inter;
    font-size: 4.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .itemTitle {
    color: #fff;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .itemDesc {
    color: #fff;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
  }
}
</style>