<template>
  <Modal
    v-model="show"
    :loading="loading"
    @on-ok="submit"
    :title="formData.userId ? '修改子账户信息' : '新增子账户'"
  >
    <Form
      v-if="userInfo.createChildUser"
      style="padding: 0px"
      ref="form"
      :model="formData"
      :rules="rules"
      :label-width="100"
    >
      <FormItem label="子账户昵称" prop="nickname">
        <Input
          v-model="formData.nickname"
          placeholder="请输入子账户昵称"
        ></Input>
      </FormItem>
      <FormItem label="子账户账号" prop="account">
        <Input
          :disabled="formData.userId"
          v-model="formData.account"
          placeholder="请输入子账户账号"
        ></Input>
      </FormItem>
      <FormItem label="子账户密码" prop="password" v-if="!formData.userId">
        <Input
          v-model="formData.password"
          placeholder="请输入子账户密码"
          type="password"
          password
        ></Input>
      </FormItem>
      <FormItem label="是否启用">
        <Switch v-model="formData.enabled" :true-value="1" :false-value="0" />
      </FormItem>
    </Form>
    <div v-else class="tip-warp">
      <h3>很抱歉,您还没有开通创建子账户的权限</h3>
      <div class="tip">可选择含有指定《子账户》功能的会员组进行开通</div>
      <div style="padding: 15px 0; text-align: right">
        <Button @click="show = false" style="margin-right: 15px">关闭</Button>
        <Button type="primary" @click="toUserCenterRecharge">去开通</Button>
      </div>
    </div>
    <template #footer>
      <div style="text-align: center">
        <Button class="saveBtn" long :loading="loading" @click="submit">{{
          formData.userId ? "修改子账户" : "新增子账户"
        }}</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import loginService from "@/service/login/index";
import userService from "@/service/user/index";
export default {
  name: "ChildUserModal",
  emits: ["on-success"],
  data() {
    return {
      show: false,
      rules: {
        account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            type: "string",
            min: 2,
            message: "账号至少输入2位",
            trigger: "blur",
          },
          {
            type: "string",
            max: 32,
            message: "账号最多输入32位",
            trigger: "blur",
          },
          {
            validator: async (rule, value, callback) => {
              var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
              let result = reg.test(value);
              if (!result) {
                callback(
                  new Error("请输入正确的用户名. 只能包含数字,字母,中文")
                );
                return;
              }
              if (!this.formData.userId) {
                result = await loginService.checkUsername(value);
                if (!result) {
                  callback(new Error("用户名已被占用"));
                  return;
                }
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        nickname: [
          { required: false, message: "请输入昵称", trigger: "blur" },
          {
            type: "string",
            min: 2,
            message: "昵称至少输入2位",
            trigger: "blur",
          },
          {
            type: "string",
            max: 32,
            message: "昵称不能超过32个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "密码至少输入6位",
            trigger: "blur",
          },
          {
            type: "string",
            max: 32,
            message: "密码最多输入32位",
            trigger: "blur",
          },
        ],
      },
      formData: {
        nickname: "",
        account: "",
        password: "",
        enabled: 1,
        autoDistribution: 1,
      },
      loading: false,
      userInfo: {},
    };
  },
  async mounted() {
    this.userInfo = await userService.get();
  },
  methods: {
    async open(userId) {
      this.show = true;
      this.initForm(userId);
    },
    initForm(userId) {
      if (!userId) {
        this.initInsertForm();
      } else {
        this.initUpdateForm(userId);
      }
    },
    initInsertForm() {
      this.formData = {
        nickname: "",
        account: "",
        password: "",
        enabled: 1,
      };
    },
    async initUpdateForm(userId) {
      let userInfo = await userService.getChildInfo(userId);
      this.formData = {
        userId: userInfo.userId,
        nickname: userInfo.nickname,
        account: userInfo.account,
        password: userInfo.password,
        enabled: userInfo.enabled,
      };
    },
    submit() {
      let { userId, nickname, account, password, enabled } = this.formData;
      let params = { userId, nickname, account, password, enabled };
      if (userId) {
        delete params.password;
        delete params.account;
      }
      let sub = async () => {
        this.loading = true;
        //ajax
        let result = await userService.saveChildUser(params);
        this.loading = false;
        if (!result) {
          return;
        }
        this.success();
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          sub();
        }
      });
    },
    success() {
      this.$Message.success("操作成功");
      this.$emit("on-success");
      this.show = false;
    },
    toUserCenterRecharge() {
      this.$router.push({ name: "UserCenterRecharge" });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>