<style lang="scss" scoped>
.ivu-drawer-body {
  padding: 0 !important;
  margin: 0;
}
.tabs {
  display: flex;
  .tab {
    width: 50%;
    text-align: center;
    margin-bottom: 25px;
    background: #dededd;
    &:first-child {
      border-right: 0;
    }
    padding: 10px 0;
    border: 1px solid #606060;
    &.act {
      border-color: #2d8cf0;
      background: #2d8cf0;
      color: #fff;
    }
    cursor: pointer;
  }
}
span.tip {
  color: #838383;
}
.tip {
  text-align: center;
}

.login {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("../assets/imgs/loginBg.png");
  position: relative;
  background-size: cover;
  background-attachment: fixed;
}
.mainContent {
  padding: 12.9375rem 0;
}
.loginLogo {
  position: absolute;
  top: 16px;
  left: 26px;
}
.copyright {
  position: fixed;
  text-align: center;
  color: #323232;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  right: 0;
  left: 0;
  bottom: 20px;
}
.title1,
.title2 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
  text-align: left;
}
.title2 {
  margin-top: 0.5rem;
}
.desc {
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: left;
  color: #042967;
  margin: 1.625rem 0 2.25rem;
}
.formBox {
  position: absolute;
}
.loginForm {
  position: absolute;
  width: 26rem;
  top: 12.9375rem;
  right: 15.625rem;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 14.6px 2px rgba(210, 210, 210, 0.25);
  backdrop-filter: blur(20px);
  padding: 2.5rem;
}
.titleRow {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 10px;
}
.title {
  color: #242424;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.toRes {
  color: #333;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.notify {
  color: #3581ff;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.inputGrp {
  margin-top: 20px;
}
.inputGrp > div:first-child {
  margin-bottom: 0.5rem;
  color: #323232;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.input {
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(229, 229, 229, 0.25);
}
.loginBtn {
  border-radius: 2.375rem;
  background: #3581ff;
  box-shadow: 0px 4px 12px 0px rgba(23, 110, 255, 0.25);
  text-align: center;
  color: #fff;
  margin-top: 1rem;
  width: 100%;
}
.agreement {
  margin-top: 1.87rem;
}

.resForm {
  position: absolute;
  width: 29rem;
  top: 8.75rem;
  right: 15.625rem;
}

.resForm > div {
  margin-bottom: 4rem;
  background: #fff;
  padding: 2.5rem;
  border-radius: 24px;
  box-shadow: 0px 4px 14.6px 2px rgba(210, 210, 210, 0.25);
  backdrop-filter: blur(20px);
}
.tips {
  color: #666;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  bottom: -24px;
  right: 0;
}
.getCode {
  color: #3581ff;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  cursor: pointer;
}
@media (max-width: 768px) {
  .login {
    background-image: none;
    position: relative;
  }
  .mNav {
    padding: 10px 16px;
    align-items: center;
  }
  .logoImg {
    height: 2.625rem;
  }
  .loginForm {
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    top: 12.9375rem;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 4px 14.6px 2px rgba(210, 210, 210, 0.25);
    backdrop-filter: blur(20px);
    padding: 2.5rem 1.5rem;
  }

  .resForm {
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    top: 12.9375rem;
  }
  .resForm > div {
    margin-bottom: 0rem;
    background: #fff;
    padding: 2.5rem 1.5rem;
    border-radius: 24px;
    box-shadow: 0px 4px 14.6px 2px rgba(210, 210, 210, 0.25);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
}
</style>
<template>
  <div class="login">
    <div class="mainContent" v-if="!isMobile">
      <div class="cursor-pointer loginLogo">
        <img
          src="../assets/imgs/footer/footerLogo.png"
          alt=""
          style="height: 2.625rem"
          @click="clickLogo"
        />
      </div>
      <div class="copyright">
        <span style="margin-right: 16px">蜀ICP备2023001571号-1</span
        ><span>Copyright © 2012-2023 UCloud</span>
      </div>
      <div class="title1">易翻译</div>
      <div class="title2">电商客服辅助翻译平台</div>
      <div class="desc">专业、快捷、高效的翻译软件</div>
    </div>
    <div v-if="isMobile">
      <div class="mNav">
        <img
          src="@/assets/imgs/footer/footerLogo.png"
          alt=""
          class="logoImg"
          @click="clickLogo"
        />
      </div>
      <banner :bgSrc="mBannerBg" :showDownload="false"></banner>
    </div>

    <div v-if="type == 1" class="loginForm">
      <div class="titleRow">
        <div class="title">登录</div>
        <div class="toRes">
          未有账号，<span
            @click="
              () => {
                type = 2;
              }
            "
            class="notify"
            >去注册</span
          >
        </div>
      </div>
      <Form
        style="padding: 20px"
        ref="loginForm"
        :model="formData"
        :rules="rules"
        label-position="top"
      >
        <FormItem label="邮箱或用户名" prop="account">
          <Input
            v-model="formData.account"
            class="input"
            size="large"
            placeholder="请输入邮箱或用户名（必填）"
          ></Input>
        </FormItem>
        <FormItem label="密码" prop="password">
          <Input
            v-model="formData.password"
            placeholder="请输入密码（必填）"
            type="password"
            class="input"
            size="large"
            password
          ></Input>
        </FormItem>
        <FormItem>
          <Button
            @click="login"
            :loading="loading"
            class="loginBtn"
            long
            type="primary"
            size="large"
            >登录</Button
          >
        </FormItem>
        <FormItem>
          <div class="toRes text-center cursor-pointer" @click="openModal()">
            忘记密码?
          </div>
        </FormItem>

        <div class="toRes agreement">
          登录即代表您同意
          <span class="notify" @click="showa">服务条款</span> 和
          <span class="notify" @click="showb">用户协议</span>
        </div>
      </Form>
    </div>
    <div v-if="type == 2" class="resForm">
      <div>
        <div class="titleRow">
          <div class="title">注册</div>
          <div class="toRes">
            已有账号，<span
              @click="
                () => {
                  type = 1;
                }
              "
              class="notify"
              >直接登录</span
            >
          </div>
        </div>
        <Form
          style="padding: 20px"
          ref="registerForm"
          :model="formData2"
          :rules="rules2"
          label-position="top"
        >
          <FormItem label="昵称" prop="nickname">
            <Input
              v-model="formData2.nickname"
              placeholder="请输入昵称"
              class="input"
              size="large"
            ></Input>
          </FormItem>
          <FormItem label="用户名" prop="username">
            <Input
              v-model="formData2.username"
              placeholder="请输入用户名（必填，必须字母开头，不出现特殊字符）"
              class="input"
              size="large"
            ></Input>
          </FormItem>
          <FormItem label="邮箱" prop="account">
            <Input
              v-model="formData2.account"
              placeholder="请输入您的注册邮箱（必填）"
              class="input"
              size="large"
            ></Input>
            <span class="tips">用户名和邮箱都可以用于登录</span>
          </FormItem>
          <FormItem label="验证码" prop="vcode">
            <Input
              style="width: 60%"
              v-model="formData2.vcode"
              placeholder="请输入验证码（必填）"
              class="input"
              size="large"
            ></Input>
            <Button
              style="width: 35%; float: right; height: 40px"
              @click="sendVCode(formData2.account)"
              :disabled="!formData2.account || sending > 1 || disabledSendVCode"
            >
              {{
                sending > 1 ? "" + sending + "秒后再次发送" : "点击获取验证码"
              }}
            </Button>
          </FormItem>
          <FormItem label="密码" prop="password">
            <Input
              v-model="formData2.password"
              placeholder="请设置密码（必填）"
              type="password"
              class="input"
              size="large"
              password
            ></Input>
          </FormItem>
          <FormItem label="再次输入密码" prop="password2">
            <Input
              v-model="formData2.password2"
              placeholder="请再次输入密码（必填）"
              type="password"
              class="input"
              size="large"
              password
            ></Input>
          </FormItem>

          <FormItem>
            <Button
              @click="register"
              :loading="loading"
              long
              class="loginBtn"
              type="primary"
              size="large"
              >注册</Button
            >
          </FormItem>
        </Form>
      </div>
    </div>

    <Modal v-model="modal.show" title="找回密码" :width="800">
      <Steps :current="modal.current">
        <Step title="验证账号" icon="ios-person"></Step>
        <Step title="设置新密码" icon="ios-lock"></Step>
        <Step title="修改成功" icon="ios-checkmark-circle-outline"></Step>
      </Steps>
      <Form
        style="padding: 20px"
        ref="resetPwdForm"
        :model="modal.formData"
        :rules="modal.rules"
        label-position="top"
        v-if="modal.current < 2"
      >
        <FormItem label="请输入邮箱或用户名" prop="accountOrEmail">
          <Input
            :disabled="modal.current !== 0"
            v-model="modal.formData.accountOrEmail"
            placeholder="请输入您注册的邮箱,用户名"
            class="input"
            size="large"
          ></Input>
        </FormItem>
        <FormItem label="密码" prop="password" v-if="modal.current == 1">
          <Input
            v-model="modal.formData.password"
            placeholder="请设置新的登录密码"
            type="password"
            class="input"
            size="large"
            password
          ></Input>
        </FormItem>
        <FormItem
          label="再次输入密码"
          prop="password2"
          v-if="modal.current == 1"
        >
          <Input
            v-model="modal.formData.password2"
            placeholder="请设置新的登录密码"
            class="input"
            size="large"
            type="password"
            password
          ></Input>
        </FormItem>
        <FormItem label="请输入验证码" prop="vcode" v-if="modal.current == 1">
          <div>
            <Alert
              >已向您的邮箱:
              {{ modal.formData.email }} 发送验证码,请注意查收</Alert
            >
          </div>
          <Input
            style="width: 70%"
            v-model="modal.formData.vcode"
            placeholder="请输入验证码"
            class="input"
            size="large"
          ></Input>
          <Button
            style="width: 28%; float: right; height: 40px"
            @click="sendVCode(modal.formData.email)"
            :disabled="modal.formData.account || sending > 1"
          >
            {{ sending > 1 ? "" + sending + "秒后再次发送" : "点击获取验证码" }}
          </Button>
        </FormItem>
      </Form>
      <div v-else style="text-align: center; padding: 25px">
        <Icon type="ios-checkmark-circle" size="200" color="green" />
        <div>操作成功,请重新登陆</div>
      </div>
      <template #footer>
        <div style="text-align: right">
          <Button
            v-if="modal.current == 1"
            @click="per()"
            :loading="loading"
            type="default"
            size="large"
            style="margin-right: 15px"
            >上一步</Button
          >
          <Button
            v-if="modal.current < 2"
            @click="next()"
            :loading="loading"
            type="primary"
            size="large"
            >下一步</Button
          >
          <Button
            v-if="modal.current == 2"
            @click="modal.show = false"
            :loading="loading"
            type="primary"
            size="large"
            >关闭</Button
          >
        </div>
      </template>
    </Modal>
    <UserOKServiceModal ref="UserOKServiceModalRef" />
    <UserOKPrivateModal ref="UserOKPrivateModalRef" />
    <sideBar v-if="!isMobile" />
  </div>
</template>

<script>
import UserOKServiceModal from "../components/UserOKServiceModal.vue";
import UserOKPrivateModal from "../components/UserOKPrivateModal.vue";
import sideBar from "@/components/sideBar.vue";
import loginService from "@/service/login/index";
import userService from "@/service/user/index";
import mBannerBg from "@/assets/imgs/mobile/mLoginBg.png";
import banner from "@/components/banner.vue";
export default {
  name: "login",
  components: { UserOKServiceModal, UserOKPrivateModal, sideBar, banner },
  emits: ["on-login-success"],
  data() {
    const that = this;
    return {
      mBannerBg,
      isMobile:
        window.screen.width > 768 || window.screen.width == 768 ? false : true,
      modal: {
        show: false,
        current: 0,
        formData: {},
        rules: {
          accountOrEmail: [
            {
              required: true,
              message: "请输入用户名或者邮箱",
              trigger: "blur",
            },
            { type: "string", min: 2, message: "至少输入2位", trigger: "blur" },
            {
              validator: async (rule, value, callback) => {
                if (this.modal.current == 0) {
                  this.loading = true;
                  let result = await loginService.findEmail(value);
                  this.loading = false;
                  if (!result) {
                    callback(new Error("该账户未注册"));
                    return;
                  }
                  that.disabledSendVCode = false;
                  that.modal.formData.email = result;
                }
                callback();
              },
              trigger: "blur",
            },
          ],
          password: [
            { required: true, message: "请输入密码", trigger: "blur" },
            {
              type: "string",
              min: 6,
              message: "密码至少输入6位",
              trigger: "blur",
            },
          ],
          password2: [
            { required: true, message: "请在此输入密码", trigger: "blur" },
            {
              validator: (rule, value, callback) => {
                if (this.modal.formData.password !== value) {
                  callback(new Error("两次密码不一致"));
                  return;
                }
                callback();
              },
              trigger: "blur",
            },
          ],
          vcode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        },
      },
      type: 1, // 1：登录 2：注册
      rules: {
        account: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      formData: {
        account: "",
        password: "",
      },
      rules2: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            type: "string",
            min: 2,
            message: "用户名至少输入2位",
            trigger: "blur",
          },
          {
            type: "string",
            max: 32,
            message: "用户名最多输入32位",
            trigger: "blur",
          },
          {
            validator: async (rule, value, callback) => {
              var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
              let result = reg.test(value);
              if (!result) {
                callback(
                  new Error("请输入正确的用户名. 只能包含数字,字母,中文")
                );
                return;
              }
              result = await loginService.checkUsername(value);
              if (!result) {
                callback(new Error("用户名已被占用"));
                return;
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        account: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            type: "string",
            min: 2,
            message: "邮箱至少输入2位",
            trigger: "blur",
          },
          {
            type: "string",
            max: 255,
            message: "邮箱最多输入255位",
            trigger: "blur",
          },
          {
            validator: async (rule, value, callback) => {
              let reg =
                /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
              let result = reg.test(value);
              this.disabledSendVCode = true;

              if (!result) {
                callback(new Error("请输入正确的邮箱号"));
                return;
              }
              result = await loginService.checkEmail(value);
              if (!result) {
                callback(new Error("邮箱号已被占用"));
                return;
              }
              this.disabledSendVCode = false;
              callback();
            },
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "密码至少输入6位",
            trigger: "blur",
          },
          {
            type: "string",
            max: 32,
            message: "密码最多输入32位",
            trigger: "blur",
          },
        ],
        password2: [
          { required: true, message: "请在此输入密码", trigger: "blur" },

          {
            validator: (rule, value, callback) => {
              if (this.formData2.password !== value) {
                callback(new Error("两次密码不一致"));
                return;
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        vcode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      formData2: {
        nickname: "",
        username: "",
        account: "",
        password: "",
        password2: "",
        vcode: "",
        inviteCode: "",
      },
      sending: 0,
      disabledSendVCode: true,
      loading: false,
    };
  },
  mounted() {
    this.type = this.$route.query.type;
  },
  methods: {
    clickLogo() {
      let inviteCode = this.$route.query.inviteCode;
      if (inviteCode) {
        this.$router.push({ path: "/", query: { inviteCode: inviteCode } });
      } else {
        this.$router.push("/");
      }
    },
    open(type) {
      this.disabledSendVCode = true;
      this.type = type;
      this.formData2.inviteCode = this.$route.query.inviteCode;
    },
    async sendVCode(account) {
      if (this.sending > 1) {
        return;
      }
      loginService.sendVCode(account);
      this.$Message.success("验证码发送成功，请前往邮箱查看");

      this.sending = 60;
      this.iid = window.setInterval(() => {
        if (--this.sending < 1) {
          window.clearInterval(this.iid);
        }
      }, 1000);
    },
    register() {
      let { nickname, username, account, password, vcode, inviteCode } =
        this.formData2;
      let params = { nickname, username, account, password, vcode, inviteCode };

      let sub = async () => {
        this.loading = true;
        let result = await loginService.register(params);
        this.loading = false;
        if (!result) {
          return;
        }
        this.type = 1;
        this.$Message.success("注册成功，请登录");
      };
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          sub();
        }
      });
    },
    login() {
      let { account, password } = this.formData;
      let params = { account, password };
      let sub = async () => {
        this.loading = true;
        //ajax
        let result = await loginService.login(params);
        this.loading = false;
        if (!result) {
          return;
        }
        this.$Message.success("登陆成功");
        this.setLoginInfo(result);
        this.$router.push("/");
      };
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          sub();
        }
      });
    },
    async setLoginInfo(loginVO) {
      sessionStorage.setItem("userId", loginVO.userId);
      sessionStorage.setItem("sk", loginVO.sk);
      let userInfo = await userService.get();
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
      this.$emit("on-login-success", userInfo);
    },
    showa() {
      this.$refs.UserOKServiceModalRef.open();
    },
    showb() {
      this.$refs.UserOKPrivateModalRef.open();
    },
    openModal() {
      this.modal.show = true;
      this.modal.current = 0;
      this.modal.formData = {
        account: "",
        email: "",
        password: "",
        password2: "",
        vcode: "",
      };
    },
    per() {
      this.modal.current -= 1;
    },
    next() {
      this.$refs.resetPwdForm.validate(async (valid) => {
        if (valid) {
          if (this.modal.current == 0) {
            this.modal.current += 1;
            this.sendVCode(this.modal.formData.email);
            return;
          }

          if (this.modal.current == 1) {
            this.loading = true;
            let request = {
              email: this.modal.formData.email,
              vcode: this.modal.formData.vcode,
              password: this.modal.formData.password,
            };
            let result = await loginService.resetPwdByVcode(request);
            this.loading = false;
            if (result) {
              this.modal.current += 1;
              return;
            }
          }
        }
      });
    },
  },
};
</script>