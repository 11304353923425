<template>
  <div class="achievement flex mainContent">
    <div v-for="(item, idx) in achiArr" :key="idx" class="achiItem">
      <div>
        <img :src="item.imgSrc" alt="" />
      </div>
      <div class="numGrp flex items-end justify-center">
        <template v-if="!item.split">
          <span class="num">{{ item.num }}</span>
          <span class="unit">{{ item.numSuffix }}</span>
        </template>
        <template v-else>
          <span class="num">{{ item.num }}</span>
          <span class="unit">{{ item.split }}</span>
          <span class="num">{{ item.num2 }}</span>
        </template>
      </div>
      <div class="desc">
        {{ item.desc }}
      </div>
    </div>
  </div>
</template>
<script setup>
import hzqy from "@/assets/imgs/achievement/hzqy.png";
import yhsj from "@/assets/imgs/achievement/yhsj.png";
import hynm from "@/assets/imgs/achievement/hynm.png";
import fwxy from "@/assets/imgs/achievement/fwxy.png";
import fyzqd from "@/assets/imgs/achievement/fyzqd.png";
const achiArr = [
  {
    desc: "合作企业",
    num: "200",
    numSuffix: "+",
    imgSrc: hzqy,
  },
  {
    desc: "用户数据",
    num: "10",
    numSuffix: "万+",
    imgSrc: yhsj,
  },
  {
    desc: "行业和类目",
    num: "180",
    numSuffix: "个",
    imgSrc: hynm,
  },
  {
    desc: "服务响应",
    num: "7",
    split: "x",
    num2: "24",
    imgSrc: fwxy,
  },
  {
    desc: "翻译准确度",
    num: "99.9",
    numSuffix: "%",
    imgSrc: fyzqd,
  },
];
</script>
<style scoped>
.achievement {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0.75rem;
  box-shadow: 0 4px 20px 0 rgba(216, 216, 216, 0.25);
  padding: 1.5rem 0.75rem;
  border: 1px solid #fff;
  backdrop-filter: blur(30px);
  left: 0;
  right: 0;
  top: -4.4rem;
}
.achiItem {
  padding: 0 4.5rem;
  text-align: center;
}
.achiItem img {
  width: 5rem;
  height: 5rem;
}
.numGrp {
  margin: 0.75rem 0 0.5rem;
  color: #000;
}
.num {
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.875rem;
}
.unit {
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  white-space: nowrap;
}
.desc {
  white-space: nowrap;
}
@media (max-width: 768px) {
  .achievement {
    padding: 0.88rem 1.75rem 0;
    border: 1px solid #fff;
    left: 0.75rem;
    right: 0.75rem;
    top: -4.4rem;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .achievement::after {
    content: "";
    width: 4.13794rem;
  }
  .achiItem {
    padding: 0 0.5rem 1.25rem;
    /* padding: 0rem 1.39rem 1.25rem; */
  }
  .achiItem img {
    width: 4.13794rem;
    height: 4.13794rem;
  }
  .numGrp {
    margin: 0.5rem 0 0.125rem;
    color: #000;
  }
  .num {
    color: #333;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .unit {
    color: #333;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .desc {
    color: #676767;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>