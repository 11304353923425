<template>
  <router-view/>
  <NoticeModal ref="NoticeModalRef"/>
</template>

<style lang="scss">
@import "~@/css/common";

html, body, #app, .content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-width: $MINW !important;
}
@media (max-width: 768px) {
  html, body, #app, .content {
    min-width: 0px !important;
  }
  
}
</style>

<script>
import NoticeModal from "@/components/NoticeModal";
import {v4 as uuidv4} from 'uuid';
import config from "@/config";

export default {
  components: {NoticeModal},

  mounted() {
    this.clientId = uuidv4().replace(/-/g, '');
    console.log(this.clientId)
    // this.createSSEConnection()
  },

  beforeUnmount() {
    if (this.eventSource) {
      // this.eventSource.close();
      // 通知后端关闭连接
      // 这里写通知后端关闭的接口以及需要的参数
      // noticeService.closeSse(this.clientId)
    }
  },

  methods: {
    createSSEConnection() {
      let api = config.request + "/notice/openSse/" + this.clientId;
      this.eventSource = new EventSource(api);
      this.eventSource.addEventListener('message', this.handleSSEMessage)
      this.eventSource.addEventListener('error', this.handleSSEError)
    },

    handleSSEMessage(event) {
      // 处理 SSE 事件
      console.log('Received SSE message:', event.data);
      let noticeModalRef = this.$refs.NoticeModalRef
      noticeModalRef.loadData(JSON.parse(event.data))
      // 处理收到的 SSE 数据
    },

    handleSSEError(event) {
      if (event.target.readyState === EventSource.CLOSED) {
        // 连接关闭时执行重连逻辑
        console.log('SSE connection closed. Reconnecting...');
        setTimeout(() => {
          this.createSSEConnection();
        }, 1000); // 延迟3秒后重新建立连接
      }
    },
  }
}
</script>

