<style lang="scss" scoped>
.query-warp {
  width: 100%;
  padding-bottom: 25px;
}
.base-warp {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.page-warp {
  text-align: right;
  position: relative;
}
</style>
<template>
  <div class="mainPage">
    <PageHeader title="翻译日志" />
    <div class="base-warp">
      <Table
        :loading="loading"
        size="small"
        :height="556"
        stripe
        :columns="columns"
        :data="list"
      >
        <template #translationBillingMode="{ row }">
          <span>{{
            row.translationBillingMode == "char" ? "扣除字符数" : "无限量"
          }}</span>
        </template>
        <template #charNum="{ row }">
          <a @click="distribution(row.userId)">
            {{
              row.memberAccount.usableCharNum
                ? row.memberAccount.usableCharNum
                : 0
            }}
          </a>
        </template>
        <template #enabled="{ row }">
          <a
            v-if="row.enabled == 1"
            style="color: #19be6b"
            @click="updateChildUser(row.userId)"
            >启用</a
          >
          <a v-else style="color: #ed4014" @click="updateChildUser(row.userId)"
            >禁用</a
          >
        </template>
        <template #option="{ row }">
          <a style="padding-right: 5px" @click="distribution(row.userId)"
            >分配</a
          >
          <a style="padding-right: 5px" @click="updateChildUser(row.userId)"
            >修改</a
          >
          <a @click="resetPassword(row)">重置</a>
        </template>
      </Table>
    </div>
    <div class="page-warp">
      <Page
        style="margin-top: 25px"
        show-total
        :total="page.total"
        :page-size="page.size"
        v-model="page.num"
        @on-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import translationLogService from "@/service/translation/log";
import PageHeader from "../components/common/pageHeader.vue";
export default {
  name: "UserCenterChangeTranslateLog",
  components: { PageHeader },
  data() {
    return {
      loading: false,
      columns: [
        { title: "翻译时间", key: "createDateTime", width: 180 },
        { title: "字符数", key: "wordCount", width: 100 },
        { title: "扣费模式", slot: "translationBillingMode" },
        { title: "翻译渠道", key: "tranChannelId" },
        { title: "调用IP", key: "ip" },
        { title: "调用客户端", key: "clientType" },
        { title: "原语种", key: "sourceLangCode" },
        { title: "目标语种", key: "targetLangCode" },
      ],
      page: {
        size: 10,
        num: 1,
        total: 0,
      },
      list: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loadData();
    },
    async loadData() {
      this.loading = true;
      let params = {
        pageSize: this.page.size,
        pageNum: this.page.num,
      };
      let result = await translationLogService.page(params);
      console.log(result);
      this.loading = false;
      this.page.total = result.total;
      this.list = result.rows;
    },
    pageChange() {
      this.loadData();
    },
  },
};
</script>

