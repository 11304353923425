import http from "@/utils/http.js"

export default{
    async saleList(){
        let api = "/memberGroup/saleList";
        let result = await http.get(api);
        return result.success && result.data;
    },
    async mappingList(){
        let api = "/memberGroup/mappingList";
        let result = await http.get(api);
        return result.success && result.data;
    },
    async changeChannel(targetChannelId){
        let api = "/memberGroup/changeChannel";
        let result = await http.post(api,{targetChannelId});
        return result.success && result.data;
    },
}