<style lang="scss" scoped>
.roundTag {
  border-radius: 28px !important;
  line-height: 28px !important;
  height: 28px !important;
  font-size: 14px !important;
}
.query-warp {
  width: 100%;
  padding-bottom: 15px;
}
.btn-warp {
  padding-bottom: 10px;
  text-align: right;
}
.base-warp {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.page-warp {
  text-align: right;
  position: relative;
}
.simp-info {
  display: flex;
  padding: 2px 0;
  .l {
    width: 30px;
  }
  .r {
    flex: 1;
    text-align: left;
  }
  .l60 {
    width: 60px;
    font-size: 14px;
    color: #a7a7a7;
    text-align: left;
  }
  .l50 {
    width: 50%;
    padding-right: 15px;
    .r {
      text-align: right;
    }
  }
}
</style>
<template>
  <div class="mainPage">
    <PageHeader title="账户日志" />
    <div class="searchWrap">
      <Row :gutter="16">
        <Col span="7" class="searchCol">
          <span>日志类型 :</span>
          <Select
            v-model="query.logType"
            placeholder="请选择日志类型"
            class="searchIpt"
          >
            <Option v-for="(v, k) in logType" :value="k" :key="k">
              {{ v[0] }}
            </Option>
          </Select>
        </Col>
        <Col span="15" class="searchCol">
          <span>时间范围 :</span>
          <DatePicker
            v-model="query.date"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择日志时间范围"
            class="searchDate"
          />
        </Col>
        <Col
          span="2"
          class="searchCol"
          style="text-align: right; display: block"
        >
          <Button class="saveBtn" @click="search" long>查询</Button>
        </Col>
      </Row>
    </div>
    <div class="base-warp">
      <Table
        :loading="loading"
        size="small"
        :height="556"
        stripe
        :columns="columns"
        :data="list"
      >
        <template #logType="{ row }">
          <div v-if="row.logType == 'VOICE_CONSUME'">
            <span>
              <Tag v-if="row.voiceChangeNum == 0" class="roundTag">无变更</Tag>
              <Tag color="error" v-if="row.voiceChangeNum > 0" class="roundTag"
                >增</Tag
              >
              <Tag
                color="success"
                v-if="row.voiceChangeNum < 0"
                class="roundTag"
                >减</Tag
              >
            </span>
            <span style="padding-left:3px;padding:top:5px;">
              {{ logType[row.logType][0] }}
            </span>
          </div>
          <div v-else>
            <span>
              <Tag
                class="roundTag"
                v-if="
                  row.charChangeNum == 0 &&
                  row.minChangeNum == 0 &&
                  row.voiceChangeNum == 0
                "
                >无变更</Tag
              >
              <Tag
                color="blue"
                class="roundTag"
                v-if="row.charChangeNum > 0 && row.minChangeNum < 0"
                >转</Tag
              >
              <Tag
                color="blue"
                class="roundTag"
                v-if="row.charChangeNum < 0 && row.minChangeNum > 0"
                >转</Tag
              >

              <Tag
                class="roundTag"
                color="error"
                v-if="row.charChangeNum > 0 && row.minChangeNum == 0"
                >增</Tag
              >
              <Tag
                class="roundTag"
                color="success"
                v-if="row.charChangeNum < 0 && row.minChangeNum == 0"
                >减</Tag
              >

              <Tag color="error" v-if="row.voiceChangeNum > 0" class="roundTag"
                >增</Tag
              >
              <Tag
                color="success"
                class="roundTag"
                v-if="row.voiceChangeNum < 0"
                >减</Tag
              >

              <Tag
                color="error"
                class="roundTag"
                v-if="row.minChangeNum > 0 && row.charChangeNum == 0"
                >增</Tag
              >
              <Tag
                color="success"
                class="roundTag"
                v-if="row.minChangeNum < 0 && row.charChangeNum == 0"
                >减</Tag
              >
            </span>
            <span style="padding-left:3px;padding:top:5px;">
              {{ logType[row.logType][0] }}
            </span>
          </div>
        </template>
        <template #tranInfo="{ row }">
          <div
            v-if="
              row.beforeTranChannelId == row.lastTranChannelId &&
              row.beforeTranslationBillingMode == row.lastTranslationBillingMode
            "
          >
            <span style="color: #c5c8ce; font-size: 14px">无变更</span>
          </div>
          <div v-else>
            <div class="simp-info">
              <div class="l">渠道</div>
              <div class="r">
                <span
                  v-if="row.beforeTranChannelId == row.lastTranChannelId"
                  style="color: #c5c8ce; font-size: 14px"
                  >无变更</span
                >
                <div v-else>
                  <translate-channel-label
                    :tranChannelId="row.beforeTranChannelId"
                  />
                  <span style="padding: 0 5px">></span>
                  <translate-channel-label
                    :tranChannelId="row.lastTranChannelId"
                  />
                </div>
              </div>
            </div>
            <div class="simp-info">
              <div class="l">模式</div>
              <div class="r">
                <span
                  v-if="
                    row.beforeTranslationBillingMode ==
                    row.lastTranslationBillingMode
                  "
                  style="color: #c5c8ce; font-size: 14px"
                  >无变更</span
                >
                <div v-else>
                  <span>{{
                    TranslationBillingMode[row.beforeTranslationBillingMode]
                  }}</span>
                  <span style="padding: 0 5px">></span>
                  <span>{{
                    TranslationBillingMode[row.lastTranslationBillingMode]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #changeInfoByChar="{ row }">
          <div v-if="!row.charChangeNum">
            <span style="color: #c5c8ce; font-size: 14px">无变更</span>
          </div>
          <div v-else>
            <div class="simp-info">
              <div class="l">前</div>
              <div class="r">{{ row.beforeCharNum }}</div>
            </div>
            <div class="simp-info">
              <div class="l">后</div>
              <div class="r">{{ row.lastCharNum }}</div>
            </div>
            <div class="simp-info">
              <div class="l">差</div>
              <div class="r">
                <span
                  :style="{
                    color: row.charChangeNum > 0 ? '#d90101' : '#43C93E',
                  }"
                >
                  <span
                    >{{ row.charChangeNum > 0 ? "+" : "-"
                    }}{{ Math.abs(row.charChangeNum) }}</span
                  >
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #changeInfoByVoice="{ row }">
          <div v-if="!row.voiceChangeNum">
            <span style="color: #c5c8ce; font-size: 14px">无变更</span>
          </div>
          <div v-else>
            <div class="simp-info">
              <div class="l">前</div>
              <div class="r">{{ row.beforeVoiceNum }}</div>
            </div>
            <div class="simp-info">
              <div class="l">后</div>
              <div class="r">{{ row.lastVoiceNum }}</div>
            </div>
            <div class="simp-info">
              <div class="l">差</div>
              <div class="r">
                <span
                  :style="{
                    color: row.voiceChangeNum > 0 ? '#d90101' : '#43C93E',
                  }"
                >
                  <span
                    >{{ row.voiceChangeNum > 0 ? "+" : "-"
                    }}{{ Math.abs(row.voiceChangeNum) }}</span
                  >
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #changeInfoByImage="{ row }">
          <div v-if="!row.imageChangeNum">
            <span style="color: #c5c8ce; font-size: 14px">无变更</span>
          </div>
          <div v-else>
            <div class="simp-info">
              <div class="l">前</div>
              <div class="r">{{ row.beforeImageNum }}</div>
            </div>
            <div class="simp-info">
              <div class="l">后</div>
              <div class="r">{{ row.lastImageNum }}</div>
            </div>
            <div class="simp-info">
              <div class="l">差</div>
              <div class="r">
                <span
                  :style="{
                    color: row.imageChangeNum > 0 ? '#d90101' : '#43C93E',
                  }"
                >
                  <span
                    >{{ row.imageChangeNum > 0 ? "+" : "-"
                    }}{{ Math.abs(row.imageChangeNum) }}</span
                  >
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #changeInfoByTime="{ row }">
          <div v-if="!row.minChangeNum">
            <span style="color: #c5c8ce; font-size: 14px">无变更</span>
          </div>
          <div v-else>
            <div class="simp-info">
              <div class="l">前</div>
              <div class="r">
                {{ row.beforeExpireDateTime.substring(0, 16) }}
              </div>
            </div>
            <div class="simp-info">
              <div class="l">后</div>
              <div class="r">{{ row.lastExpireDateTime.substring(0, 16) }}</div>
            </div>
            <div class="simp-info">
              <div class="l">差</div>
              <div class="r">
                <span
                  :style="{
                    color: row.minChangeNum > 0 ? '#d90101' : '#43C93E',
                  }"
                >
                  <span
                    >{{ row.minChangeNum > 0 ? "+" : "-"
                    }}{{ Math.abs(row.minChangeNum) }}</span
                  >
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #slotDetail="{ row }">
          <div v-if="row.rechargeOrderByMemberGroupNo">
            <div class="simp-info">
              <div class="l50">
                <div class="simp-info">
                  <div class="l60">订单号</div>
                  <div class="r">{{ row.rechargeOrderByMemberGroupNo }}</div>
                </div>
              </div>
              <div class="r">
                <div class="simp-info">
                  <div class="l60" style="width: 60px">会员组</div>
                  <div class="r" style="text-align: right">
                    {{ row.rechargeMemberGroupName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="simp-info">
              <div class="l50">
                <div class="simp-info">
                  <div class="l60">翻译渠道</div>
                  <div class="r">
                    <translate-channel-label
                      :tranChannelId="row.rechargeTranChannelId"
                    />
                  </div>
                </div>
              </div>
              <div class="r">
                <div class="simp-info">
                  <div class="l60" style="width: 60px">计费模式</div>
                  <div class="r" style="text-align: right">
                    {{
                      TranslationBillingMode[row.rechargeTranslationBillingMode]
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="row.consumeTranslationConfigId">
            <div class="simp-info" style="width: 100%">
              <div class="l50">
                <div class="simp-info">
                  <div class="l60">消费渠道</div>
                  <div class="r">
                    <translate-channel-label
                      :tranChannelId="row.consumeTranChannelId"
                    />
                  </div>
                </div>
              </div>
              <!--<div class="r">
                                <div class="simp-info">
                                    <div class="l60" style="width:60px">消费API</div>
                                    <div class="r">{{row.consumeTranslationConfigName}}</div>
                                </div>
                            </div>  -->
            </div>
            <div class="simp-info" style="width: 100%">
              <div class="l50">
                <div class="simp-info">
                  <div class="l60">消费IP</div>
                  <div class="r">{{ row.consumerIp }}</div>
                </div>
              </div>
              <!--<div class="r">
                                <div class="simp-info">
                                    <div class="l60" style="width:60px">消费客户端</div>
                                    <div class="r">{{row.consumerClientType}}</div>
                                </div>
                            </div>  -->
            </div>
          </div>
          <div v-if="row.logType == 'VOICE_CONSUME'">
            <div class="simp-info" style="width: 100%">
              <div class="l50">
                <div class="simp-info">
                  <div class="l60">消费IP</div>
                  <div class="r">{{ row.consumerIp }}</div>
                </div>
              </div>
              <!--<div class="r">
                                <div class="simp-info">
                                    <div class="l60" style="width:60px">消费客户端</div>
                                    <div class="r">{{row.consumerClientType}}</div>
                                </div>
                            </div>  -->
            </div>
          </div>
          <div v-if="row.managerId">
            <div class="simp-info">
              <div class="l50">
                <div class="simp-info">
                  <div class="l60">操作管理员</div>
                  <div class="r">{{ row.managerName }}</div>
                </div>
              </div>
              <div class="r">
                <div class="simp-info">
                  <div class="l60" style="width: 60px"></div>
                  <div class="r" style="text-align: right"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="row.logType == 'RECOVERY' || row.logType == 'DISTRIBUTION'"
          >
            <div class="simp-info">
              <div class="l50">
                <div class="simp-info">
                  <div class="l60">主账号</div>
                  <div class="r">{{ row.parentUserName }}</div>
                </div>
              </div>
              <div class="r">
                <div class="simp-info">
                  <div class="l60" style="width: 60px">子账号</div>
                  <div class="r" style="text-align: right">
                    {{ row.childUserName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="simp-info">
              <div class="l50">
                <div class="simp-info">
                  <div class="l60">数量</div>
                  <div class="r">{{ Math.abs(row.charChangeNum) }}</div>
                </div>
              </div>
              <div class="r">
                <div class="simp-info">
                  <div class="l60" style="width: 60px">时间</div>
                  <div class="r" style="text-align: right">
                    {{
                      $dayjs(row.createDateTime).format("YYYY-MM-DD HH:mm:ss")
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #createDateTime="{ row }">
          <span>{{
            $dayjs(row.createDateTime).format("YYYY-MM-DD HH:mm:ss")
          }}</span>
        </template>
        <!-- <template #option="{ row }">
          <a @click="detail(row)">查看详情</a>
        </template> -->
      </Table>
    </div>
    <div class="page-warp">
      <Page
        style="margin-top: 25px"
        show-total
        :total="page.total"
        :page-size="page.size"
        v-model="page.num"
        @on-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import memberAccountLogService from "@/service/member/accountLog";
import TranslateChannelLabel from "../components/TranslateChannelLabel.vue";
import PageHeader from "../components/common/pageHeader.vue";
export default {
  components: { TranslateChannelLabel, PageHeader },
  name: "UserCenterMemberAccountLog",
  data() {
    const begin = new Date();
    begin.setTime(begin.getTime() - 3600 * 1000 * 24 * 10);
    return {
      loading: false,
      TranslationBillingMode: {
        char: "字符",
        time: "时间",
      },
      query: {
        loginIp: "",
        clientType: "",
        date: [begin, new Date()],
      },
      columns: [
        { title: "日志类型", slot: "logType", minWidth: 180, fixed: 'left' },
        { title: "渠道/模式", slot: "tranInfo", width: 135 },
        { title: "账户字符 (个)", slot: "changeInfoByChar", width: 140 },
        { title: "账户时间 (分钟)", slot: "changeInfoByTime", width: 140 },
        { title: "账户语音 (次数)", slot: "changeInfoByVoice", width: 135 },
        { title: "账户图片 (张数)", slot: "changeInfoByImage", width: 135 },
        { title: "变更详情", slot: "slotDetail", width: 450 },
        {
          title: "变更时间",
          key: "createDateTime",
          slot: "createDateTime",
          width: 180,
          fixed: 'right'
        },
      ],
      logType: {
        /** 会员组订单充值 */
        RECHARGE_ORDER_BY_MEMBER_GROUP: ["会员组订单充值"],
        /** 系统换算 */
        SYS_MATRIX: ["系统换算"],
        /** 系统调整 */
        SYS_ADJUST: ["系统调整"],

        /** 回收 */
        RECOVERY: ["回收"],
        /** 分配 */
        DISTRIBUTION: ["分配"],
        /** 换算 */
        MATRIX: ["换算"],
        /** 翻译消费 */
        TRANSLATION_CONSUME: ["翻译消费"],
        VOICE_CONSUME: ["语音消费"],
        OTHER: ["其他"],
        INVITE_REWARD: ["邀请奖励"],
      },
      page: {
        size: 10,
        num: 1,
        total: 0,
      },
      list: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loadData();
    },
    async loadData() {
      this.loading = true;
      let params = this.getQuery();
      params.pageSize = this.page.size;
      params.pageNum = this.page.num;
      if (params.date && params.date[0] && params.date[1]) {
        let begin = this.$dayjs(params.date[0]).format("YYYY-MM-DD HH:mm:ss");
        let end = this.$dayjs(params.date[1]).format("YYYY-MM-DD HH:mm:ss");
        params.createDateTimeBegin = begin;
        params.createDateTimeEnd = end;
      }
      delete params.date;
      let result = await memberAccountLogService.page(params);
      console.log(result);
      this.loading = false;
      this.page.total = result.total;
      this.list = result.rows;
    },
    getQuery() {
      let query = {};
      for (var k in this.query) {
        let v = this.query[k];
        if (v == -1) {
          continue;
        }
        query[k] = v;
      }
      return query;
    },
    search() {
      this.page.num = 1;
      this.loadData();
    },
    pageChange() {
      this.loadData();
    },
  },
};
</script>

