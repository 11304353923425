

<style lang="scss">

</style>
<template>
    <span>{{fmt()}}</span>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        name: 'TranslateChannelLabel',
        computed: {
            ...mapState(['TRAN_CHANNEL_LIST','TRAN_CHANNEL_MAP'])
        },
        props:{
            tranChannelId: {
                typeof: Number,
                require: true
            }
        },
        data() {
            let list = this.TRAN_CHANNEL_LIST;
            console.log(list)
            return {

            }
        },
        mounted(){
        },
        methods:{
            fmt(){
                let item = this.TRAN_CHANNEL_MAP[this.tranChannelId]
                return item ? item.name : "未知渠道";
            }
        }
    }
</script>