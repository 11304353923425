import http from "@/utils/http.js"

export default{
    async platformList(){
        let api = "/customer-service/platform/list"
        let result = await http.get(api);
        if(!result.success){
            return null;
        }
        return result.data;
    },
    async list(params){
        let api = "/customer-service/list"
        let result = await http.get(api,params);
        if(!result.success){
            return null;
        }
        return result.data;
    },
    async valid(params){
        let api = "/customer-service/get"
        let result = await http.get(api,params);
        if(!result.success){
            return result.success;
        }
        return result.data;
    },
}