<style lang="scss" scoped>
</style>
<template>
  <div class="mainPage">
    <PageHeader title="个人资料" />
    <Form
      style="padding: 20px; width: 450px"
      ref="form"
      :model="formData"
      :rules="rules"
      :label-width="100"
    >
      <FormItem label="昵称" prop="nickname">
        <Input v-model="formData.nickname" placeholder="请输入昵称"></Input>
      </FormItem>
      <FormItem>
        <Button @click="submit" :loading="loading" class="saveBtn" long>保存</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import userService from "@/service/user";
import PageHeader from "../components/common/pageHeader.vue";
export default {
  name: "UserCenterInfo",
  components: { PageHeader },
  data() {
    return {
      loading: false,
      formData: {},
      rules: {
        nickname: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          {
            type: "string",
            min: 2,
            message: "昵称至少输入2位",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      let { nickname } = this.formData;
      let params = { nickname };

      let sub = async () => {
        this.loading = true;
        let result = await userService.update(params);
        this.loading = false;
        if (!result) {
          return;
        }
        let userInfo = await userService.get();
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
        this.$Message.success("修改成功");
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          sub();
        }
      });
    },
  },
};
</script>

