

<style lang="scss">

.ivu-drawer-body{
    padding: 0 !important;
    margin: 0;
}
.tabs{
    display: flex;
    .tab{
        width: 50%;
        text-align: center;
        margin-bottom: 25px;
        background: #dededd;
        &:first-child{
            border-right: 0;
        }
        padding: 10px 0;
        border: 1px solid #606060;
        &.act{
            border-color:#2d8cf0;
            background:#2d8cf0;
            color: #fff;
        }
        cursor: pointer;
    }
    .quick-btn-warp{
        padding-top: 155px;
    }
}
</style>
<template>
    <Drawer
        :width="450"
        :mask-closable="false" 
          v-model="show"
        title="分配子账号字符数"
    >
        <Form style="padding:20px !important" ref="form" :model="formData" :rules="rules" label-position="top">
            <FormItem label="子账户账号" >
                <Input disabled v-model="formData.account" />
            </FormItem>
            <FormItem label="子账户昵称" >
                <Input disabled v-model="formData.nickname" />
            </FormItem>
            <FormItem label="子账户当前字符数" >
                <Input disabled v-model="formData.usableCharNum" />
            </FormItem>
            <FormItem label="可分配字符数" >
                <Input disabled v-model="userInfo.memberAccount.usableCharNum" />
            </FormItem>
            <FormItem label="变更字符数量" prop="changeCharNum" >
                <InputNumber style="width:100%" :max="userInfo.memberAccount.usableCharNum"
                :step="10000" :min="0" v-model="formData.changeCharNum"  />
                <div style="padding-top:15px">
                    <Button class="btn" style="margin-right:5px" 
                        v-for="item in btns" :key="item.num"
                        :disabled="userInfo.memberAccount.usableCharNum < item.num" 
                        @click="formData.changeCharNum = item.num"
                    >
                        {{item.label}}
                    </Button>
                </div>
            </FormItem>
            <FormItem label="变更详情" prop="changeCharNum" >
                <Row>
                    <Divider orientation="left">子账户信息</Divider>
                    <Col span="6">变更前</Col>
                    <Col span="18">{{formData.usableCharNum}}</Col>
                    <Col span="6">变更后</Col>
                    <Col span="18">{{formData.changeCharNum}}</Col>
                    <Col span="6">净值差</Col>
                    <Col span="18">
                        <span style="color:red" v-if="formData.changeCharNum > formData.usableCharNum">+{{formData.changeCharNum - formData.usableCharNum}}</span>
                        <span style="color:green" v-if="formData.changeCharNum < formData.usableCharNum">-{{formData.usableCharNum - formData.changeCharNum}}</span>
                        <span style="color:#a7a7a7" v-if="formData.changeCharNum == formData.usableCharNum">无变更</span>
                    </Col>
                    <Divider orientation="left">主账户信息</Divider>
                    <Col span="6">变更前</Col>
                    <Col span="18">{{userInfo.memberAccount.usableCharNum}}</Col>
                    <Col span="6">变更后</Col>
                    <Col span="18">
                        <span v-if="(userInfo.memberAccount.usableCharNum + formData.usableCharNum - formData.changeCharNum) > -1">
                            {{userInfo.memberAccount.usableCharNum + formData.usableCharNum - formData.changeCharNum}}
                        </span>
                        <span v-else>余额不足</span>
                    </Col>
                    <Col span="6">净值差</Col>
                    <Col span="18">
                        <span style="color:green" v-if="formData.changeCharNum > formData.usableCharNum">
                            -{{formData.changeCharNum - formData.usableCharNum}}
                        </span>
                        <span style="color:red" v-if="formData.changeCharNum < formData.usableCharNum">
                            +{{formData.usableCharNum - formData.changeCharNum}}
                        </span>
                        <span style="color:#a7a7a7" v-if="formData.changeCharNum == formData.usableCharNum">无变更</span>
                    </Col>
                </Row>
            </FormItem>
            <FormItem >
                <Button @click="submit"  :loading="loading" :disabled="((userInfo.memberAccount.usableCharNum + formData.usableCharNum - formData.changeCharNum) < 0) || formData.changeCharNum == formData.usableCharNum"  long type="primary" size="large">
                    分配
                </Button>
            </FormItem>
            
        </Form>
        
    </Drawer>
</template>

<script>
    import userService from "@/service/user/index"
    export default {
        name: 'ChildUserDistributionDrawer',
        emits:["on-success"],
        data() {
            return {
                show : false,
                userInfo : {
                    memberAccount:{}
                },
                btns:[
                    {num : 10000,label:"1万字"},
                    {num : 50000,label:"5万字"},
                    {num : 100000,label:"10万字"},
                    {num : 200000,label:"20万字"},
                    {num : 500000,label:"50万字"},
                ],
                rules: {
                },
                formData: {
                    userId : null,
                    account: "",
                    nickname: "",
                    usableCharNum: 0,
                    changeCharNum: 0
                },
                loading: false,
            }
        },
        mounted(){
        },
        methods:{
            async open(userId){
                this.show = true;
                this.userInfo = await userService.get();
                this.initUpdateForm(userId);
            },
            async initUpdateForm(userId){
                let userInfo = await userService.getChildInfo(userId);
                let usableCharNum = userInfo.memberAccount.usableCharNum;
                usableCharNum = usableCharNum ? usableCharNum : 0;
                this.formData = {
                    userId: userInfo.userId,
                    account: userInfo.account,
                    nickname: userInfo.nickname,
                    usableCharNum: usableCharNum,
                    changeCharNum: usableCharNum
                }
            },
            submit(){
                let {userId,changeCharNum} = this.formData;
                let params = {childUserId:userId,changeCharNum};

                let sub = async () => {
                    this.loading = true;
                    //ajax
                    let result = await userService.distribution(params);
                    this.loading = false;
                    if(!result){
                        return;
                    }
                    this.success();
                }
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        sub();
                    }
                })
            },
            success(){
                this.$Message.success('操作成功');
                this.$emit("on-success");
                this.show = false;

            }
            
        }
    }
</script>