<template>
  <div class="functions">
    <div class="mainContent">
      <div class="moduleTitle">软件的功能</div>
      <template v-if="!isMobile">
        <div class="flex justify-between mb20">
          <div class="w6167 mr20">
            <div class="panel blueBg1 mb20 flex justify-between">
              <div>
                <div class="panelTitle">账户管理权限</div>
                <div class="panelDesc">
                  主账户可添加无限个子账户及分配字符量，便于员工账户的统一管理
                </div>
              </div>
              <img :src="accountAuth" alt="" class="authImg" />
            </div>
            <div class="flex">
              <div class="panel w50_10 mr20 flex justify-between">
                <div>
                  <div class="panelTitle">自动翻译</div>
                  <div class="panelDesc">
                    收发消息根据设置语言自动翻译<br />无需频繁操作
                  </div>
                </div>
                <img :src="zdfy" alt="" class="zdfyImg" />
              </div>
              <div class="panel w50_10 flex justify-between">
                <div>
                  <div class="panelTitle">多种翻译通道</div>
                  <div class="panelDesc">
                    多种翻译通道可随心切换<br />使翻译更加精准
                  </div>
                </div>
                <img :src="fytd" alt="" class="zdfyImg" />
              </div>
            </div>
          </div>
          <div class="panel blueBg2 grow">
            <div class="panelTitle">应用集成</div>
            <div class="panelDesc">
              易翻译集成了whatsapp/line/zalo/telegram等全球主流社交软件
            </div>
            <div class="text-right">
              <img :src="yyjc" alt="" class="inline" />
            </div>
          </div>
        </div>
        <div class="mb20 flex">
          <div class="panel blueBg2 w50_10 mr20 flex justify-between">
            <div>
              <div class="panelTitle">应用多开</div>
              <div class="panelDesc">
                易翻译支持免费使用应用多开功能，可同时打开多个应用聊天沟通
              </div>
            </div>
            <img :src="yydk" alt="" class="yydkImg" />
          </div>
          <div class="panel w50_10 flex justify-between">
            <div>
              <div class="panelTitle">客户资料库</div>
              <div class="panelDesc">
                易翻译支持whatsapp/line/zalo等应用的好友统计，标签备注功能
              </div>
            </div>
            <img :src="khzlk" alt="" class="khzlkImg" />
          </div>
        </div>
        <div class="flex justify-between">
          <div class="panel w6167 mr20 flex justify-between">
            <div>
              <div class="panelTitle">群发功能</div>
              <div class="panelDesc">
                易翻译支持whatsapp/googlevoice/textnow等应用群发自动打招呼的功能<br />发展客户更方便
              </div>
            </div>
            <img :src="qfgn" alt="" class="khzlkImg" />
          </div>
          <div class="panel blueBg1 grow flex justify-between">
            <div>
              <div class="panelTitle">更多功能</div>
              <div class="panelDesc">
                持续开发语音翻译 视频翻译图片翻译功能<br />助力跨境电商服务
              </div>
            </div>
            <img :src="gdgn" alt="" class="khzlkImg" />
          </div>
        </div>
      </template>
      <template v-if="isMobile"
        ><div class="flex justify-between mb20" style="flex-wrap: wrap">
          <div class="panel blueBg1">
            <div>
              <div class="panelTitle blue">账户管理权限</div>
              <div class="panelDesc" style="padding-right: 3rem">
                主账户可添加无限个子账户及分配字符量，便于员工账户的统一管理
              </div>
            </div>
            <div style="text-align: right">
              <img :src="accountAuth" alt="" />
            </div>
          </div>
          <div class="panel blueBg2">
            <div class="panelTitle blue">应用集成</div>
            <div class="panelDesc">
              易翻译集成了whatsapp/line/zalo/telegram等全球主流社交软件
            </div>
            <div class="text-right">
              <img
                :src="yyjc"
                alt=""
                style="
                  width: 10rem !important;
                  height: 5.5rem !important;
                  margin-top: 0.63rem;
                "
              />
            </div>
          </div>
          <div class="panel">
            <div>
              <div class="panelTitle">自动翻译</div>
              <div class="panelDesc">
                收发消息根据设置语言自动翻译无需频繁操作
              </div>
            </div>
            <div style="text-align: right">
              <img :src="zdfy" alt="" />
            </div>
          </div>
          <div class="panel">
            <div>
              <div class="panelTitle">多种翻译通道</div>
              <div class="panelDesc">多种翻译通道可随心切换使翻译更加精准</div>
            </div>
            <div style="text-align: right">
              <img :src="fytd" alt="" />
            </div>
          </div>
          <div class="panel blueBg2">
            <div>
              <div class="panelTitle blue">应用多开</div>
              <div class="panelDesc">
                易翻译支持免费使用应用多开功能，可同时打开多个应用聊天沟通
              </div>
            </div>
            <div style="text-align: right">
              <img :src="yydk" alt="" />
            </div>
          </div>
          <div class="panel">
            <div>
              <div class="panelTitle">客户资料库</div>
              <div class="panelDesc">
                易翻译支持whatsapp/line/zalo等应用的好友统计，标签备注功能
              </div>
            </div>
            <div style="text-align: right">
              <img :src="khzlk" alt="" />
            </div>
          </div>
          <div class="panel">
            <div>
              <div class="panelTitle">群发功能</div>
              <div class="panelDesc">
                易翻译支持whatsapp/googlevoice/textnow等应用群发自动打招呼的功能发展客户更方便
              </div>
            </div>
            <div style="text-align: right">
              <img :src="qfgn" alt="" />
            </div>
          </div>
          <div class="panel blueBg1">
            <div>
              <div class="panelTitle blue">更多功能</div>
              <div class="panelDesc" style="padding-right: 3rem">
                持续开发语音翻译 视频翻译图片翻译功能助力跨境电商服务
              </div>
            </div>
            <div style="text-align: right">
              <img :src="gdgn" alt="" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import accountAuth from "@/assets/imgs/home/functions/accountAuth.png";
import zdfy from "@/assets/imgs/home/functions/zdfy.png";
import fytd from "@/assets/imgs/home/functions/fyqd.png";
import yyjc from "@/assets/imgs/home/functions/yyjc1.png";
import yydk from "@/assets/imgs/home/functions/yydk.png";
import khzlk from "@/assets/imgs/home/functions/khzlk.png";
import qfgn from "@/assets/imgs/home/functions/qfgn.png";
import gdgn from "@/assets/imgs/home/functions/gdgn.png";
const isMobile =
  window.screen.width > 768 || window.screen.width == 768 ? false : true;
</script>
<style scoped>
.functions {
  background: #f6f8ff;
  padding: 5.625rem 0 6.3125rem;
}
.moduleTitle {
  margin-bottom: 3.75rem;
}
.panel {
  padding: 1.625rem 0 0.75rem 1.625rem;
  border-radius: 1rem;
  background-color: #fff;
}
.panelTitle {
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.75rem;
}
.panelDesc {
  color: #8e939f;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
}
.blueBg1 {
  background-color: #e5f3fc;
}
.blueBg2 {
  background-color: #eaf2ff;
}
.w6167 {
  width: 61.67%;
}
.w50_10 {
  width: calc(50% - 10px);
}
.authImg {
  margin: 0 1.3125rem;
}
.zdfyImg {
  margin-right: 1.5rem;
}
.yydkImg {
  margin-right: 2.25rem;
}
.khzlkImg {
  margin-right: 1.875rem;
}
@media (max-width: 768px) {
  .functions {
    padding: 2.5rem 0 1.4rem;
  }
  .moduleTitle {
    margin-bottom: 1.87rem;
  }
  .mainContent {
    padding: 0 0.75rem;
  }

  .panel {
    padding: 1rem 0.75rem 0.75rem;
    margin-bottom: 0.75rem;
    width: calc(50% - 6px);
  }
  .panelTitle {
    color: #000;
    font-family: Inter;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.5rem !important;
  }
  .panelTitle.blue {
    color: #0f9cf7;
  }

  .panelDesc {
    color: #666;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    word-wrap: break-word;
    word-break: break-all;
  }
  .panel img {
    width: 4.75rem !important;
    height: 4.75rem !important;
    display: inline !important;
  }
  .mr20 {
    margin-right: 0.8rem;
  }
}
</style>