

<style lang="scss">

</style>
<template>
    <Select v-model="selection" clearable @on-change="change" placeholder="请选择翻译渠道">
        <Option v-for="item in list" :value="item.id" :label="item.name" :key="item"></Option>
    </Select>
</template>

<script>
    import service from "@/service/member/group"
    export default {
        name: 'TranslateChannelSelect',
        emits:["update:modelValue"],
        props:{
            modelValue:Number
        },
        data() {
            return {
                loading: false,
                list : [],
                selection : []
            }
        },
        watch:{
            modelValue(){
                this.setSelection();
            }
        },
        mounted(){
            this.loadData();
        },
        methods:{
            loadData(){
                this.loadList();
            },
            setSelection(){
                this.selection = this.modelValue;
            },
            change(){
                this.$emit("update:modelValue",this.selection)
            },
            async loadList(){
                this.loading = true;
                let result = await service.list();
                this.loading = false;
                this.list = result;
            },
        }
    }
</script>