<template>
    <Modal v-model="show" :width="80" title="易翻译 - 隐私协议">
        <pre >
        易翻译软件用户服务协议
        
        欢迎你使用易翻译软件及服务！
        为使用易翻译软件（以下简称“本软件”）及服务，你应当阅读并遵守《易翻译软件用户服务协议》（以下简称“本协议”）。请你务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通或使用某项服务包括但不限于实时翻译服务的单独协议，并选择接受或不接受。限制、免责条款可能以加粗等形式提示你注意。
        除非你已阅读、知晓并接受本协议所有条款，否则你无权下载、安装或使用本软件及相关服务。你的下载、安装、注册、使用、获取易翻译帐号、登录等行为即视为你已阅读、知晓并同意本协议的约束。
        本软件用户注册使用时已为年满18周岁，具有完全民事行为能力的民事主体。如果你因年龄、智力等因素而不具备完全民事行为能力，请在法定监护人的陪同下阅读和判断是否同意本协议及上述其他协议，以及使用本软件的相关服务，并特别注意相关使用条款。
        本软件使用范围仅限于中国大陆使用。若超出上述使用范围，使用本软件从事任何活动、造成的任何后果及法律责任均由用户本人自行承担。如果你是非中国大陆地区用户，你订立或履行本协议以及使用本服务的行为同时还需要遵守你所属或所处国家或地区的法律。

        目录
        目录是空的，因为你尚未选择要在目录中显示的段落样式。

        一、特别提示
        在此特别提醒你（用户）在注册成为“易翻译”用户之前，请认真阅读本《“易翻译”软件用户服务协议》（以下简称“本协议”），确保你充分理解本协议中各条款。请你审慎阅读并选择接受或不接受本协议。你同意并点击确认本协议条款且完成注册程序后，才能成为“易翻译”的正式注册用户，并享受“易翻译”的各类服务。你的下载、安装、注册、获取易翻译帐号、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。若你不同意本协议，或对本协议中的条款存在任何疑问，请你立即停止“易翻译”用户注册程序，并可以选择不使用本软件服务。

        协议范围
        2.1、本协议是你（用户）与“易翻译”之间关于你（用户）下载、安装、注册、登录、使用本软件以及使用“易翻译”相关服务所订立的协议，约定了你（用户）与“易翻译”之间关于“易翻译”服务包括但不限于实时翻译服务（以下简称“服务”）的权利义务。
        2.2、“用户”是指注册、登录、使用本服务的个人、单位。本协议可由“易翻译”随时更新，更新后的协议条款一旦公布即代替原来的协议条款，不再另行通知，用户可在本APP中或本软件官网中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用“易翻译”提供的服务，用户继续使用“易翻译”提供的服务将被视为认可并接受修改后的协议。

        三、账号注册
        3.1、用户在使用本服务前需要注册“易翻译”账号。“易翻译”账号应当使用邮箱账号绑定并注册，请用户使用尚未与“易翻译”账号绑定的邮箱账号以及未被“易翻译”根据本协议封禁的邮箱账号注册“易翻译”账号。“易翻译”可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。
        3.2、如果注册申请者有被“易翻译”封禁的先例或涉嫌虚假注册及冒用、滥用他人名义注册，及其他不能得到许可的理由，“易翻译”将拒绝其注册申请。 
        3.3、鉴于“易翻译”账号的绑定注册方式，你同意“易翻译”在注册时将访问用户的手机设备识别码、注册地区等信息用于注册。
        3.4、在用户注册及使用本软件服务时，“易翻译”需要搜集能识别用户身份的个人信息以便“易翻译”可以在必要时联系用户，或为用户提供更好的使用体验。“易翻译”搜集的信息包括但不限于用户的姓名、性别、民族、所在地区、联系方式；“易翻译”同意对这些信息的使用将受限于用户个人隐私信息保护的约束。
        3.5、用户使用“易翻译”应当经过“易翻译”授权的安装程序，在“易翻译”官方网站下载并注册使用。如果你从未经“易翻译”授权的第三方获取本软件或与本软件名称相同的安装程序，“易翻译”无法保证该软件能够正常使用，若因此给你方造成的任何损失均不予负责。

        四、账户安全
        4.1、用户一旦注册成功，成为“易翻译”的用户，将得到一个用户名和密码，并有权利使用自己的用户名及密码随时登陆“易翻译”，登录以及使用本软件时应当遵守本协议全部内容。
        4.2、用户对用户名和密码的安全负全部责任，同时对以其用户名进行的所有活动和事件负全责。
        4.3、用户不得以任何形式赠与、借用、租用、转让、售卖或者以其他方式许可非初始申请注册人使用使用自己的“易翻译”账户及用户名。
        4.4、用户有责任妥善保管注册帐户信息及帐户密码的安全，用户需对注册帐户以及密码下的行为承担全部法律责任。用户同意在任何情况下不向他人透露帐户及密码信息。若因密码保管不善而造成的所有损失由用户自行承担。若因此给“易翻译”造成损失的，“易翻译”及公司有权要求该用户赔偿全部经济损失。
        4.5、如果用户泄漏了密码，有可能导致不利的法律后果，因此不管任何原因导致用户的密码安全受到威胁，应该立即和“易翻译”客服人员取得联系并通知“易翻译”，否则后果自负，造成的损失由用户自行承担。若给“易翻译”造成损失的，“易翻译”及公司还有权要求该用户赔偿全部经济损失。

        五、用户声明与保证
        5.1、用户承诺其已年满18周岁，具有完全民事行为能力的民事主体，且具有达成交易履行其义务的能力。
        5.2、用户承诺并保证使用易翻译提供的翻译服务时，所支付的服务对价系用户本人合法所得（合法所有）并持有的款项，不存在任何侵害公私财物或他人权益的情形。
        5.3、用户承诺并保证仅在中国大陆地区使用本软件及服务。
        5.4、用户有义务在注册时提供自己的真实资料，并保证诸如姓名、性别、手机号码、所在地区等内容的有效性及安全性，保证“易翻译”工作人员可以通过上述联系方式与用户取得联系。同时，用户也有义务在相关资料实际变更时及时更新有关注册资料。
        5.5、用户通过使用“易翻译”的过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复和相关链接页面，以及其他使用账号或本服务所产生的内容。你理解并同意，“易翻译”一直致力于为用户提供文明健康、规范有序的网络环境，你不得利用“易翻译”帐号或本软件及服务制作、复制、发布、传播、储存如下违反国家法律法规规定的，干扰“易翻译”正常运营，以及侵犯其他用户或第三方合法权益的内容，也不得为任何行为提供便利，包含但不限于：
        5.5.1、不得发布、传送、传播、储存违反国家法律法规规定的内容或从事相关行为提供便利：
        （1）违反宪法确定的基本原则的；
        （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
        （3）损害国家荣誉和利益的；
        （4）煽动民族仇恨、民族歧视，破坏民族团结的；
        （5）破坏国家宗教政策，宣扬邪教和封建迷信的；
        （6）散布谣言，扰乱社会秩序，破坏社会稳定的；
        （7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
        （8）侮辱或者诽谤他人，侵害他人合法权益的；
        （9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；
        （10）以非法民间组织名义活动的；
        （11）不符合或不遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；
        （12）含有法律、行政法规禁止的其他内容的。
        5.5.2、不得干扰“易翻译”正常运营，以及侵犯其他用户或第三方合法权益的内容或从事相关行为提供便利：
        （1）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
        （2）非法收集获取用户隐私、数据、个人信息或资料的；
        （3）含有辱骂、恐吓、威胁内容的；
        （4）发表、传送、传播骚扰、广告信息、恶意信息、过度营销信息及垃圾信息或含有任何性或性暗示的诱骗信息；
        （5）其他违反法律法规、政策及公序良俗、社会公德或干扰“易翻译”软件正常运营和侵犯其他用户或第三方合法权益内容的信息。

        用户注意事项
            6.1、你理解并同意：为了向你提供有效的服务，本软件会利用你终端设备的处理器和宽带等资源。本软件使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。
        6.2、你理解并同意：本软件的某些功能可能会让第三方知晓用户的信息。例如：用户的好友可以查询用户头像、名字、“易翻译”帐号等可公开的个人简介以及和互动信息等已公开的信息资料
        6.3、你在使用本软件某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），你在使用该项服务前请阅读并同意相关的单独协议；你使用前述特定服务，即视为你接受相关单独协议。
            6.4、你理解并同意“易翻译”将会尽其合理努力保障你在本软件及服务中的数据存储安全，但是用户应当自行保存使用本软件时产生的全部数据，“易翻译”不对数据备份、储存或丢失等负责，包括但不限于以下情形：
        6.4.1、“易翻译”不对你在本软件及服务中相关数据的删除或储存失败负责；
            6.4.2、“易翻译”有权根据实际情况自行决定单个用户在本软件及服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。你可根据自己的需要自行备份本软件及服务中的相关数据；
        6.4.3、如果你停止使用本软件及服务或服务被终止或取消，“易翻译”可以从服务器上永久地删除你的数据。服务停止、终止或取消后，“易翻译”没有义务向你返还任何数据。
        6.4.5、用户在使用本软件及服务时，须自行承担如下来自“易翻译”不可掌控的风险内容，包括但不限于：
        （1）使用本软件从事任何活动，产生的自身纠纷或与第三方纠纷均由用户本人自行承担；
        （2）用户在使用本软件访问第三方网站时或与第三方联系时，因第三方或第三方网站及相关内容所可能导致的风险，由用户本人自行承担；
        （3）由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险；
        （4）用户必须选择与所安装终端设备相匹配的软件版本，否则，由于软件与终端设备型号不相匹配所导致的任何问题或损害，均由用户自行承担；
        （5）用户发布的内容被他人转发、分享，因此等传播可能带来的风险和责任；
        （6）由于无线网络信号不稳定、无线网络带宽小等原因，所引起的“易翻译”登录失败、资料同步不完整、页面打开速度慢等风险。
        6.4.6、你理解并同意：在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指一切不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、风暴、瘟疫等以及社会事件如战争、动乱、政府行为等。

        用户使用规范
        7.1、除非法律允许或“易翻译”书面许可，你使用本软件过程中不得从事下列行为：
        7.1.1、对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；
        7.1.2、通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
        7.1.3、通过非“易翻译”开发、授权的第三方软件、插件、外挂、系统，登录或使用“易翻译”软件及服务，或制作、发布、传播上述工具；
        7.1.4、其他未经“易翻译”明示授权的行为。
            7.2、除非法律允许或“易翻译”书面许可，你使用本服务过程中不得从事下列行为：
            7.2.1、提交、发布虚假信息，或冒充、利用他人名义的；
        7.2.2、诱导其他用户点击链接页面或分享信息的；
        7.2.3、虚构事实、隐瞒真相以误导、欺骗他人的；
        7.2.4、侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
            7.2.5、未经“易翻译”书面许可利用“易翻译”帐号、任何功能，以及第三方运营平台进行推广或互相推广的；
            7.2.6、利用“易翻译”帐号或功能帐号或本软件及服务从事任何违法犯罪活动的；
        7.2.7、制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；
        7.2.8、其他违反法律法规规定、侵犯其他用户合法权益、第三方、干扰产品正常运营或“易翻译”未明示授权的行为。
        八、服务内容及解释权
        8.1、本服务内容是指“易翻译”向用户提供的实时翻译服务，在发送基本文字内容通讯服务的基础上还提供各行业或类目全球大部分语言的文本翻译服务。
        8.2、“易翻译”有权随时审核或删除用户发布/传播的涉嫌违法或违反社会主义精神文明，或者被“易翻译”认为不妥当的内容（包括但不限于文字、语音、图片、视频、图文等）。
        8.3、所有发给用户的通告及其他消息都可通过APP或者用户所提供的联系方式发送。
        8.4、本软件提供的实时翻译服务及对应文本翻译内容释义以及相关问题的最终解释权归“易翻译”及公司所有。

        九、服务的变更、中断
        9.1、鉴于网络服务的特殊性，用户需同意“易翻译”会变更、中断部分或全部的网络服务，并删除（不再保存）用户在使用过程中提交的任何资料，而无需通知用户，也无需对任何用户或任何第三方承担任何责任。
        9.2、“易翻译”需要定期或不定期地对提供网络服务的平台进行检测或者更新，如因此类情况而造成网络服务在合理时间内的中断，“易翻译”及公司无需为此承担任何责任。
        
        十、服务的终止
        10.1、在下列情况下，“易翻译”有权终止向用户提供服务：
        10.1.1、在用户违反本服务协议相关规定时，“易翻译”有权终止向该用户提供服务；如该用户再一次直接或间接或以他人名义注册为用户的，一经发现，“易翻译”有权直接单方面终止向该用户提供服务；
        10.1.2、如“易翻译”通过用户提供的相关信息与用户联系时，发现用户在注册时填写的联系方式或邮箱账号已不存在或无法接通，“易翻译”以其它联系方式通知用户更改，而用户在三个工作日内仍未能提供新的联系方式，“易翻译”有权终止向该用户提供服务；
        10.1.3、用户不得通过程序或人工方式进行作弊，若发现用户有作弊行为，“易翻译”将立即终止服务；
        10.1.4、一旦“易翻译”发现用户提供的数据或信息中含有虚假内容，“易翻译”有权随时终止向该用户提供服务；
        10.1.5、本服务条款终止或更新时，用户明示不愿接受新的服务条款；
        10.1.6、其它“易翻译”认为需终止服务的情况。
        10.2、服务终止后，“易翻译”没有义务为用户保留原账号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给用户或第三方。
        10.3、用户理解并同意，即便在本协议终止及用户的服务被终止后，“易翻译”仍有权：
        10.3.1、继续保存你的用户信息；
        10.3.2、继续向用户主张在其使用“易翻译”服务期间因违反法律法规、本协议及本软件规则而应承担的责任。

        十一、服务协议内容修改
        11.1、本协议可由“易翻译”随时更新。“易翻译”有权随时修改本服务协议的任何内容，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本APP中或本软件官网中查阅最新版协议条款。
        11.2、如果不同意“易翻译”对本服务条款所做的修改，不接受修改后的条款，请立即停止使用“易翻译”提供的服务。
        11.3、如果用户继续使用“易翻译”提供的服务，则将视为用户接受“易翻译”对本服务条款所做的修改。

        免责与赔偿声明
        12.1、你理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务受到影响。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，“易翻译”及公司将努力在第一时间与相关单位配合，争取及时进行处理，但是由此给你造成的损失，“易翻译”及公司在法律允许的范围内免责。
        12.2、在法律允许的范围内，“易翻译”及公司对以下情形导致的服务中断或受阻不承担责任
        12.2.1、受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏。
        12.2.2、用户或“易翻译”及公司的电脑软件、系统、硬件和通信线路出现故障。
        12.2.3、用户操作不当或用户通过非“易翻译”及公司授权的方式使用本服务。
        12.2.4、程序版本过时、设备的老化和/或其兼容性问题。
        12.2.5、其他“易翻译”及公司无法控制或合理预见的情形。
        12.2.6、你理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，“易翻译”及公司不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给你造成的损害负责。这些风险包括但不限于：
        （1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息。
        （2）遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失。
        （3）其他因网络信息或用户行为引起的风险。
        12.4、“易翻译”及公司依据本协议约定获得处理违法违规内容的权利，该权利不构成“易翻译”及公司的义务或承诺，“易翻译”及公司不能保证及时发现违法行为或进行相应处理。
        12.5、在任何情况下，你不应轻信借款、索要密码或其他涉及财产的信息。涉及财产操作的，请一定先核实对方身份，并请经常留意“易翻译”及公司有关防范诈骗犯罪的提示。
        12.6、你在使用本服务过程中所翻译或发布的内容有可能会被第三方转发、复制、修改或做其他用途，从而脱离你的预期和控制，你必须充分意识此类风险的存在，“易翻译”及公司对由此产生的纠纷不予负责。
        12.7、若“易翻译”及公司已经明示其服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果及法律责任由用户自行承担。若给“易翻译”造成损失的，“易翻译”及公司还有权要求该用户赔偿全部经济损失。
        12.8、用户明确同意其使用“易翻译”所存在的风险将完全由其自己承担，因其使用“易翻译”而产生的一切后果及法律责任也由其自行承担。若给“易翻译”造成损失的，“易翻译”及公司还有权要求该用户赔偿全部经济损失。
        12.9、用户同意保障和维护“易翻译”及其他用户的利益，由于用户在使用“易翻译”有违法、不真实、不正当、侵犯第三方合法权益的行为，或用户违反本协议项下的任何条款而给“易翻译”及任何其他第三方造成损失，用户同意承担由此造成的全部损害赔偿责任。若给“易翻译”造成损失的，“易翻译”及公司还有权要求该用户赔偿全部经济损失。
        12.10、用户使用本软件的翻译服务，翻译内容涉及国家政治问题、国家安全问题、及他人隐私问题等，所造成的一切后果及法律责任由用户自行承担。
        12.11、用户自愿接受并使用本软件，在使用该软件进行翻译服务的过程中，若因行业专业术语理解的偏差、因软件系统问题导致的翻译偏差或因用户自身输入文字及使用标点符号习惯等行为产生的任何翻译内容不准确，理解不准确，由此导致的任何风险与“易翻译”及本公司无关。
        12.1 2、本软件仅限于中国大陆地区使用，若用户在其它任何地区使用“易翻译”所存在的风险将完全由其自己承担，因其使用“易翻译”而产生的一切后果及法律责任也由其自己承担。若给“易翻译”造成损失的，“易翻译”及公司还有权要求该用户赔偿全部经济损失。

        十三、隐私声明
        13.1、适用范围：
        13.1.1、在用户注册“易翻译”账户时，根据要求提供的个人注册信息；
        13.1.2、在用户使用“易翻译”，或访问其相关网页时，“易翻译”自动接收并记录的用户浏览器上的服务器数值，包括但不限于IP地址等数据及用户要求取用的网页记录。
        13.2、信息使用：
        13.2.1、“易翻译”不会向任何人出售或出借用户的个人信息，除非事先得到用户的许可；
        13.2.2、“易翻译”亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播用户的个人信息。任何用户如从事上述活动，一经发现，“易翻译”有权立即终止与该用户的服务协议，查封其账号，若给易翻译造成损失的，易翻译还有权要求该用户赔偿全部经济损失；
        13.2.3、为达到服务用户的目的，“易翻译”可能通过使用用户的个人信息，向用户提供服务，包括但不限于向用户发出产品和服务信息，或者与“易翻译”合作伙伴共享信息以便他们向用户发送有关其产品和服务的信息。
        13.3、信息披露：用户的个人信息将在下述情况下部分或全部被披露： 
        13.3.1、经用户同意，向第三方披露；
        13.3.2、根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
        13.3.3、如果用户出现违反中国有关法律或者网站政策的情况，需要向第三方披露； 
        13.3.4、为提供用户所要求的产品和服务，而必须和第三方分享用户的个人信息； 
        13.3.5、其它“易翻译”根据法律或者网站政策认为合适的披露；
        13.3.6、用户使用“易翻译”时提供的银行账户信息，“易翻译”将严格履行相关保密约定。

        十四、其他
        14.1、“易翻译”郑重提醒用户注意本协议中免除“易翻译”责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。
        14.2、你使用本软件即视为你已阅读、知晓并同意受本协议的约束。
        14.3、本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
        14.4、若用户和“易翻译”之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，双方均应向   成都千蓝月网络科技有限公司  公司所在地的人民法院提起诉讼。
        14.5、本协议可能存在包括中文、英文在内的多种语言的版本，如果存在中文版本与其他语言的版本相冲突的地方，以中文版本为准。
        14.6、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
        14.7、本协议最终解释权归“易翻译”及公司所有，并且保留一切解释和修改的权力。
        （完）
        成都千蓝月网络科技有限公司 
        </pre>
    </Modal>
</template>

<script>
    export default {
        name: '',
        data() {
            return {
                show : false,
                page : 1,
            }
        },
        methods : {
            open(){
                this.show = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    }
</style>