import http from "@/utils/http.js"

export default{
    async checkEmail(account){
        let api = "/login/checkAccount";
        let params = {account}
        let result = await http.post(api,params);
        return result.success && result.data;
    },
    async checkUsername(username){
        let api = "/login/checkUsername";
        let params = {username}
        let result = await http.post(api,params);
        return result.success && result.data;
    },
    async sendVCode(account){
        let api = "/login/sendVCode";
        let params = {account}
        let result = await http.post(api,params);
        return result.success && result.data;
    },
    async register(params){
        let api = "/login/register"
        let result = await http.post(api,params);
        if(!result.success){
            return null;
        }
        return result.data;
    },
    async login(params){
        let api = "/login/login"
        params.clientType = "web";
        let result = await http.post(api,params);
        if(!result.success){
            return null;
        }
        return result.data;
    },
    async findEmail(accountOrEmail){
        let api = "/login/findEmail"
        let result = await http.get(api,{accountOrEmail});
        if(!result.success){
            return null;
        }
        return result.data;
    },
    async resetPwdByVcode(params){
        let api = "/login/resetPwdByVcode"
        let result = await http.post(api,params);
        if(!result.success){
            return null;
        }
        return result.data;
    }
}