<template>
  <div class="mainPage">
    <PageHeader title="登陆记录" />
    <div class="searchWrap">
      <Row :gutter="16">
        <Col span="7" class="searchCol">
          <span>登陆IP地址 :</span>
          <Input v-model="query.loginIp" placeholder="请输入登陆IP地址" class="searchIpt"></Input>
        </Col>
        <Col span="7" class="searchCol">
          <span>登陆客户端 :</span>
          <Input v-model="query.clientType" placeholder="请输入登陆客户端" class="searchIpt"></Input>
        </Col>
        <Col span="7" class="searchCol">
          <span>时间范围 :</span>
          <DatePicker
            v-model="query.date"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择登陆时间范围"
            class="searchDate"
          />
        </Col>
        <Col span="3" class="searchCol" style="text-align: right;display: block">
            <Button class="saveBtn" @click="search" long>查询</Button>
        </Col>
      </Row>
    </div>

    <div>
      <Table
        :loading="loading"
        size="small"
        :height="556"
        stripe
        :columns="columns"
        :data="list"
      />
    </div>
    <div style="text-align: right;">
      <Page
        style="margin-top: 20px"
        show-total
        :total="page.total"
        :page-size="page.size"
        v-model="page.num"
        @on-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import loginLogService from "@/service/login/log.js";
import PageHeader from "../components/common/pageHeader.vue";
export default {
  components: { PageHeader },
  name: "UserCenterLoginLog",
  data() {
    const begin = new Date();
    begin.setTime(begin.getTime() - 3600 * 1000 * 24 * 10);
    return {
      loading: false,
      columns: [
        { type: "index", title: "序号", width: 75 },
        { title: "登陆ip", key: "loginIp" },
        { title: "登陆客户端", key: "clientType" },
        { title: "登陆时间", key: "loginDateTime" },
      ],
      page: {
        size: 10,
        num: 1,
        total: 0,
      },
      query: {
        loginIp: "",
        clientType: "",
        date: [begin, new Date()],
      },
      list: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loadData();
    },
    async loadData() {
      let params = this.getQuery();
      params.pageSize = this.page.size;
      params.pageNum = this.page.num;
      if (params.date && params.date[0] && params.date[1]) {
        let begin = this.$dayjs(params.date[0]).format("YYYY-MM-DD HH:mm:ss");
        let end = this.$dayjs(params.date[1]).format("YYYY-MM-DD HH:mm:ss");
        params.loginDateTimeBegin = begin;
        params.loginDateTimeEnd = end;
      }
      delete params.date;
      this.loading = true;
      let result = await loginLogService.page(params);
      this.loading = false;
      this.page.total = result.total;
      this.list = result.rows;
    },
    getQuery() {
      let query = {};
      for (var k in this.query) {
        let v = this.query[k];
        if (v == -1) {
          continue;
        }
        query[k] = v;
      }
      return query;
    },
    search() {
      this.page.num = 1;
      this.loadData();
    },
    pageChange() {
      this.loadData();
    },
  },
};
</script>

