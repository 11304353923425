// 封装请求
import axios from 'axios'
import { Message } from 'view-ui-plus'

import basicConfig from '@/config'
import { hex_md5 } from "@/utils/md5.js";
// 请求
let request = axios.create({
  baseURL: basicConfig.request,
  timeout: 60000,
})

// 请求拦截
request.interceptors.request.use(config => {
  // 请求参数拦截 
  initParam(config);
  // 请求头拦截
  initHeaders(config);

  return config
}, err => {
  console.log(err);
})

// 响应拦截
request.interceptors.response.use(response => {
  let result = response.data;
  console.log("response:", response)
  if (!result.success) {
    if (response?.config?.url?.indexOf('/customer-service/get') == -1) {
      Message.error(result.msg)
    }
  }
  return response.data
}, err => {
  if (err.response.status >= 700 && err.response.status <= 777) {
    Message.error('登陆失效 即将跳转首页');
    setTimeout(() => {
      // window.location.href = "/user-pc/index"
      window.location.href = "/index"
    }, 1000);
    sessionStorage.removeItem("sk");
    sessionStorage.removeItem("userInfo");
    return;
  }
  Message.error('请求异常' + err);
})



const initParam = (config) => {
  config.a;
}

const initHeaders = (config) => {
  let userId = sessionStorage.getItem("userId");
  let sk = sessionStorage.getItem("sk");
  if (userId && sk) {
    let token = (hex_md5(`${sk}_${userId}`)).toUpperCase();
    let timestamp = new Date().getTime() + "";
    let sign = hex_md5(token + "_" + timestamp).toString().toUpperCase();
    config.headers.Authorization = token
    config.headers['auth-token'] = token
    config.headers['auth-timestamp'] = timestamp
    config.headers['auth-sign'] = sign
  }
}


export default request;