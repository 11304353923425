<template>
    <Modal v-model="show" :width="80" title="易翻译 - 服务条款">
        <pre>
    重要提示：
    易翻译特别提示您，在注册及使用易翻译网站及相应客户端服务前，请事先认真阅读本服务条款内容，特别是关于用户义务、用户责任及易翻译有限保证及免责的条款。易翻译网站及相应客户端的各类业务规则构成本服务条款的组成部分。拟注册用户在进行注册过程中，点击“我已仔细阅读并接受易翻译用户服务条款”即表示您完全接受并同意遵守本服务条款的全部内容。如您不同意本服务条款，请立即停止注册及使用。
    易翻译有权不时对本服务条款（包括各类业务规则）作出修改或补充，并通过易翻译网站或相应客户端公布。您继续访问和使用易翻译即视为您接受修订后的本服务条款。否则，您有权通过停止访问且拒绝使用本服务、删除账号等方式终止本服务条款。
    拟注册用户应具备我国法律规定的与您从事行为相应的民事行为能力，并需同意遵守所有服务条款之约束，在按照规定流程完成注册程序后，才能成为易翻译的正式注册用户并享受易翻译提供的更全面的服务。如果您代表某个机构而非您个人注册、登录和使用易翻译，则您将被认为获得充分授权代表该机构同意本服务条款。

    目 录
    1.	定义
    2.	服务内容
    3.	用户权利义务
    4.	用户信息及隐私政策
    5.	知识产权保护
    6.	有限保证及免责条款
    7.	服务变更、中断或终止
    8.	通知和送达
    9.	投诉处理
    10.	法律适用、争议管辖及其他

    易翻译用户服务条款
    一、定义
    1.1 易翻译：指易翻译网站（网络域名：www.易翻译.net）与相应客户端、自媒体平台、主运营网站（包括但不限于iteye.com）等。
    1.2 易翻译经营者：指北京创新乐知网络技术有限公司及其他相关联的公司。
    1.3 用户信息：指包括但不限于用户姓名或名称、身份证件号码、邮箱、银行帐号、联系方式、行踪轨迹等能够单独或相互结合识别特定用户身份或者反映某特定用户活动情况的有关信息。
    1.4 用户：
    用户：包括匿名用户、注册用户、会员用户。
    1.4.1注册用户：已注册的自然人或企业，含潜在内容发布者。
    1.4.2会员用户：已注册且开通特定服务权限的自然人或企业。
    1.5 作品：包括但不限于文章、文档、音频、视频、图片、课程、软件、源代码等内容。

    二、服务内容
    易翻译是一个面向中国软件和软件人的综合社区网站，中文IT社区和开发技术服务平台，为中国的软件开发者、IT从业者及其他人员提供技术交流及存储服务的平台，主要服务内容如下：
    2.1 知识服务
    依托易翻译社区、博客、下载、学院、移动APP、新媒体矩阵等资源，扩大并加速知识和信息的传播，为用户提供业界资讯、技术订阅、专业培训等知识和信息共享服务。同时，通过多样化的市场推广手段进行信息精确推送，为企业品牌及产品推广提供服务。
    2.2 开发服务
    易翻译经营者致力于为个人、团队及企业提供软件工程、IT管理以及数据分析过程中所需的工具产品及专业服务。易翻译经营者研发全生命周期解决方案，通过提供管理工具及服务，满足企业软件开发过程中的管理需求，提升研发团队交付能力，提高软件质量，帮助企业快速适应市场变化，支撑业务创新。
    2.3 其他服务内容详见易翻译各版块功能。

    三、用户权利义务
    3.1 用户权利
    3.1.1 在遵守本服务条款及各版块行为准则的前提下，用户有传输作品及在易翻译发表观点等权利。用户同意授予易翻译经营者对用户上传的作品进行非实质性的修改、编辑的权利。
    3.1.2 用户有按照本服务条款及各版块行为准则获得积分的权利。
    3.1.3用户获得的积分可按照易翻译各版块有关积分的规定进行兑换或使用。
    3.2用户义务
    3.2.1用户应遵守下列规则：
    （1）遵守中华人民共和国关于网络和BBS的相关法律、法规。
    （2）使用网络服务不作非法用途。
    （3）不干扰或混乱网络服务。
    （4）遵守所有使用网络服务的网络协议、规定、程序和惯例以及产品的使用说明。
    3.2.2用户在传输作品时，应保证：
    （1）不得传输或发表煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论或内容。
    （2）不传输任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的、淫秽的、侵权等信息资料。
    （3）不传输任何教唆他人可能构成犯罪行为的资料。
    （4）不传输任何不符合国家法律及国际惯例的资料。
    （5）不得在未经许可的情况下，非法进入其它电脑系统。
    （6）在论坛讨论的时候遵守易翻译论坛行为准则。
    （7）遵守所在国家的法律、法规、政策规定及易翻译的相关规定。
    3.2.3用户不得利用易翻译或其提供的服务实施下列行为：
    （1）损害他人名誉或隐私权；
    （2）使用自己名义、匿名或冒用他人或以易翻译经营者的名义散播诽谤、不实、威胁、不雅、不法、攻击性或侵害他人权利的消息或文字；
    （3）传播或散布计算机病毒；
    （4）发布与易翻译提供服务无关的垃圾广告或信息，售卖商品；
    （5）其他易翻译经营者认为不适当的行为。
    3.2.4用户知悉并同意，用户仅得依据本服务条款享有对易翻译账户及易翻译相关功能、服务的使用权，用户仅能为学习、技术交流等非营利性目的使用易翻译账户及易翻译提供的功能、服务；用户不得出于任何非法或未经易翻译授权的目的使用易翻译账户或易翻译提供的功能、服务，包括但不限于以营利为目的恶意注册帐号，不得利用易翻译账户或易翻译提供的功能、服务从事营利活动，不得以营利或非营利目的以任何方式（包括但不限于转让、借用、出租、共享等）向任何第三方提供易翻译账户或易翻译提供的功能、服务或其任何部分，不得利用易翻译账户或易翻译提供的功能、服务从事任何违法或侵犯第三方知识产权或其他合法权益的活动。
    3.2.5用户保证对其传输的作品拥有或取得了所有必要的权利，包括知识产权（包括但不限于著作权、专利权、商标权、专有技术、商业秘密等），或已经取得必要的许可、授权（如为第三方许可、授权的作品，用户须注明权利人及出处）。用户上述保证不实或违反权利人标示规定的，应承担因此可能产生的全部法律责任，给易翻译或第三方造成损失的，须赔偿易翻译及/或第三方的损失。
    3.2.6用户须妥善保管易翻译账号及密码，任何于用户账号项下发生的行为将被视为用户行为或经用户授权的行为，用户须就此承担责任。
    3.2.7用户应当对自己的言行负责，不得通过任何方式发表、散布、传播诋毁或攻击易翻译或可能对易翻译造成任何负面、不良影响的言论或信息。
    3.2.8用户违反本服务条款项下任何用户义务的，易翻译经营者有权不经通知用户而采取下列一项或多项措施，且无须向用户或任何第三方承担任何法律责任；因用户行为引发的风险、责任及损失由用户自行承担，造成易翻译或第三方损失的，用户还须赔偿全部损失：
    （1）删除不符合本服务条款规定的相关信息、资料、内容；
    （2）断开相关信息、资料、内容在易翻译上的链接；
    （3）暂停用户对账户的使用或限制部分功能或服务；
    （4）永久封禁账户并禁止任何功能或服务；
    （5）将用户信息列入易翻译及易翻译经营者黑名单，不予接受以相同用户信息注册易翻译用户或易翻译经营者运营的任何其他平台的申请，不再进行任何形式的合作；
    （6）向有关第三平台投诉或举报用户的违法违规行为；
    （7）向征信部门报送用户不诚信信息；
    （8）向有关部门就用户的违法行为进行举报或报案，协助有关部门追究违法犯罪活动的法律责任；
    （9）法律法规及本服务条款规定的其他措施。
    3.2.9用户停止对易翻译的使用的，用户仍须就此前已发生的使用行为承担责任。
    3.2.10用户同意易翻译经营者有权根据用户使用易翻译的时长、活跃程度、使用频率等综合因素调整用户等级。

    四、用户信息及隐私政策
    4.1用户信息
    4.1.1注册信息
    用户注册时应提供详尽、准确的用户资料（包括但不限于姓名或名称、身份证号码、邮箱、联系方式等），并应不断更新注册资料，以符合及时、详尽、准确的要求。因用户注册信息不真实或更新不及时产生的相关争议或问题，易翻译经营者不承担任何责任。用户使用虚假、伪造信息或冒用、盗用他人信息注册的，易翻译经营者有权不予接受注册或封禁账户及采取本服务条款第3.2.8条规定的其他措施。
    4.1.2帐号信息
    （1）用户一旦注册成功，成为易翻译的合法用户，将得到一个密码和用户名。用户对自己的帐号、密码及安全负全部责任。
    （2）用户发现其账户被盗用的，可通过易翻译公示的方法及渠道重置密码；用户希望易翻译对账户使用采取临时性限制措施的，应书面向易翻译申请并提供账户被盗用以及无法自行采取补救措施的证据，易翻译经审核及核实后将按照其业务规则相应处理。
    （3）易翻译建议用户定期更换密码，避免风险。
    4.2隐私政策
    4.2.1 易翻译尊重用户隐私、保障用户隐私安全。
    4.2.2 易翻译会采取一切合理可行的安全防护措施保护用户信息，防止用户信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失，用户资格终止后易翻译仍为用户保密所有隐私。
    4.2.3 易翻译在中华人民共合国境内收集的个人信息，存储在中华人民共和国境内。如果发生数据的跨境传输，我们会单独向您以弹窗或邮件的方式告知您数据出境的目的、接收方等，并征得您的授权同意，我们会确保数据接收方有充足的数据保护能力来保护您的个人信息。
    4.2.4 我们仅在为实现提供授权目的所必需的期限内保留您的个人信息：您发布的信息、评论、点赞及相关信息。在您未撤回、删除或未注销账号期间，我们会保留相关信息，超出必要期限后，我们将对个人信息进行删除或匿名化处理。
    4.2.5 如我们因经营不善或其他原因出现停止运营的情况，我们会立即停止对您个人信息的收集，删除已收集的个人信息。 我们会将此情况在网站上进行公告或以站内信、邮件等其他合理方式逐一传达到各个用户。

    4.2.6 用户同意易翻译经营者合法收集和使用有关用户及用户所使用功能、服务的技术性或诊断性信息。收集到的这些信息将用于改进易翻译功能、服务的内容和技术，提升服务品质。 
    4.2.7 用户同意易翻译经营者可在对用户的数据及信息进行信息脱密处理的情况下，将该等信息用于制作及发布行业分析报告、行业交流及其他非营利性用途。
    4.2.8 用户不会向任何无关第三方提供、出售、出租、分享和交易用户的个人信息，但从用户的用户名或其它可披露资料分析得出的资料不受此限。
    4.2.9 用户同意易翻译经营者可披露或使用用户信息以用于识别和确认用户的身份，或解决争议，或用于有助于确保易翻译安全，限制欺诈、非法或其他刑事犯罪的活动。用户同意易翻译经营者可披露或使用用户信息以保护用户的生命、财产之安全或为防止严重侵害他人之合法权益或为公共利益之需要。
    4.2.10 用户同意易翻译经营者利用用户信息与用户联络，并向用户提供用户可能感兴趣的信息，如：介绍、产品或服务推荐、促销优惠或者其他商业性短信息等。
    4.2.11 当易翻译经营者被法律强制或依照政府或依权利人因识别涉嫌侵权行为人的要求而提供用户信息时，易翻译经营者将善意地披露用户信息。当易翻译经营者需要保护易翻译经营者、易翻译经营者的代理人、客户、用户和其他人的权益和财产，包括执行易翻译的服务条款、业务规则或政策规定时，易翻译经营者将善意的使用或披露用户信息。
    4.2.12 用户同意如果易翻译经营者拟进行企业并购、重组、出售全部或部分股份和/或资产时，易翻译经营者有权在与前述交易的相关方签署保密协议的前提下向其披露用户信息以协助易翻译经营者完成该等交易。
    4.2.13 用户同意，易翻译经营者有权对用户使用易翻译的使用行为、使用习惯等相关的数据进行采集、分析及使用，该等使用行为数据及其任何分析、演绎等衍生数据的所有权归属易翻译经营者所有。
    4.2.14 为向用户提供全方位、多元化的服务体验，用户同意易翻译经营者将用户信息用于与易翻译经营者相关运营平台（包括Careerfocus平台）的数据交换。用户同意易翻译经营者收集及整理用户在易翻译的行为轨迹（包括但不限于用户在易翻译博客上的专家等级、在易翻译参加过的会议、在易翻译学习过的课程等）并形成TimeLine，用户并同意易翻译经营者可将TimeLine集成到用户的简历（CareerFouse）中，用于猎头筛查、相关运营平台用户服务或其他使用。

    五、知识产权保护
    5.1 易翻译的外观设计、应用程序、源代码、商标、标示图案（LOGO）、界面设计、应用程序编程接口及相关著作权，以及与易翻译经营者提供的服务有关的任何著作权及其他知识产权（包括但不限于著作权、专利权、商标权、商业秘密、专有技术等，下同）均归易翻译经营者所有，其它本协议中未经提及的权利亦由易翻译经营者保留。用户不能复制、拷贝、模仿或者使用任何部分的代码和外观设计。
    5.2 用户知悉，易翻译上存储及传播的作品、内容、信息等由其他用户或易翻译经营者提供；由易翻译经营者提供的作品、内容、信息等的知识产权及其相关权利归属易翻译经营者或相关权利人所有，由其他用户提供的作品、内容、信息等的知识产权及其相关权利归属其他用户或相关权利人所有。未经上述作品、内容、信息等的知识产权权利人同意，用户不得下载、转载、摘编或以其他方式使用（本服务条款及相应版块或服务业务规则项下允许的合理使用除外）。
    5.3 用户同意，用户一旦接受本协议，即表明用户同意将其在任何时间在易翻译上传、存储、发表、传播的任何形式的作品、信息、内容无偿、无条件、无期限、无地域限制的授予易翻译经营者使用、共享及商业利用的权利，包括但不限于对作品、信息、内容进行复制、下载、展示及其信息网络传播权等权利。当任何第三方侵犯用户权益时，用户同意授权易翻译采用任何合法途径进行维权，用户积极配合。

    六、有限保证及服务免责
    6.1 易翻译经营者保证提供的功能、服务与相应功能、服务的介绍及易翻译公布的服务承诺相符。除上述保证外，易翻译经营者不对平台及/或功能、服务作出任何其他明示或暗示的保证。
    6.2 用户在易翻译上进行的任何行为均是用户的个人行为。因用户行为或用户上传、存储、发布、传播的任何作品、信息、内容等引发的任何争议，或由此产生的任何直接、间接、偶然、特殊的损害，均由用户自行承担法律责任，易翻译经营者不承担任何责任；如用户行为对易翻译造成损失或不良影响，易翻译经营者保留追究相关用户法律责任的权利。
    6.3 用户使用易翻译服务，应遵守法律、法规以及本服务条款的规范，否则易翻译经营者有权依照本服务条款处分或终止用户使用易翻译服务，由此造成的损失易翻译经营者不负任何责任。
    6.4 易翻译无法对用户传播的作品、信息、内容的权属或合法性、合规性、真实性、科学性、完整权、有效性等进行实质审查；无论易翻译经营者是否已进行审查，用户均应自行承担因其传输的作品、信息、内容而可能或已经产生的侵权或权属纠纷等法律责任。
    6.5 用户应自行、独立对其在易翻译知悉的其他用户传播的任何作品、信息、内容等进行独立判断及考虑、衡量其真实性、有效性及相关风险，用户因使用该等作品、信息、内容等所遭受的风险及损失等，由用户与作品、信息、内容的传播者自行处理，易翻译经营者不承担任何责任。其他用户在其发表的作品信息中加入宣传资料或广告信息、人才招聘需求，或以其他方式在易翻译上展示其产品或服务等，用户因该等信息、内容与发布用户产生的法律关系或纠纷，应由其自行解决，易翻译经营者不承担任何责任。
    6.6 易翻译经营者对用户所发布信息的删除或储存失败不承担任何法律责任。
    6.7 不论在何种情况下，易翻译经营者均不对由于Internet连接故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令，第三方的不作为或任何易翻译经营者不能合理控制的原因而造成的易翻译不能访问、信息及数据的延误、停滞或错误，不能提供或延迟提供服务而承担责任。
    6.8 不论是否可以预见，不论是源于何种形式的行为或不作为，易翻译经营者不对因任何原因造成的任何特别的、间接的、惩罚性的、突发性的或其他任何损害（包括但不限于利润或其他可得利益的损失）承担责任。除易翻译经营者因故意或重大过失损害用户的利益以外，任何情况下，易翻译经营者向用户承担的赔偿、补偿或其他任何责任的限额将不超过该等用户就相关服务向易翻译经营者支付的服务费用的数额。
    6.9 基于互联网的开放性属性，用户知悉用户将作品、信息、内容等上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识到此类风险的存在。用户明确同意使用易翻译过程中所存在的上述风险将完全由用户自行承担，易翻译经营者对此不承担任何责任。

    七、服务变更、中断或终止
    7.1 如因平台维护或升级的需要而暂停服务、调整服务功能的，易翻译经营者将尽可能事先在本平台上进行通告。
    7.2 易翻译经营者有权对其平台、及其提供的功能、服务等进行变更，届时易翻译经营者将通过在易翻译发布公告、公示或其他适当方式通知该等变更，用户有义务注意该等变更公示或通知。如果用户继续使用本平台提供的服务，则视为其同意接受变更后的功能及服务。如果用户拒绝接受变更后的功能或服务，应立即停止使用易翻译。
    7.3 易翻译经营者有权终止其服务，并通过在易翻译发布公告、公示或其他适当方式通知该等终止。
    7.4 易翻译经营者无须就平台或其提供的功能、服务的变更、中断或终止向用户或任何第三方承担任何责任。

    八、通知和送达
    8.1 一般原则
    本服务条款项下所有通知均可通过易翻译相关页面公告、电子邮件等方式进行，有关服务条款的变更、修改或其他重要事项的通知自发出之日视为已送达用户或收件人。
    易翻译经营者决定对用户上传资源的下载所需积分值进行动态调整时，将在易翻译发布公告通知，用户如不同意易翻译经营者实施此行为，应在公示期内以书面形式告知，逾期未告知的视为同意。
    8.2 特殊情况
    对于易翻译的通知用户应予接收，因用户拒收或其他自身原因导致未知悉相关通告内容而遭受的损失由其自行承担，与易翻译无关。

    九、投诉处理
    当易翻译中出现与侵权纠纷、用户投诉等各种问题有关的投诉时，请将包括但不限于权属证明、权利主体证明、联系方式、网址链接、投诉内容及要求发送至易翻译经营者指定联系方式中。

    十、法律适用、争议管辖及其他
    10.1 本服务条款之解释及适用，均应按照中华人民共和国（就本服务条款之目的，不包括港澳台地区）法律。用户和易翻译经营者与本服务条款有关的争议，由北京创新乐知网络技术有限公司住所地人民法院管辖。
    10.2 本服务条款的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对易翻译经营者及用户具有约束力。
    10.3 易翻译经营者未能或延迟行使本服务条款规定的任何权利、权力或特权不应视为放弃行使，且任何单一或部分权利、权力或特权的行使不应排除权利、权力或特权的任何其他或进一步的行使或本协议项下任何其他权利、权力或特权的行使。
    10.4 易翻译在先发布适用的规则、规范、制度等内容与本服务条款约定不一致的，以本服务条款为准。
    10.5 本服务条款最终解释权归北京创新乐知网络技术有限公司所有。
        </pre>
    </Modal>
</template>

<script>
    export default {
        name: '',
        data() {
            return {
                show : false
            }
        },
        methods : {
            open(){
                this.show = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    }
</style>