<style lang="scss" scoped>
.nav {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 999;
}
.logoImg {
    height: 2.625rem;
}
.menuItem {
    font-size: 1rem;
    color: #000000;
    margin-left: 2rem;
    font-weight: 600;
}
.menuItem.active {
    color: #3581ff;
}
.loginGrp {
    margin-left: 3.75rem;
    border: 1px solid #3581ff;
    border-radius: 0.5rem;
    overflow: hidden;
}
.loginGrp div {
    padding: 0.3rem 1.25rem;
}
.loginGrp div:first-child {
    color: #3581ff;
}
.loginGrp div:nth-child(2) {
    background-color: #3581ff;
    color: #ffffff;
}
@media (max-width: 768px) {
    .mobileNav {
        padding: 0 16px;
        position: relative;
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(10px);
        z-index: 99999999;
    }
    .mNav {
        padding: 10px 0;
        align-items: center;
    }
    .mNav img:first-child {
        height: 32px;
        width: auto;
    }
    .mNav img:last-child {
        height: 18px;
        width: auto;
    }

    .navMain {
        font-size: 1.17rem;
    }

    .dropdown {
        position: absolute;
        left: 0;
        right: 0;
        top: 52px;
        z-index: 99999999;
        height: 0px;
        opacity: 0;
        overflow: hidden;
        transition: all 0.1s linear;
        background: rgba(255, 255, 255, 1);
    }
    .mloginGrp {
        width: 100%;
        background: #3581ff;
        backdrop-filter: blur(10px);
        padding: 0.8rem 0.75rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .mloginGrp > div:first-child {
        padding: 0.64rem 0;
        color: #d9d9d9;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .mRes {
        border-radius: 0.5rem 0rem 0rem 0.5rem;
        border: 1px solid #fff;
        padding: 0.65rem 1.25rem;
        color: #fff;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .mLogin {
        border-radius: 0rem 0.5rem 0.5rem 0rem;
        background: #fff;
        padding: 0.65rem 1.25rem;
        color: #3581ff;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .mJump {
        padding: 0 1.5rem;
        color: #000;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .mMenuItem {
        padding: 1.25rem 0;
        border-bottom: 1px solid #e5e5e5;
    }
    .mJump .mMenuItem:last-child {
        border-bottom: none;
    }
    .mMenuItem.active {
        color: #3581ff;
    }
}
</style>

<template>
    <div>
        <div
            v-if="!isMobile"
            class="flex justify-between nav mainContent"
            :style="{ top: '1rem' || 0 }"
        >
            <div class="cursor-pointer">
                <img
                    src="@/assets/imgs/footer/footerLogo.png"
                    alt=""
                    class="logoImg"
                    @click="clickLogo"
                />
            </div>
            <div class="flex items-center">
                <div class="flex items-center">
                    <div
                        @click="actdNav(nav)"
                        ref="nav"
                        :class="{
                            active: actNav.label === nav.label,
                            'menuItem cursor-pointer': true,
                        }"
                        v-for="nav in navs"
                        :key="nav.label"
                    >
                        {{ nav.label }}
                    </div>
                    <div
                        v-if="userInfo.userId"
                        class="menuItem cursor-pointer"
                        style="color: #04bafa"
                        @click="toIndex"
                    >
                        控制台
                    </div>
                    <div class="menuItem cursor-pointer" v-if="userInfo.userId">
                        <span style="padding-right: 5px"
                            >欢迎您 {{ userInfo.nickname }}</span
                        >
                    </div>
                </div>
                <div class="loginGrp cursor-pointer flex items-center">
                    <div v-if="!userInfo.userId" @click="register">注册</div>
                    <div v-if="!userInfo.userId" @click="login">登录</div>
                </div>
            </div>
        </div>
        <div v-if="isMobile" class="mobileNav">
            <div class="flex justify-between mNav">
                <img
                    src="@/assets/imgs/footer/footerLogo.png"
                    alt=""
                    class="logoImg"
                    @click="clickLogo"
                />
                <img
                    :src="mobileNavIsOpen ? cNav : mNav"
                    @click="
                        () => {
                            mobileNavIsOpen = !mobileNavIsOpen;
                        }
                    "
                    alt=""
                />
            </div>
            <div
                class="dropdown"
                :style="{
                    height: mobileNavIsOpen
                        ? userInfo.userId
                            ? '480px'
                            : '368px'
                        : '0px',
                    opacity: mobileNavIsOpen ? 1 : 0,
                }"
            >
                <div class="mloginGrp">
                    <div>专业、快捷、高效</div>
                    <div v-if="!userInfo.userId" class="flex">
                        <div class="mRes" @click="register">注册</div>
                        <div class="mLogin" @click="login">登录</div>
                    </div>
                </div>
                <div class="mJump">
                    <div
                        @click="actdNav(nav)"
                        ref="nav"
                        :class="{
                            active: actNav.label === nav.label,
                            mMenuItem: true,
                        }"
                        v-for="nav in navs"
                        :key="nav.label"
                    >
                        {{ nav.label }}
                    </div>
                    <div
                        v-if="userInfo.userId"
                        class="mMenuItem"
                        style="color: #04bafa"
                        @click="mobileConsole"
                    >
                        控制台
                    </div>
                    <div class="mMenuItem" v-if="userInfo.userId">
                        <span style="padding-right: 5px"
                            >欢迎您 {{ userInfo.nickname }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="content">
                <router-view
                    @on-free-test="freeTest"
                    @toDownload="toDownload"
                    :isMobile="isMobile"
                />
                <!-- <component
          :is="actNav.component"
          @on-free-test="freeTest"
          @toDownload="toDownload"
          :isMobile="isMobile"
        ></component> -->
            </div>
        </div>
        <Footer v-if="!isLogin"></Footer>
        <sideBar v-if="!isMobile" @on-free-test="freeTest" />
        <!-- <LoginDrawer ref="LoginDrawerRef" @on-login-success="loginSuccess" /> -->
        <Modal v-model="showDownloadTip" title="下载提示" :mask-closable="false">
            <div style="font-size:1.25rem;font-weight: bold">
              请联系右侧客服获取应用下载地址！
            </div>
            <template #footer>
                <div style="text-align: center">
                    <Button class="saveBtn" long @click="showDownloadTip = false"
                        >关 闭</Button
                    >
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import sideBar from "@/components/sideBar.vue";
import logoSrc from "@/assets/imgs/footer/footerLogo.png";
// import NoticeModal from "@/components/NoticeModal";
// import LoginDrawer from "@/components/LoginDrawer";
import router from "@/router/index.js";
// import Home from "@/components/index/Home";
// import price from "@/components/pageComponets/price";
// import Doc from "@/components/index/Doc";
// import useDoc from "@/components/pageComponets/useDoc";
// import contactUs from "@/components/pageComponets/contactUs";
// import download from "@/components/pageComponets/download";
// import AffixCustomerService from "@/components/index/AffixCustomerService";
import Footer from "../components/Footer.vue";
import userService from "@/service/user";
import dataService from "@/service/data";
import mNav from "@/assets/imgs/mobile/nav.png";
import cNav from "@/assets/imgs/mobile/cNav.png";
export default {
    name: "Index",
    components: {
        // NoticeModal,
        // LoginDrawer,
        sideBar,
        Footer,
        // download,
        // Home,
        // price,
        // Doc,
        // AffixCustomerService,
        // useDoc,
        // contactUs,
    },
    data() {
        //原界面组件
        // let navs = [
        //   {
        //     label: "首页",
        //     img: require("@/assets/bg/home.png"),
        //     component: "Home",
        //   },
        //   {
        //     label: "定价标准",
        //     img: require("@/assets/bg/price.png"),
        //     component: "Price",
        //   },
        //   {
        //     label: "使用文档",
        //     img: require("@/assets/bg/doc.png"),
        //     component: "Doc",
        //   },
        //   {
        //     label: "软件下载",
        //     img: require("@/assets/bg/price.png"),
        //     component: "Download",
        //   },
        //   {
        //     label: "联系我们",
        //     img: require("@/assets/bg/home.png"),
        //     component: "Home",
        //   },
        // ];
        let navs = [
            {
                label: "首页",
                img: require("@/assets/bg/home.png"),
                component: "Home",
                url: "/home",
            },
            {
                label: "定价标准",
                img: require("@/assets/bg/price.png"),
                component: "price",
                url: "/price",
            },
            {
                label: "使用文档",
                img: require("@/assets/bg/doc.png"),
                component: "useDoc",
                url: "/useDoc",
            },
            // {
            //     label: "软件下载",
            //     img: require("@/assets/bg/price.png"),
            //     component: "download",
            //     url: "/download",
            // },
            // 由于与home 一致  故暂时屏蔽
            {
                label: "联系我们",
                img: require("@/assets/bg/doc.png"),
                component: "contactUs",
                url: "/contactUs",
            },
            {
                label: "Instagram营销",
                img: require("@/assets/bg/price.png"),
                component: "Instagram",
                url: "/Instagram",
            },
        ];
        let actNav = navs[0];
        const isLogin = false;
        const isMobile =
            window.screen.width > 768 || window.screen.width == 768
                ? false
                : true;
        const showPcNav = true;
        const navTop = "4.5rem";
        return {
            mNav,
            cNav,
            isFreeTest: false,
            userInfo: {},
            downloadData: [],
            innerHeight: window.innerHeight,
            mobileNavIsOpen: false,
            navs,
            actNav,
            isLogin,
            showPcNav,
            isMobile,
            navTop,
            logoSrc,
            showDownloadTip:false
        };
    },
    mounted() {
        let { act } = this.$route.query;
        if (act) {
            for (var i in this.navs) {
                let nav = this.navs[i];
                if (nav.label === act) {
                    this.actNav = nav;
                    break;
                }
            }
        }
        window.onresize = () => {
            this.innerHeight = window.innerHeight;
        };
        this.init();
    },
    watch: {
        "router.currentRoute.value.path": {
            handler: function () {
                this.showPcNav =
                    !this.isMobile &&
                    router.currentRoute.value.path !== "/login";
                if (router.currentRoute.value.path === "/") {
                    this.navTop = "4.5rem";
                } else {
                    this.navTop = "1rem";
                }
                if (router.currentRoute.value.path === "/login") {
                    this.isLogin = true;
                } else {
                    this.isLogin = false;
                }
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        mobileConsole() {
            this.$Message.info("请在PC端登录官网使用该功能！");
        },
        clickLogo() {
            let inviteCode = this.$route.query.inviteCode;
            if (inviteCode) {
                this.$router.push({
                    path: "/",
                    query: { inviteCode: inviteCode },
                });
            } else {
                this.$router.push("/");
            }
        },
        async init() {
            let data = await dataService.get("SOFT_DOWNLOAD_CONFIG");
            this.downloadData = JSON.parse(data.valued);
            if (!sessionStorage.getItem("sk")) {
                let inviteCode = this.$route.query.inviteCode;
                if (inviteCode) {
                    // this.$refs.LoginDrawerRef.open(2);
                    this.$router.push({
                        path: "/login",
                        query: { type: 2, inviteCode: inviteCode },
                    });
                }
                return;
            }
            let userInfo = await userService.get();
            if (userInfo) {
                this.userInfo = userInfo;
            }
        },
        toDownload() {
            this.showDownloadTip = true;
            // this.$refs.nav[3].click();
        },
        actdNav(nav) {
            this.actNav = nav;
            this.$router.push(nav.url);
            this.mobileNavIsOpen = false;
        },
        login() {
            if (this.$route.query.inviteCode) {
                this.$router.push({
                    path: "/login",
                    query: {
                        type: 1,
                        inviteCode: this.$route.query.inviteCode,
                    },
                });
            } else {
                this.$router.push({ path: "/login", query: { type: 1 } });
            }

            // this.$refs.LoginDrawerRef.open(1);
        },
        register() {
            if (this.$route.query.inviteCode) {
                this.$router.push({
                    path: "/login",
                    query: {
                        type: 2,
                        inviteCode: this.$route.query.inviteCode,
                    },
                });
            } else {
                this.$router.push({ path: "/login", query: { type: 2 } });
            }

            // this.$refs.LoginDrawerRef.open(2);
        },
        async loginSuccess() {
            this.userInfo = await userService.get();
            if (this.isFreeTest) {
                this.freeTest();
            }
        },
        toIndex() {
            this.$router.push({ name: "UserCenterIndex" });
        },
        freeTest() {
            this.isFreeTest = true;
            if (!this.userInfo.userId) {
                this.register();
                return;
            }
            if (this.userInfo.memberGroupId === 1) {
                this.$router.push({ name: "UserCenterChangeTranslateTest" });
            } else {
                this.$router.push({ name: "UserCenterRecharge" });
            }
        },
    },
};
</script>

