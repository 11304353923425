<template>
  <div class="userWorkSpace">
    <Divider orientation="center">工作空间</Divider>
    <div class="spaceMain">
      <div class="spaceArea">
        <el-scrollbar style="height: 100%; padding-right: 14px">
          <Divider orientation="center" class="spaceDivider">我的空间</Divider>
          <div
            :class="{
              spaceItem: true,
              current: curSpace.id === item.id ? true : false,
            }"
            v-for="(item, idx) in selfSpace"
            :key="'self_' + idx"
            :title="'我的 - ' + item.name"
            @click="changeCurSpace(item)"
          >
            {{ item.name }}
            <el-icon
              class="delIcon"
              title="删除空间"
              v-if="item.id === item.ownerId"
              @click="deleteSpace"
              ><Delete
            /></el-icon>
            <el-icon
              class="delIcon"
              title="退出空间"
              v-if="item.id !== item.ownerId"
              @click="exitSpace"
              ><Delete
            /></el-icon>
          </div>
          <div style="text-align: center; margin-top: 30px">
            <el-button type="primary" :icon="addIcon" @click="createSpace"
              >创建个人空间</el-button
            >
          </div>
          <Divider orientation="center" class="spaceDivider">共享空间</Divider>
          <div
            :class="{
              spaceItem: true,
              current: curSpace.id === item.id ? true : false,
            }"
            v-for="(item, idx) in sharedSpace"
            :key="'shared_' + idx"
            :title="item.name + ' - 来自' + item.ownerName"
            @click="changeCurSpace(item)"
          >
            {{ item.name }} - 来自{{ item.ownerName }}
            <el-icon
              class="delIcon"
              title="删除空间"
              v-if="item.id === item.ownerId"
              @click="deleteSpace"
              ><Delete
            /></el-icon>
            <el-icon
              class="delIcon"
              title="退出空间"
              v-if="item.id !== item.ownerId"
              @click="exitSpace"
              ><Delete
            /></el-icon>
          </div>
          <Divider orientation="center" class="spaceDivider"
            >子用户空间</Divider
          >
          <div
            :class="{
              spaceItem: true,
              current: curSpace.id === item.id ? true : false,
            }"
            v-for="(item, idx) in childUserSpace"
            :key="'child_' + idx"
            :title="item.name + ' - 来自' + item.ownerName"
            @click="changeCurSpace(item)"
          >
            {{ item.name }} - 来自{{ item.ownerName }}
            <el-icon
              class="delIcon"
              title="删除空间"
              v-if="item.id === item.ownerId"
              @click="deleteSpace"
              ><Delete
            /></el-icon>
            <el-icon
              class="delIcon"
              title="退出空间"
              v-if="item.id !== item.ownerId"
              @click="exitSpace"
              ><Delete
            /></el-icon>
          </div>
        </el-scrollbar>
      </div>
      <div class="mainArea">
        <div>
          <span>已加入空间用户</span>
          <span v-for="(name, idx) in spaceUsers" :key="idx" class="userSpan">{{
            name
          }}</span>
          <el-button
            type="primary"
            style="margin-left: 24px"
            @click="inviteUser"
            >邀请用户加入</el-button
          >
        </div>
        <Divider orientation="center" style="margin: 14px 0" />
        <div
          style="
            flex-grow: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
          "
        >
          <div class="searchArea">
            <span>名称：</span>
            <el-input v-model="condition.title" class="searchIpt" />
            <span>联系方式：</span>
            <el-input v-model="condition.cellphone" class="searchIpt" />
            <el-button
              type="primary"
              style="margin-left: 14px"
              @click="queryContacts"
              >查询</el-button
            >
            <el-button style="margin-left: 14px" @click="reset">重置</el-button>
            <el-button
              type="primary"
              style="margin-left: 14px"
              @click="addContact"
              >新增联系人</el-button
            >
          </div>
          <div class="tableArea">
            <div class="base-warp">
              <Table
                :loading="loading"
                size="small"
                :height="420"
                border
                :columns="columns"
                :data="tableData"
              >
                <template #action="{ row }">
                  <el-button
                    type="primary"
                    style="font-size: 18px"
                    @click="editContact(row)"
                    title="编辑"
                    icon="Edit"
                  ></el-button>
                  <el-button
                    type="danger"
                    icon="Delete"
                    style="font-size: 18px"
                    @click="delContact(row)"
                    title="删除"
                  /> </template
              ></Table>
            </div>
            <div class="page-warp">
              <Page
                style="margin-top: 25px"
                show-total
                :total="page.total"
                :page-size="page.size"
                v-model="page.num"
                @on-change="pageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import workSpaceService from "@/service/workSpace"
import { Add } from "@element-plus/icons-vue";
export default {
  name: "UserWorkSpace",
  data() {
    return {
      addIcon: Add,
      selfSpace: [
        {
          name: "默认空间",
          id: 0,
          ownerId: 0,
        },
      ],
      curSpace: {
        id: 0,
      },
      sharedSpace: [
        {
          name: "共享空间1",
          id: 1,
          ownerName: "张三",
          ownerId: 1,
        },
        {
          name: "共享空间2",
          id: 2,
          ownerName: "李四",
          ownerId: 0,
        },
      ],
      childUserSpace: [
        {
          name: "子用户空间1",
          id: 3,
          ownerName: "张四",
          ownerId: 0,
        },
        {
          name: "子用户空间2",
          id: 4,
          ownerName: "李五",
          ownerId: 0,
        },
        {
          name: "子用户空间2",
          id: 5,
          ownerName: "李五",
          ownerId: 0,
        },
        {
          name: "子用户空间2",
          id: 6,
          ownerName: "李五",
          ownerId: 0,
        },
        {
          name: "子用户空间2",
          id: 7,
          ownerName: "李五",
          ownerId: 0,
        },
        {
          name: "子用户空间2",
          id: 8,
          ownerName: "李五",
          ownerId: 0,
        },
        {
          name: "子用户空间2",
          id: 9,
          ownerName: "李五1",
          ownerId: 0,
        },
        {
          name: "子用户空间2",
          id: 10,
          ownerName: "李五111",
          ownerId: 0,
        },
      ],
      spaceUsers: ["张三", "李四", "王五"],
      condition: {
        title: null,
        cellphone: null,
      },
      tableData: [{}],
      columns: [
        { title: "名称", key: "title" },
        { title: "联系方式", key: "cellphone" },
        { title: "公司", key: "company" },
        { title: "销售阶段", key: "step" },
        { title: "国家", key: "country" },
        { title: "标签", key: "tags" },
        { title: "来源", key: "fromApp" },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
      page: {
        size: 10,
        num: 1,
        total: 11,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {},
    querySpaces() {},
    changeCurSpace(item) {
      this.curSpace = item;
    },
    deleteSpace() {},
    exitSpace() {},
    inviteUser() {},
    createSpace() {},
    queryContacts() {},
    reset() {
      this.condition = {
        title: null,
        cellphone: null,
      };
    },
    pageChange() {
      this.queryContacts();
    },
    addContact(){},
    editContact(row) {
      console.log(row);
    },
    delContact(row) {
      console.log(row);
    },
  },
};
</script>
<style lang="scss" scoped>
.userWorkSpace {
  height: calc(100% - 18px);
  width: 100%;
  .spaceMain {
    height: calc(100% - 56px);
    display: flex;
  }
  .spaceArea {
    width: 250px;
    height: 100%;
    border-right: 1px solid #dcdee2;
    // padding-right: 14px;
    .spaceDivider {
      color: rgb(64, 158, 255);
    }
    .spaceItem {
      text-align: center;
      padding: 10px 24px;
      border: 1px solid #dcdee2;
      margin-bottom: 14px;
      cursor: pointer;
      border-radius: 6px;
      position: relative;
      .delIcon {
        position: absolute;
        font-size: 18px;
        color: red;
        top: 14px;
        right: 6px;
        display: none;
      }
    }
    .spaceItem:hover {
      color: rgb(64, 158, 255);
      border-color: rgb(64, 158, 255);
      .delIcon {
        display: block;
      }
    }

    .spaceItem.current {
      color: rgb(64, 158, 255);
      border-color: rgb(64, 158, 255);
      background-color: #f0faff;
      font-weight: bold;
      .delIcon {
        display: block;
      }
    }
  }

  .mainArea {
    flex: 1;
    padding: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > div:first-child {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .userSpan {
        color: rgb(64, 158, 255);
        border-color: rgb(64, 158, 255);
        background-color: #f0faff;
        padding: 6px 12px;
        border-radius: 4px;
        margin-left: 10px;
      }
    }
    .searchArea {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 14px;
      .searchIpt {
        width: 200px;
        margin-right: 14px;
        ::v-deep .el-input__wrapper {
          border-radius: 6px !important;
        }
      }
    }
    .tableArea {
      flex-grow: 1;
      width: 100%;
      .base-warp {
        width: 100%;
        // height: calc(100% - 60px);
        overflow: hidden;
        overflow-y: auto;
      }
      .page-warp {
        text-align: right;
        position: relative;
      }
    }
  }
}
</style>

