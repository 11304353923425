<template>
  <div>
    <banner :bgSrc="bannerBg" @toDownload="toDownload"></banner>
    <demand mainTitle="套餐选择" @freeTest="freeTest" />
  </div>
</template>
<script >
import banner from "@/components/banner.vue";
import bannerBg from "@/assets/imgs/price/priceBg.png";
import mBannerBg from "@/assets/imgs/mobile/banner/mBanner3.png";
import demand from "@/components/home/demand.vue";
export default {
  components: {
    banner,
    demand,
  },
  data() {
    const isMobile =
      window.screen.width > 768 || window.screen.width == 768 ? false : true;
    return {
      bannerBg: isMobile ? mBannerBg : bannerBg,
    };
  },
  methods: {
    toDownload() {
      this.$emit("toDownload");
    },
    freeTest() {
      this.$emit("on-free-test");
    },
  },
};
</script>
<style scoped>
@media (max-width: 768px) {
}
</style>
